import { Navigate, Link } from "react-router-dom";

import "./Index.css";
import { useGlobalContext } from "../../context";
import React, { useEffect, useState } from "react";
import Terms from "../../reusableComponents/privacyAndTerms/Terms";
import Policies from "../../reusableComponents/privacyAndTerms/Policies";
import {
  AboutComponent,
  CardListComponent,
  CarouselComponent,
  FooterComponent,
  HeaderComponent,
  RolesComponent,
  SubscribeComponent,
} from "./components";
import axios from "axios";
import { Navbar1 } from "../../navigation/Navbar";
import { PageDescription } from "../../reusableComponents/pageDescription";
import SideBar from "../../navigation/Sidebar";
export default function Index() {
  const user = useGlobalContext();

  const [tearmsModal, setTearmsModal] = useState(false);
  const [policyModal, setPolicyModal] = useState(false);
  const [subscribeSuccess, setSubscribeSuccess] = useState(false);

  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWindowWidth(window.innerWidth);
      document.documentElement.style.setProperty("--b", window.innerWidth);
    }
  }, []);
  const outerWidth = window.innerWidth;

  useEffect(() => {
    if (windowWidth !== window.innerWidth) {
      setWindowWidth(window.innerWidth);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () =>
      document.documentElement.style.setProperty("--b", window.outerWidth)
    );

    return () => {
      window.removeEventListener("resize", () => setWindowWidth(0));
    };
  }, [outerWidth]);

  const props = {
    tearmsModal,
    setTearmsModal,
    policyModal,
    setPolicyModal,
    subscribeSuccess,
    setSubscribeSuccess,
  };

  const cards = [
    {
      text: "3455 Jobs Available",
      title: (
        <span>
          Outside sales <div className="mx-5"></div> representative
        </span>
      ),
      img: "/static/media/flames.png",
    },
    {
      text: "5214 Jobs Available",
      title: (
        <span>
          Inside sales <div className="mx-5"></div> representative
        </span>
      ),
      img: "/static/media/flames.png",
    },
    {
      text: "4869 Jobs Available",
      title: (
        <span>
          Sales <div className="mx-5"></div>manager{" "}
        </span>
      ),
      img: "/static/media/flames.png",
    },
    {
      text: "3490 Jobs Available",
      title: (
        <span>
          Director <div className="mx-5"></div>of sales{" "}
        </span>
      ),
      img: "/static/media/flames.png",
    },
    {
      text: "3251 Jobs Available",
      title: (
        <span>
          Regional <div className="mx-5"></div>sales manager
        </span>
      ),
      img: "/static/media/flames.png",
    },
    {
      text: "6483 Jobs Available",
      title: (
        <span>
          Account<div className="mx-5"></div> manager
        </span>
      ),
      img: "/static/media/flames.png",
    },
    {
      text: "8227 Jobs Available",
      title: (
        <span>
          {" "}
          Sales <div className="mx-5"></div>Representative{" "}
        </span>
      ),
      img: "/static/media/flames.png",
    },
    {
      text: "1836 Jobs Available",
      title: (
        <span>
          Sales operational <div className="mx-5"></div> manager{" "}
        </span>
      ),
      img: "/static/media/flames.png",
    },
    {
      text: "1485 Jobs Available",
      title: (
        <span>
          Technical<div className="mx-5"></div>sales engineer
        </span>
      ),
      img: "/static/media/flames.png",
    },
    {
      text: "2580 Jobs Available",
      title: (
        <span>
          Sales development <div className="mx-5"></div> Representative
        </span>
      ),
      img: "/static/media/flames.png",
    },
    {
      text: "3776 Jobs Available",
      title: (
        <span>
          {" "}
          Sales <div className="mx-5"></div>consultant{" "}
        </span>
      ),
      img: "/static/media/flames.png",
    },
    {
      text: "5877 Jobs Available",
      title: (
        <span>
          {" "}
          Account <div className="mx-5"></div>Executive{" "}
        </span>
      ),
      img: "/static/media/flames.png",
    },
  ];

  return (
    <article className="w-100 overflow-hidden bg-white">
      <PageDescription
        title={" intoosales - Sales jobs & Recruitment "}
        content={"Profile Information"}
      />
      <Navbar1 />

      <SubscribeComponent {...props} />
      <Terms {...props} />

      <Policies {...props} />
      <div className="b"></div>

      <HeaderComponent />

      <RolesComponent />

      <CardListComponent props={cards} />

      <CarouselComponent />

      <AboutComponent />

      <FooterComponent {...props} />
    </article>
  );
}
