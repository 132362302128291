import React from "react";
import { policies } from "../../screens/home/mockData";
import "./index.css";
import { FaTimes } from "react-icons/fa";
import { useEffect } from "react";
import { useRef } from "react";

export default function Policies(props) {
  useEffect(() => {
    if (props.policyModal) {
      window.scrollTo(0, 0);
    }
  }, []);

  const ref = useRef(null);
  useEffect(() => {
    const leave = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        props.setPolicyModal(false);
      }
    };
    document.addEventListener("mousedown", leave);
  }, [ref]);
  return (
    <div
      className={`${props.policyModal ? " show termsModal" : "termsModal"} `}
    >
      <div
        ref={ref}
        className="bg d-flex flex-column gap-5 p-5 position-relative"
      >
        <FaTimes
          onClick={() => props.setPolicyModal(false)}
          className="termsModalIcon"
        />
        {policies.map(({ title, text }, index) => {
          return (
            <div className="" key={index}>
              <div className="title">{title}</div>
              {text.map(({ text, subtext }) => {
                return (
                  <div className="mt-3">
                    <div
                      style={{ textTransform: "none" }}
                      className="text mb-3"
                    >
                      {text}
                    </div>
                    {subtext?.map((item) => {
                      return (
                        <ul>
                          {" "}
                          <li className="mb-2">
                            <span className="text-uppercase">{item[0]}</span>
                            {item.substring(1)}
                          </li>
                        </ul>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}
