import { useEffect, useLayoutEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { useGlobalContext } from "./context";
import Home from "./screens/home/Index";
import Navbar from "./navigation/Navbar";
import Sidebar from "./navigation/Sidebar";
import Login from "./screens/userAuth/Login";
import Signup from "./screens/userAuth/Signup";
import CreateAccount from "./screens/recruiter/CreateAccount";
import UserProfile from "./screens/userProfile/Index";
import RecruiterProfile from "./screens/recruiterProfile/Index";
import AppliedJobs from "./screens/appliedJobs/Index";
import Jobs from "./screens/myJobs/Index";
import Subscription from "./screens/subscriptions/Subscription";
import Candidates from "./screens/candidates/Candidates";
import JobsList from "./screens/jobs/Jobs";
import PreviewJob from "./screens/jobs/PreviewJob";
import SignIn from "./screens/recruiter/Login";
import { CreateJob } from "./screens/createteJob/index";
import Payment from "./screens/payment/Payment";
import EditJob from "./screens/editJob/EditJob";
import axios from "axios";
import ResetPassword from "./reusableComponents/resetPassword/ResetPassword";
import ConfirmPassword from "./reusableComponents/confirmPassword/ConfirmPassword";
import PaymentModal from "./screens/payment";
import { api_url } from "./libs/api";

function App() {
  const user = useGlobalContext();
  const { myJobs, setMyJobs } = useGlobalContext();
  const [api, setApi] = useState("");
  const applicant = user.user.applicant;
  const recruiter = user.user.recruiter;
  const { subscription_end_date, email } = user.recruiter;
  const [page, setPage] = useState("default");

  const path = user.path;

  useLayoutEffect(() => {
    user.setPath(window.location.pathname);
  }, [path]);

  const key = JSON.parse(localStorage.getItem("key")) || "";
  useLayoutEffect(() => {
    const auth = JSON.parse(localStorage.getItem("id"));
    setApi(key);
    if (auth) {
      const user_id = auth;
      const getUser = () => {
        axios
          .get(`${api_url}recruiter/${user_id}/profile/`, {
            headers: { Authorization: `Token ${key}` },
          })
          .then((res) => {
            if (res.data.email) {
              localStorage.setItem(
                "subscription_end_date",
                JSON.stringify(subscription_end_date)
              );
              user.setRecruiter(res.data);
              console.log(res.data);
            }
          })
          .catch((err) => console.log(err));
        axios
          .get(`${api_url}applicant/${user_id}/profile/`, {
            headers: { Authorization: `Token ${key}` },
          })
          .then((res) => {
            user.setUser(res.data);
          })
          .catch((err) => console.log(err));
      };
      return getUser();
    }
    return () => {
      user.setRecruiter({});
      user.setUser({});
    };
  }, [applicant, recruiter]);

  useLayoutEffect(() => {
    axios
      .get(`${api_url}jobs/`, {
        headers: { Authorization: `Token ${key}` },
      })
      .then((res) => {
        console.log(api_url);
        setMyJobs(res?.data.reverse());
        if (res?.data.length > 0) {
          setPage("jobsPage");
        } else {
          setPage("create_jobsPage");
        }
        localStorage.setItem("myJobs", res.data.length);
      })
      .catch((err) => console.log(api_url));
  }, []);

  const Redirect = () => {
    return <Navigate to={"/"} />;
  };

  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <div className="App">
      {/* <Navbar /> */}
      {window?.location.pathname != "/plan" && <Sidebar />}
      <Routes>
        <Route
          exact
          path="/"
          element={
            (!key && <Home />) ||
            (user.user.applicant && <JobsList />) ||
            (user.recruiter.recruiter &&
              (myJobs.length > 0 && page === "jobsPage" ? (
                <Jobs />
              ) : page === "create_jobsPage" ? (
                <CreateJob />
              ) : (
                ""
              )))
          }
        />

        <Route path="/password-reset-confirm" element={<ConfirmPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/edit-jobs"
          element={user.recruiter.recruiter && <EditJob />}
        />

        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/createAccount" element={<CreateAccount />} />
        <Route
          path="/applicant-profile"
          element={
            user.user.applicant ? (
              <UserProfile />
            ) : (
              !key && !user.user.recruiter && <Redirect />
            )
          }
        />
        <Route
          path="/recruiter-profile"
          element={
            user.recruiter.recruiter ? (
              <RecruiterProfile />
            ) : (
              !key && !user.recruiter.recruiter && <Redirect />
            )
          }
        />
        <Route path="/applied-jobs" element={api && <AppliedJobs />} />
        <Route path="/jobs" element={api && <Jobs />} />
        <Route path="/subscription" element={api && <Subscription />} />
        <Route path="/candidates" element={api && <Candidates />} />
        <Route path="/create-job" element={api && <CreateJob />} />
        <Route path="/payment" element={api && <Payment />} />
        <Route path="/plan" element={<PaymentModal />} />
        <Route
          path="/preview-job"
          element={
            api ? (
              <PreviewJob />
            ) : (
              !key && !user.recruiter.recruiter && <Redirect />
            )
          }
        />
      </Routes>
    </div>
  );
}

export default App;
