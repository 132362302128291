import React, { useEffect, useRef } from "react";
import styles from "./Index.module.scss";
import Questionaire from "../questionaire/Questionaire";

export default function RepostModal({
  postJob,
  repostId,
  setRepostId,
  questionaire,
  setQuestionaire,
  editJobOnClick,
}) {
  const ref = useRef(null);
  useEffect(() => {
    const leave = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setRepostId("");
      }
    };
    document.addEventListener("mousedown", leave);

    return () => {
      window.removeEventListener("mousedown", leave);
    };
  }, [ref]);

  const props = {
    questionaire,
    setQuestionaire,
    editJobOnClick,
    repostId,
    postJob,
  };

  return (
    <div className={`${styles.container} ${repostId ? styles.show : ""}`}>
      <div ref={ref} className={styles.box}>
        <img src="/static/media/question.svg" alt={"question"} />
        <div className="">
          <div className={styles.title}>
            Do you wish to edit your current questionnaire or add a new one?
          </div>
          <div className={styles.text}>
            By clicking No!Thanks will publish this job
          </div>
        </div>
        <div className={"d-flex text-capitalize"}>
          <div onClick={() => postJob()} className={styles.yes}>
            no! thanks
          </div>
          <Questionaire {...props} btn={<div className={styles.no}>Yes</div>} />
        </div>
      </div>
    </div>
  );
}
