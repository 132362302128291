import React, { useEffect } from "react";
import { useContext, useState } from "react";

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [myJobs, setMyJobs] = useState([]);
  const [path, setPath] = useState(null);
  const [prevPath, setPrevPath] = useState(null);
  const [edit, setEdit] = useState({ edit: {}, id: "" });
  const [user, setUser] = useState({
    address: "",
    applicant: null,
    email: "",
    id: "",
    name: "",
    phone: "",
  });

  const [recruiter, setRecruiter] = useState({
    address: "",
    recruiter: null,
    email: "",
    id: "",
    name: "",
    phone: "",
    subscription_end_date: "",
  });

  const [createJob, setCreateJob] = useState({});

  const siteKey = `6LfVtiYnAAAAAIZXwkTGWmE9Hkf_RiY2Gj-jUbxx`;
  const secretKey = `6LfVtiYnAAAAALbeQcjtGy_0fVNawpFr9FDNN9ov`;

  // EDIT JOB
  const ediT = (pk) => {
    const item = myJobs.find((job) => job.id === pk);
    setEdit({ edit: item, id: pk });
  };

  useEffect(() => {
    const job = JSON.parse(localStorage.getItem("editJobs"));
    const item = job ? job : 1;
    setEdit({ edit: item, id: item.id });
  }, []);

  // PREVIEW JOB
  const [previewJob, setPreviewJob] = useState({});

  return (
    <AppContext.Provider
      value={{
        path,
        setPath,
        previewJob,
        setPreviewJob,
        recruiter,
        setRecruiter,
        user,
        setUser,
        myJobs,
        setMyJobs,
        edit,
        ediT,
        prevPath,
        setPrevPath,
        createJob,
        setCreateJob,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
