import React, { useLayoutEffect } from "react";
import styles from "./index.module.scss";
import {
  FaChevronDown,
  FaInfoCircle,
  FaPlus,
  FaTrash,
  FaTimes,
} from "react-icons/fa";
import { BsFillQuestionOctagonFill } from "react-icons/bs";
import { MdOutlineLibraryAdd, MdEditNote } from "react-icons/md";
import { AiOutlineUpload } from "react-icons/ai";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { IoIosCreate } from "react-icons/io";
import { Question } from "./question";

export default function Questionaire({
  questionaire,
  setQuestionaire,
  btn,
  editJob,
  editJobOnClick,
  repostId,
  status,
}) {
  const editJobQuestions =
    questionaire?.questions?.map(({ id, choices, text }) => {
      const option =
        choices?.map((idx) => {
          const { id, text } = idx;
          const is_correct = idx.is_correct === "True" ? true : false;
          return { is_correct, id, text };
        }) || [];
      return { questionTitle: text, id, options: option };
    }) || [];

  const [openModal, setOpenModal] = useState(false);
  const [questions, setQuestions] = useState(editJobQuestions || []);
  const [title, setTitle] = useState("");
  const [openQuestion, setOpenQuestion] = useState("");
  const [editQuestionClick, setEditQuestionClick] = useState(false);
  const [editQuestionId, setEditQuestionId] = useState("");
  const [score, setScore] = useState(questionaire.score || "");
  const [EmptyScore, setEmptyScore] = useState("");

  const ref = useRef(null);

  const createQuestion = (e) => {
    e.preventDefault();
    const item = { questionTitle: title, id: `${new Date()}`, options: [] };
    setQuestions([...questions, item]);
    setTitle("");
  };

  const onChange = (e) => {
    const re = /^[0-9/]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      if (e.target.value > questions.length) {
        setScore("");
      } else {
        setScore(e.target.value);
      }
    }
  };

  const editQuestion = (e) => {
    e.preventDefault();
    const items = questions.find((item) => item.id == editQuestionId);
    items.questionTitle = title;
    setTitle("");
    setEditQuestionId("");
  };

  useEffect(() => {
    if (questions.length === 0) {
      setEditQuestionId("");
    }
  }, [questions]);

  const submitQuestion = () => {
    const answers = questions?.filter((item) =>
      item.options.find((idx) => idx.is_correct == true)
    );
    if (repostId && !questions.length > 0) {
      console.log("repostId");
      setQuestionaire({
        questions: [],
        score: 0,
        state: false,
      });
      setOpenModal(false);
      setEditQuestionClick(true);
    } else if (answers.length == questions.length) {
      setQuestionaire({
        questions: questions,
        score: score,
        state: true,
      });
      setOpenModal(false);
      setEditQuestionClick(true);
      toast.success("Questonnaire successfully created👋👋🤚 ");
    } else {
      toast.warning("Please select Answer ");
    }
  };

  const handleSubmit = () => {
    if (score === "") {
      setEmptyScore(true);
    } else {
      submitQuestion();
    }
  };

  useEffect(() => {
    if (repostId && editQuestionClick) {
      editJobOnClick(repostId);
    }
  }, [editQuestionClick]);

  const repost = JSON.parse(localStorage.getItem("repost"));

  const props = {
    questions,
    setQuestions,
    openQuestion,
    setOpenQuestion,
    setTitle,
    editQuestionId,
    setEditQuestionId,
  };

  return (
    <div>
      <ToastContainer />

      {!btn ? (
        <div
          style={{
            width: "fit-content",
            opacity: status === "active" ? "0.8" : "",
            cursor: status === "active" ? "auto" : "",
          }}
          className="rounded cursor-pointer orange"
        >
          <div
            onClick={() => {
              if (status === "active") {
                const notify = () =>
                  toast.warning(
                    "You cannot edit the questionnaire when the job is active"
                  );
                notify();
              } else {
                setOpenModal(true);
              }
            }}
            className={`d-flex align-items-center text-white px-3 link ${
              editJob ? "py-2" : "py-2"
            }`}
          >
            <IoIosCreate style={{ fontSize: "1.5rem" }} />
            <div
              className=" cursor-pointer"
              style={{
                fontFamily: "inter",
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              {editJobQuestions.length > 0
                ? " Edit Questionnaire"
                : " Create a questionnaire"}
            </div>
          </div>
        </div>
      ) : (
        <div
          onClick={() => {
            setOpenModal(true);
          }}
          className=""
          style={{ width: "fit-content" }}
        >
          {btn}
        </div>
      )}
      <article
        className={`${openModal ? styles.show : ""} ${
          styles.shareBoxContainer
        }`}
      >
        <div ref={ref} className={styles.box}>
          <section style={{ textAlign: "start", justifyContent: "start" }}>
            <div className={styles.text + ""}>
              Create a questionnaire for candidates to get more qualified
              applications{" "}
              <BsFillQuestionOctagonFill
                style={{ color: "#f19306" }}
                className="inline"
              />
            </div>
            <small className={"text-muted"}>
              You can only add maximum of 5 questions.
            </small>
          </section>
          <article>
            {EmptyScore && score === "" ? (
              <small className="text-danger mb-1">
                Please add a passing score
              </small>
            ) : (
              ""
            )}
            <div
              style={{
                outline: EmptyScore && score === "" ? "1px solid red" : "",
              }}
              className={styles.scorebtn}
            >
              <div className={styles}> Passing score :</div>
              <input
                type="text"
                placeholder="0"
                value={score}
                maxLength={1}
                onChange={onChange}
                autoFocus
                required
                className={styles.scoreInput}
              />
            </div>
            {questions.length < 5 ? (
              <form
                onSubmit={editQuestionId ? editQuestion : createQuestion}
                className={styles.addInput}
              >
                <input
                  required
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  autoFocus
                  type="text"
                  placeholder="Type your question"
                  className={styles.tagInput}
                />
                <button type="submit" className={styles.tagSubmit}>
                  {editQuestionId ? (
                    <AiOutlineUpload />
                  ) : (
                    <MdOutlineLibraryAdd />
                  )}
                </button>
              </form>
            ) : (
              ""
            )}
            {questions.map((item, index) => {
              return (
                <Question
                  key={index}
                  index={index}
                  item={item}
                  setQuestTitle={setTitle}
                  {...props}
                />
              );
            })}
          </article>
          <div className="flex justify-end gap-3">
            <div
              onClick={() => setOpenModal(false)}
              className=" capitalize text-danger bg-white flex items-center w-28 justify-center cursor-pointer duration-300 hover:!bg-red-100 font-bold min-h-[57px] font-inter rounded-md !shadow"
            >
              cancel
            </div>
            {questions.length > 0 && !repostId ? (
              <div
                style={{ width: "fit-content" }}
                onClick={handleSubmit}
                className="d-flex cursor align-items-center btn bg-primary text-white rounded gap-2 px-3 py-3 link"
              >
                <div className="d-flex align-items-center">
                  <FaInfoCircle
                    style={{
                      fontSize: "18px",
                      marginRight: "5px",
                    }}
                  />{" "}
                </div>
                <div
                  className=""
                  style={{
                    fontFamily: "inter",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  Submit
                </div>
              </div>
            ) : repostId || window.location.pathname.includes("edit") ? (
              <div
                style={{ width: "fit-content" }}
                onClick={() => submitQuestion()}
                className="d-flex cursor align-items-center btn bg-primary text-white rounded gap-2 px-3 py-3 link"
              >
                <div className="d-flex align-items-center">
                  <FaInfoCircle
                    style={{
                      fontSize: "18px",
                      marginRight: "5px",
                    }}
                  />{" "}
                </div>
                <div
                  className=""
                  style={{
                    fontFamily: "inter",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  Publish
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </article>
    </div>
  );
}
