import {
  expData,
  industryData,
  jobData,
  locations,
  roleData,
  salaryData,
  salesData,
} from "../screens/jobs/data";

export const jobDesc_placeholder = `${
  "We are seeking a motivated and results-driven sales representative to join our team. The ideal candidate will be responsible for prospecting, negotiating, and closing deals in order to meet and exceed sales goals. If you have a passion for sales and a desire to succeed, this is the opportunity for you." +
  "\r\n" +
  "\r\n" +
  "for example:)"
}`;

export const req_placeholder = `${
  "Proven sales experience" +
  "\r\n" +
  "Strong communication and interpersonal skills " +
  "\r\n" +
  "Ability to build and maintain relationships with clients " +
  "\r\n" +
  "Ability to negotiate and close deals" +
  "\r\n" +
  "Strong organisational and time management skills " +
  "\r\n" +
  "Self-motivated and results-driven" +
  "\r\n" +
  "\r\n" +
  "for example:)"
}`;

export const qualify_placeholder = `${
  "Prospect and identify potential clients" +
  "\r\n" +
  "Build and maintain relationships with clients " +
  "\r\n" +
  "Present and sell company products and services to new and existing clients" +
  "\r\n" +
  "Negotiate and close deals" +
  "\r\n" +
  "Meet and exceed sales targets" +
  "\r\n" +
  "Keep accurate records and report sales activity " +
  "\r\n" +
  "\r\n" +
  "We offer a competitive salary and benefits package, as well as opportunities for growth and advancement within the company. If you are a motivated and enthusiastic sales professional, we encourage you to apply for this exciting opportunity." +
  "\r\n" +
  "\r\n" +
  "for example:)"
}`;

export const inputs = [
  {
    name: "title",
    title: "Job Title",
    placeholder: "Write the best headline for your job",
    value: "",
  },
  {
    name: "company",
    title: "Company Name",
    placeholder: "Your company name",
    value: "",
  },
  {
    name: "industry",
    title: "Industry",
    placeholder: "Select",
    value: "",
    data: industryData,
  },
  {
    name: "role",
    title: "Role",
    placeholder: "Select",
    value: "",
    data: roleData,
  },
  {
    name: "type",
    title: "Job Type",
    placeholder: "Select",
    value: "",
    data: jobData,
  },
  {
    name: "salestype",
    title: "Sales Type",
    placeholder: "Select",
    value: "",
    data: salesData,
  },
  {
    name: "experience",
    title: "Experience",
    placeholder: "Select",
    value: "",
    data: expData,
  },
  {
    name: "salary",
    title: "Salary",
    placeholder: "Select",
    value: "",
    data: salaryData,
  },
  {
    name: "state",
    title: "State",
    placeholder: "Select",
    value: "",
    data: locations,
  },
  { name: "city", title: "City", placeholder: "Select", value: "", data: [] },
  {
    name: "description",
    title: "Job Description",
    placeholder: jobDesc_placeholder,
    value: "",
    subtext:
      "Provide a quick and clear overview of the job to pontential candidates",
  },
  {
    name: "requirements",
    title: "Requirements",
    placeholder: req_placeholder,
    value: "",
    subtext:
      "Requirements are what the empolyee needs in order for the job to be performed successfully",
  },
  {
    name: "qualifications",
    title: "qualifications",
    placeholder: qualify_placeholder,
    value: "",
    subtext:
      "Qualifications are the skills and attributes that the candidate has that makes them suitable for the job",
  },
];

export const user_key = JSON.parse(localStorage.getItem("key"));
export const user_id = JSON.parse(localStorage.getItem("id"));
