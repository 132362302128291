import React, { useEffect, useState } from "react";
import { locations } from "../jobs/data";
import { useGlobalContext } from "../../context";
import { FaEdit } from "react-icons/fa";
import axios from "axios";
import Select from "react-select";
import { Navbar2 } from "../../navigation/Navbar";
import { PageDescription } from "../../reusableComponents/pageDescription";
import { user_id, user_key } from "../../libs/mockdata";
import { api_url } from "../../libs/api";
import { handleClickEvent } from "../../libs/utils";

function Index() {
  const user = useGlobalContext();

  const [checked, setChecked] = useState(true);
  const [check, setCheck] = useState(false);
  // eslint-disable-next-line
  const [job, setJob] = useState(false);
  const [form, setForm] = useState({
    name: user.recruiter.name,
    email: user.recruiter.email,
    address: user.recruiter.address,
    phone: user.recruiter.phone,
  });

  const [name, setName] = useState(user.recruiter.name);
  const [company, setCompany] = useState(user.recruiter.company_name);
  const [address, setAddress] = useState(user.recruiter.address);
  const [phone, setPhone] = useState(user.recruiter.phone);
  const [email, setEmail] = useState(user.recruiter.email);
  const [edit, setEdit] = useState(false);
  const [updated, setUpdated] = useState(false);

  const updateProfile = () => {
    axios
      .put(
        `${api_url}recruiter/${user_id}/profile/`,
        { ...form, company_name: company, recruiter: user_id },

        {
          headers: {
            Authorization: `Token ${user_key}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setUpdated(true);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      });
  };

  const editProfile = (e) => {
    handleClickEvent({
      action: edit ? "Update Profile" : "Edit Profile",
      label: edit ? "Update Profile" : "Edit Profile",
    });
    e.preventDefault();
    setEdit(true);
    if (edit) {
      updateProfile();
    }
  };

  const inputs = [
    { title: "name" },
    { title: "email" },
    { title: "phone" },
    { title: "address" },
  ];

  useEffect(() => {
    setTimeout(() => {
      setUpdated(false);
    }, 3000);
    if (updated) {
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  }, [updated]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <article className="margin bg-white">
      <PageDescription title={"Profile"} content={"Profile Information"} />
      <Navbar2 />
      <div
        style={{ minHeight: "100vh", background: "#fff" }}
        className="container-fluid b"
      >
        <div className=" px-md-4 pb-md-5 pt-4">
          <div className="row justify-content-center">
            <div className={` py-4 mb-md-5 col-lg-11`} style={{ bottom: "0" }}>
              <form onSubmit={editProfile} className="row">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div
                    style={{
                      fontFamily: "inter",
                      fontSize: "1.87rem",
                      fontWeight: "700",
                    }}
                    className="black"
                  >
                    Account info
                  </div>
                  <button
                    type="submit"
                    className="rounded cursor-pointer primary_bg_color mx-2 label"
                  >
                    <div className="d-flex align-items-center px-3 py-2">
                      <div
                        className=""
                        style={{
                          fontFamily: "inter",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        {edit ? "Update Profile" : "Edit Profile"}
                      </div>
                    </div>
                  </button>
                </div>
                {inputs.map(({ title }) => {
                  return (
                    <div key={title} className="col-12 col-md-6 my-2">
                      <div className=" capitalize font-inter font-medium text-base">
                        {title}
                      </div>
                      {title == "address" && edit ? (
                        <Select
                          onChange={(e) => setAddress(e.value)}
                          options={locations}
                          defaultValue={{
                            label: user.recruiter.address,
                            value: user.recruiter.address,
                          }}
                          styles={{
                            input: (baseStyles, state) => ({
                              ...baseStyles,
                              padding: "4px 0",
                              fontFamily: "inter",
                              fontWeight: "900",
                              color: "#6B778C",
                            }),

                            control: (base, state) => ({
                              ...base,
                              boxShadow: "none",
                              border: "2px solid #dfe1e6",
                              borderRadius: "6px",
                              fontSize: "0.87rem",
                              height: "49.8px",

                              "&:hover": {
                                // boxShadow: "0 0 0 2px black !important",
                                borderColor: "#4897d8 !important",
                              },
                              "&:focus": {},
                              boxShadow: state.isFocused
                                ? "2px 2px 3px rgb(213, 213, 213, 0.4), -2px -2px 3px rgb(213, 213, 213, 0.4) !important"
                                : "2px 2px 3px rgb(213, 213, 213, 0.4), -2px -2px 3px rgb(213, 213, 213, 0.4) !important",
                            }),
                          }}
                        />
                      ) : (
                        <input
                          type="text"
                          disabled={edit ? false : true}
                          required
                          onChange={(e) =>
                            setForm({ ...form, [title]: e.target.value })
                          }
                          value={form[title]}
                          style={{
                            border: `2px solid  #DFE1E6`,
                          }}
                          className="py-2 px-3 font-inter text-base bg-white font-normal text-gray-800 w-100 text-start rounded login_input"
                        />
                      )}
                    </div>
                  );
                })}
              </form>
            </div>
            <div
              className="d-none d-lg-block"
              style={{ marginLeft: "0rem", width: "7%" }}
            ></div>
          </div>
          <article className={`${!job && "d-none"} job_search `}>
            <div className="container-fluid"></div>
          </article>
        </div>
      </div>

      {/*UDATE PROFILE POPUP*/}
      <div className={`${updated ? "show" : ""} delete_success d-flex`}>
        <div
          style={{ height: "fit-content" }}
          className={`${updated ? "show" : ""} delete_content d-flex`}
        >
          profile updated successfully
        </div>
      </div>
    </article>
  );
}

export default Index;
