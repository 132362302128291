import React from "react";
import { FaUpload } from "react-icons/fa";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

export default function UploadImg({ form, setForm, error }) {
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 10,
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 1)
        toast.warning("You cannot bulk upload more than 10 files at a time");
      setForm({ ...form, image: acceptedFiles[0] });
    },
  });

  return (
    <div className="col-12 col-sm-5 col-md-5 col-lg-4">
      <div className="d-flex h-100 flex-column">
        <div className="black mb-3 font-inter relative -left-2.5 font-bold text-3xl">
          Create a job
        </div>
        {form?.image?.name ? (
          <img
            src={URL.createObjectURL(form.image)}
            alt=""
            className="rounded w-36 h-32 object-cover shadow-[2px_2px_3px_rgb(213,213,213,0.4),-2px_-2px_3px_rgb(213,213,213,0.4)]"
          />
        ) : (
          <img
            {...getRootProps()}
            src="/static/media/Rectangle 35.svg"
            alt=""
            className="rounded relative w-36 h-32 -left-2.5"
          />
        )}

        <div className="mt-auto pt-2">
          <div className="font-inter text-sm font-normal">
            We recommend using a 200x200 or higher-res square image, such as
            your Twitter or Facebook avatar
          </div>
          <div className="flex gap-2 items-center px-0 mt-3">
            <label
              for="logo"
              {...getRootProps()}
              className="rounded cursor-pointer primary_bg_color"
            >
              <div className="flex items-center  px-3 py-2 link">
                <div>
                  <FaUpload className="w-5 h-3.5 mr-1.5" />{" "}
                </div>
                <div className="font-inter text-sm font-semibold">Upload</div>
              </div>
            </label>

            <div>
              {error && !form.image ? (
                <small className="text-danger">Please choose an image</small>
              ) : form.image ? (
                <small>{form.image.name}</small>
              ) : (
                <small> no file chosen</small>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
