import React, { useEffect, useState } from "react";
import { FaUpload } from "react-icons/fa";
import { useDropzone } from "react-dropzone";
import { api_host } from "../../libs/api";

export default function UploadImg({ form, error, mb, setMb, img, setImg }) {
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 10,
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      // if (acceptedFiles.length > 1) {
      console.log(acceptedFiles);
      setImg(acceptedFiles[0]);
      const Size = acceptedFiles[0].size;
      const mb = Size / 1024 ** 2;
      setMb({ ...mb, size: mb });
      console.log(acceptedFiles);
      console.log(mb);
      // }
    },
  });

  return (
    <div className="col-12 col-sm-5 col-md-5 col-lg-4">
      <div className="d-flex h-100 flex-column">
        <div className="black mb-3 font-inter relative -left-2.5 font-bold text-3xl">
          Edit job
        </div>
        {img ? (
          <img
            src={URL.createObjectURL(img)}
            style={{
              width: "150px",
              height: "120px",
              objectFit: "cover",
            }}
            alt=""
            className="rounded"
          />
        ) : (
          <img
            src={`${api_host}/${form.image}`}
            style={{
              width: "150px",
              height: "120px",
              objectFit: "cover",
            }}
            alt=""
            className="rounded"
          />
        )}

        <div className="mt-auto pt-2">
          <div className="font-inter text-sm font-normal">
            We recommend using a 200x200 or higher-res square image, such as
            your Twitter or Facebook avatar
          </div>
          <div className="flex gap-2 items-center px-0 mt-3">
            <label
              for="logo"
              {...getRootProps()}
              className="rounded cursor-pointer primary_bg_color"
            >
              <div className="flex items-center  px-3 py-2 link">
                <div>
                  <FaUpload className="w-5 h-3.5 mr-1.5" />{" "}
                </div>
                <div className="font-inter text-sm font-semibold">Upload</div>
              </div>
            </label>

            <div>
              {error && !img && !form.image ? (
                <small className="text-danger">Please choose an image</small>
              ) : img ? (
                <small>{img.name}</small>
              ) : form.image ? (
                <small>{form.image.substring(18)}</small>
              ) : (
                <small> no file chosen</small>
              )}
              {error && mb.size > 2 && (
                <small className="text-danger">
                  Please choose an image that is smaller than 1.1mb
                </small>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
