import React, { useState, useEffect, useRef } from "react";
import { FaCheck, FaChevronDown } from "react-icons/fa";
import "./Index.css";
import { ho } from "../screens/jobs/data";
import { GoLocation } from "react-icons/go";

export default function Multi({ title, data, searchArray, setSearchArray }) {
  const [input, setInput] = useState("");
  const [show, setShow] = useState(false);
  const [first, setFirst] = useState(data);

  const filtered = first.filter((item) => {
    return item.value.toLowerCase().includes(input.toLocaleLowerCase());
  });
  const h = first.filter((item) => item.selected === true);
  const ref = useRef(null);
  useEffect(() => {
    const muf = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", muf);
  }, [ref]);

  useEffect(() => {
    const del = () =>
      data.find((item) => {
        if (item.selected === true) {
          item.selected = false;
        }
      });
    del();
  }, []);

  return (
    <div ref={ref} className="w-100 d-x flex-column position-relative">
      <div className="position-relative mx-auto">
        {h.length ? <div className="_badge">{h.length}</div> : ""}

        <input
          type="text"
          onClick={() => setShow(!show)}
          onChange={(e) => setInput(e.target.value)}
          className="input_ rounded w-100 cursor"
          placeholder={title}
        />
        <FaChevronDown style={{ color: "#f19306" }} className="arrow cursor " />
        <div style={{ background: "#f19306" }} className="arrow_border" />
      </div>
      <div className={`${show && "show"} options col-10 mx-auto mb-5 bg-white`}>
        {filtered.length > 0 &&
          filtered.map(({ value, selected }, index) => {
            return (
              <Mu
                value={value}
                first={first}
                title={title}
                setSearchArray={setSearchArray}
                searchArray={searchArray}
              />
            );
          })}
        {filtered.length === 0 && (
          <div className="text-capitalize text-center py-3">no match</div>
        )}
      </div>
    </div>
  );
}

const Mu = ({ value, first, searchArray, setSearchArray, title }) => {
  const [check, setCheck] = useState(false);

  const che = (value) => {
    const item = first.find((sale) => sale.value === value);
    item.selected = !item.selected;
    setCheck(item.selected);
  };
  useEffect(() => {
    if (check || !check) {
      const da = first.filter((item) => item.selected === true);
      const text = da.map((item) => item.value);
      const ar = text;
      const arch = [ar];
      setSearchArray({ ...searchArray, [title]: ar });
    }

    return () => {
      setSearchArray([]);
    };
  }, [check]);
  return (
    <div className="cursor">
      <div
        onClick={() => {
          che(value);
        }}
        style={{ cursor: "pointer", fontFamily: "poppins" }}
        className="d-flex justify-content-between py-2 px-3 text-capitalize child_option"
      >
        <div className="">{value}</div>
        <div className="">{check ? <FaCheck /> : ""}</div>
      </div>
      <div className="hr_"></div>
    </div>
  );
};

export const Dropdown = ({ cities, city, setCity, error, border }) => {
  const [input, setInput] = useState("");
  const [show, setShow] = useState(false);

  const [first, setFirst] = useState(cities);

  const filtered = first.filter((item) =>
    item.value.toLowerCase().includes(input.toLocaleLowerCase())
  );
  const ref = useRef(null);
  useEffect(() => {
    const muf = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", muf);
  }, [ref]);

  useEffect(() => {
    setInput("");
  }, [city]);

  return (
    <div ref={ref} className="position-relative w-100">
      <div className="position-relative">
        <input
          type="text"
          value={input}
          onClick={() => setShow(!show)}
          onChange={(e) => setInput(e.target.value)}
          placeholder={city ? city : "Select"}
          className={"input_2  rounded px-3"}
          style={{
            borderColor: error && !city ? "red" : "",
            border: border ? border : "",
          }}
        />
        <div className="arrow_container pt- ">
          <FaChevronDown className="arrow2 " />
        </div>
      </div>
      <div
        className={`${show && "show"} d- options w-100 mx-auto mb-5 bg-white`}
      >
        {filtered.length > 0 &&
          filtered.map(({ value, selected, id }, index) => {
            return (
              <div key={index} className="">
                <div
                  className={`d-flex justify-content-between py-2 px-3 text-capitalize child_option ${
                    city === value ? "primary_bg_color text-white" : ""
                  }`}
                  onClick={() => {
                    setCity(value);
                    setShow(false);
                    console.log(value);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="">{value}</div>
                  <div className="">{city === value ? <FaCheck /> : ""}</div>
                </div>
                <div className="hr_"></div>
              </div>
            );
          })}

        {filtered.length === 0 && (
          <div className="py-3 text-center">No Matches</div>
        )}
      </div>
    </div>
  );
};
export const Dropdown1 = ({
  cities,
  city,
  setCity,
  setSearchArray,
  searchArray,
}) => {
  const [input, setInput] = useState("");
  const [show, setShow] = useState(false);

  const [first, setFirst] = useState(cities);

  const filtered = first.filter((item) =>
    item.value.toLowerCase().includes(input.toLocaleLowerCase())
  );
  const ref = useRef(null);
  useEffect(() => {
    const muf = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", muf);
  }, [ref]);

  useEffect(() => {
    setInput("");
    setSearchArray({ ...searchArray, ["State"]: city });
  }, [city]);

  return (
    <div ref={ref} className="position-relative w-100">
      <div className="position-relative">
        <GoLocation className="location-icon" />
        <input
          type="text"
          value={input}
          onClick={() => setShow(!show)}
          onChange={(e) => setInput(e.target.value)}
          placeholder={city ? city : "Select"}
          className={"input_  rounded "}
          style={{ paddingLeft: "30px", paddingRight: "25px" }}
        />
        <FaChevronDown style={{ color: "#f19306" }} className="arrow cursor" />
        <div style={{ background: "#f19306" }} className="arrow_border" />
      </div>
      <div
        className={`${show && "show"} d- options w-100 mx-auto mb-5 bg-white`}
      >
        {filtered.length > 0 &&
          filtered.map(({ value, selected, id }, index) => {
            return (
              <div key={index} className="">
                <div
                  className={`d-flex justify-content-between py-2 px-3 text-capitalize child_option ${
                    city === value ? "primary_bg_color text-white" : ""
                  }`}
                  onClick={() => {
                    setCity(value);
                    setShow(false);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="">{value}</div>
                  <div className="">{city === value ? <FaCheck /> : ""}</div>
                </div>
                <div className="hr_"></div>
              </div>
            );
          })}
        {filtered.length === 0 && (
          <div className="py-3 text-center">No Matches</div>
        )}
      </div>
    </div>
  );
};

export const Dropdown2 = ({
  setSearchArray,
  searchArray,
  city,
  setCity,
  state,
}) => {
  const [input, setInput] = useState("");
  const [inputs, setInputs] = useState("");
  const [show, setShow] = useState(false);
  const [first, setFirst] = useState([]);

  useEffect(() => {
    if (state || !state) {
      const u = ho[state];
      setFirst(u?.map((srt) => ({ value: srt, selected: false })));
    }
  }, [state]);

  useEffect(() => {
    const item = () =>
      first?.filter((idx) => {
        if (idx.value !== city) {
          setCity("");
        }
      });
    item();
  }, [first]);

  const filtered = first?.filter((item) =>
    item.value.toLowerCase().includes(input.toLocaleLowerCase())
  );
  const ref = useRef(null);
  useEffect(() => {
    const muf = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", muf);
  }, [ref]);

  useEffect(() => {
    setInput("");
    setInputs(city);
    setSearchArray({ ...searchArray, ["City"]: city });
  }, [city]);

  useEffect(() => {
    if (!show) {
      setInput("");
    }
  }, [show]);

  return (
    <div ref={ref} className="position-relative w-100">
      <div className="position-relative">
        <GoLocation className="location-icon" />
        <input
          type="text"
          value={input}
          onClick={() => setShow(!show)}
          onChange={(e) => setInput(e.target.value)}
          placeholder={city ? city : "City"}
          className={"input_  rounded"}
          style={{ paddingLeft: "30px", paddingRight: "25px" }}
        />
        <FaChevronDown style={{ color: "#f19306" }} className="arrow cursor" />
        <div style={{ background: "#f19306" }} className="arrow_border" />
      </div>
      <div
        className={`${show && "show"} d- options w-100 mx-auto mb-5 bg-white`}
      >
        {filtered?.length > 0 &&
          filtered?.map(({ value, selected, id }, index) => {
            return (
              <div key={index} className="">
                <div
                  className={`d-flex child_option justify-content-between py-2 px-3 text-capitalize ${
                    city === value ? "primary_bg_color text-white" : ""
                  }`}
                  onClick={() => {
                    setCity(value);
                    setShow(false);
                    console.log(value);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="">{value}</div>
                  <div className="">{city === value ? <FaCheck /> : ""}</div>
                </div>
                <div className="hr_"></div>
              </div>
            );
          })}

        {filtered?.length === 0 && (
          <div className="py-3 text-center">No Matches</div>
        )}
      </div>
    </div>
  );
};
