import React, { useState } from "react";
import { FaInfoCircle, FaTimes } from "react-icons/fa";
import style from "./index.module.scss";
import { useRef } from "react";
import { useEffect } from "react";
import { Minus, Plus, data, infos } from "./data";

const Modal = () => {
  const [openShareModal, setOpenShareModal] = useState(false);
  const [accordion, setAccordion] = useState("");

  const ref = useRef(null);

  useEffect(() => {
    const leave = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpenShareModal(false);
      }
    };
    document.addEventListener("mousedown", leave);
  }, [ref]);

  return (
    <section>
      <div
        style={{ width: "fit-content" }}
        className={`rounded cursor-pointer d-flex align-items-center h-100 ${style.how}`}
      >
        <div
          style={{ height: "49.8px" }}
          onClick={() => setOpenShareModal(true)}
          className="d-flex align-items-center px-3 py-2 link"
        >
          <div className="d-flex align-items-center">
            <FaInfoCircle
              style={{
                fontSize: "18px",
                marginRight: "5px",
              }}
            />{" "}
          </div>
          <div
            className="text-uppercase"
            style={{
              fontFamily: "clash display",
              fontSize: "18px",
              fontWeight: "600",
              letterSpacing: "1px",
            }}
          >
            FAQ{" "}
          </div>
        </div>
      </div>

      <article
        className={`${openShareModal ? style.show : ""} ${
          style.shareBoxContainer
        }`}
      >
        <div
          ref={ref}
          className={`${openShareModal ? style.show : ""} ${style.shareBoxImg}`}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              zIndex: "1",
              cursor: "default",
            }}
            className="position-relative"
          >
            <div ref={ref} className={style.shareBox}>
              <div className={style.title}>
                Frequently Asked{" "}
                <span style={{ color: "#4FA9E2" }}>Questions</span>{" "}
              </div>
              {data.map(({ title, desc, list, text }, index) => {
                const props = {
                  title,
                  desc,
                  index,
                  setAccordion,
                  accordion,
                  list,
                  text,
                };
                return <Faq {...props} />;
              })}
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};

export default Modal;

const Faq = ({ title, desc, index, setAccordion, accordion, list, text }) => {
  const [height, setHeight] = useState(80);
  const refHeight = useRef(null);

  // THIS USEFFECT IS USED TO GET DIV HEIGHT
  useEffect(() => {
    const item = data.find(({ title }) => title == accordion);
    if (accordion === item?.title) {
      setHeight(refHeight.current?.scrollHeight);
    } else {
      setHeight(80);
    }
    window.addEventListener("resize", () => {
      if (accordion == item?.title) {
        setHeight(refHeight.current?.scrollHeight);
      } else {
        setHeight(80);
      }
    });

    return () => {
      window.removeEventListener("resize", () => {
        setHeight(80);
      });
    };
  }, [accordion]);

  return (
    <div
      ref={refHeight}
      style={{ height: accordion == title ? `${height}px` : "" }}
      className={style.faqContent}
    >
      <div
        onClick={() =>
          accordion == title ? setAccordion("") : setAccordion(title)
        }
        className={style.faq}
      >
        <div className={style.faqTitle}>0{index + 1}</div>
        <div className={style.faqTitle}>{title}</div>
        <div style={{ marginLeft: "auto" }} className="">
          <div
            style={{ background: accordion == title ? "#4FA9E2" : "" }}
            className={style.sign}
          >
            {accordion == title ? <Minus /> : <Plus />}
          </div>
        </div>
      </div>
      <div className={style.desc}>{desc}</div>
      {list?.map((item) => {
        return (
          <>
            <div
              style={{
                paddingTop: "0px",
                paddingBottom: "0px",

                display: "flex",
              }}
              className={style.desc}
            >
              <div style={{ marginRight: "1vw" }} className="">
                -
              </div>
              {item}
            </div>
          </>
        );
      })}

      {text?.map((item, index) => {
        return (
          <>
            <div
              style={{
                // paddingTop: "0px",
                paddingBottom: index !== 1 && "0px",
              }}
              className={style.desc}
            >
              {item}
            </div>
          </>
        );
      })}
    </div>
  );
};
