import axios from "axios";
import React from "react";
import { useState } from "react";
import { FaCheck, FaEdit, FaShare } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../context";
import { displaySpinner } from "../recruiter/CreateAccount";
import { removeSpinner } from "../recruiter/CreateAccount";
import { ShareBox } from "../../reusableComponents/shareBox/ShareBox";
import { Navbar2 } from "../../navigation/Navbar";
import { api_host, api_url } from "./../../libs/api";
import { handleClickEvent } from "../../libs/utils";

export default function PreviewJob() {
  const navigate = useNavigate();
  const { previewJob, setCreateJob } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [gg, setGg] = useState("hhhhhhh. vvvv");
  const [success, setSuccess] = useState(false);
  const [show, setShow] = useState(false);
  const descrip = previewJob.description;
  const el = document.getElementById("text");
  const hgg = gg.replaceAll(/\./g, "<br/>");

  const submit = () => {
    handleClickEvent({ action: "create new job", label: "Publish Job" });
    setLoading(true);
    const spinner = displaySpinner(); // Display spinner
    const date = new Date();
    const key = JSON.parse(localStorage.getItem("key"));

    const requestBody = {
      ...previewJob,
      country: "USA",
      questionnaire_required:
        previewJob.questionnaire.length > 0 ? true : false,
      questionnaire: previewJob.questionnaire,
      passing_score: previewJob.passing_score,
    };
    axios
      .post(
        `${api_url}createjob/`,
        { ...requestBody },

        {
          headers: {
            Authorization: `Token ${key}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        removeSpinner(spinner); // Remove spinner
        console.log(res.data);
        if (res.data.msg === "Job created successfully") {
          setLoading(false);
          setSuccess(true);
        } else if (res.data.error === "Plan type not provided.") {
          navigate("/subscription");
          setCreateJob(requestBody);
        } else if (res.data.error === "redirect_for_payment") {
          navigate("/subscription");
        } else {
          setSuccess(false);
        }
      })
      .catch((err) => {
        removeSpinner(spinner); // Remove spinner
        console.log("error");
        setSuccess(false);
      });
  };

  return (
    <article className={`job_searc`}>
      <Navbar2 />
      {console.log(previewJob?.questionnaire)}
      <div className="container">
        <div className="px-lg-5 mx-xl-4">
          <div className="container">
            <div className="row justify-content-between">
              <div
                onClick={() => navigate(-1)}
                style={{
                  fontFamily: "inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  width: "fit-content",
                }}
                className="rounded primary_bg_color py-2 px-3 my-4 cursor-pointer link"
              >
                <div className="white">Back</div>
              </div>
              <div
                onClick={() => navigate(-1)}
                style={{
                  fontFamily: "inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  width: "fit-content",
                }}
                className="rounded primary_bg_color py-2 px-3 my-4 cursor-pointer"
              >
                <div className="white d-flex align-items-center gap-1">
                  <FaEdit /> Edit{" "}
                </div>
              </div>
            </div>
            <div className="row">
              <div
                style={{
                  borderTop: "0.1px solid rgba(221, 221, 221, 1)",
                  borderBottom: "0.1px solid rgba(221, 221, 221, 1)",
                  borderRight: "7px solid #4897D8",
                  borderLeft: "7px solid #4897D8",
                  borderRadius: "8px",
                }}
                className="col-12 my-2 cursor pt-3 bg-white position-relative"
                onClick={() => setShow(!show)}
              >
                <img
                  src={URL.createObjectURL(previewJob.image)}
                  className="d-sm-none d-block w-100"
                  height={"150px"}
                  alt=""
                />
                <img
                  src={URL.createObjectURL(previewJob.image)}
                  className="d-none d-sm-block img-right"
                  alt=""
                />
                <div className="row ">
                  <div className="col-10">
                    <span
                      className="text-capitalize"
                      style={{
                        fontFamily: "inter",
                        fontSize: "28px",
                        fontWeight: "700",
                        lineHeight: "33.89px",
                      }}
                    >
                      {previewJob.title}
                    </span>{" "}
                    <div className=" mt-2 d-flex gap-3 flex-wrap">
                      <div
                        className="text-capitalize"
                        style={{
                          fontFamily: "inter",
                          fontSize: "22px",
                          fontWeight: "700",
                        }}
                      >
                        {previewJob.company}
                      </div>
                      <small
                        className="smallBadge"
                        style={{
                          color: "#f19306",
                          fontFamily: "inter",
                          fontWeight: "400",
                        }}
                      >
                        {previewJob.industry}
                      </small>
                      <small
                        className="smallBadge"
                        style={{
                          color: "#f19306",
                          fontFamily: "inter",
                          fontWeight: "400",
                        }}
                      >
                        {previewJob.type}
                      </small>
                      <small
                        className="smallBadge"
                        style={{
                          color: "#f19306",
                          fontFamily: "inter",
                          fontWeight: "400",
                        }}
                      >
                        {previewJob.salary}
                      </small>
                      <small
                        className="smallBadge"
                        style={{
                          color: "#f19306",
                          fontFamily: "inter",
                          fontWeight: "400",
                        }}
                      >
                        {previewJob.experience}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-2 mb-md-2">
                  <div
                    style={{
                      fontFamily: "inter",
                      fontSize: "24px",
                      fontWeight: "700",
                    }}
                    className=""
                  >
                    {"Job Description"}
                  </div>
                  <div className="mx-2 text-muted">
                    <small style={{ fontWeight: "bolder" }}>{"Just now"}</small>
                  </div>
                </div>
                <div
                  style={{
                    fontFamily: "inter",
                    fontSize: "1.125rem",
                    lineHeight: "1.7rem",
                    whiteSpace: "pre-wrap",
                  }}
                  className=""
                >
                  {show ? (
                    descrip
                  ) : (
                    <div className="readMore">
                      {descrip?.substring(0, 280)}
                      <small className="mx-0 px-0 text-muted">...</small>
                    </div>
                  )}
                </div>
                <div className={show ? "d-block" : "d-none"}>
                  <div
                    style={{
                      fontFamily: "inter",
                      fontSize: "24px",
                      fontWeight: "700",
                    }}
                    className="mt-4 mb-2"
                  >
                    {"Requirements"}
                  </div>
                  <div
                    style={{
                      fontFamily: "inter",
                      fontSize: "1.125rem",
                      lineHeight: "1.7rem",
                      whiteSpace: "pre-wrap",
                    }}
                    className=""
                  >
                    {previewJob.requirements}
                  </div>
                  <div
                    style={{
                      fontFamily: "inter",
                      fontSize: "24px",
                      fontWeight: "700",
                    }}
                    className="mt-4 mb-2"
                  >
                    {"Qualifications"}
                  </div>
                  <div
                    style={{
                      fontFamily: "inter",
                      fontSize: "1.125rem",
                      lineHeight: "1.7rem",
                      whiteSpace: "pre-wrap",
                    }}
                    className=""
                  >
                    {previewJob.qualifications}
                  </div>
                </div>
                <div className="d-flex flex-wrap gap-3 my-3">
                  <small
                    className="smallBadge"
                    style={{
                      color: "#f19306",
                      fontFamily: "inter",
                      fontWeight: "400",
                    }}
                  >
                    {previewJob.state}
                  </small>
                  <small
                    className="smallBadge"
                    style={{
                      color: "#f19306",
                      fontFamily: "inter",
                      fontWeight: "400",
                    }}
                  >
                    {previewJob.city}
                  </small>
                  <small
                    className="smallBadge"
                    style={{
                      color: "#f19306",
                      fontFamily: "inter",
                      fontWeight: "400",
                    }}
                  >
                    {previewJob.role}
                  </small>
                  <small
                    className="smallBadge"
                    style={{
                      color: "#f19306",
                      fontFamily: "inter",
                      fontWeight: "400",
                    }}
                  >
                    {previewJob.salestype}
                  </small>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="d-sm-flex d-block">
                    <div
                      style={{
                        background: "rgba(241, 147, 6, 1)",
                        borderRadius: "3px",
                      }}
                      className="btnHover text-white px-3 py-2"
                    >
                      {"Click To Apply"}
                    </div>
                    <ShareBox
                      url={`${api_host}/job-detail/` + previewJob.time}
                      title={previewJob.title}
                      disabled={true}
                    />
                  </div>
                  <div
                    onClick={() => setShow(!show)}
                    className="primary_color cursor-pointer link  mx-md-4"
                  >
                    {show ? <div>Show less</div> : <div>Show more</div>}
                  </div>
                </div>
              </div>
              <div
                onClick={submit}
                style={{
                  // pointerEvents: loading ? "none" : "false",
                  fontFamily: "inter",
                  fontSize: "16px",
                  fontWeight: "600",
                  width: "fit-content",
                }}
                className="rounded light-red py-2 px-3 my-3 mx-4 cursor-pointer"
              >
                <div className="white">Publish Job</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* SUCCESS POP-UP */}
      <div className={`${success ? "show" : ""} account_success d-flex`}>
        <div
          style={{ height: "fit-content" }}
          className="col-10 col-sm-10 col-md-6 col-lg-5 text-center bg-white mx-auto py-5 px-5 position-relative"
        >
          <div
            style={{
              width: "103.33px",
              height: "102.5px",
              border: "0.3rem solid rgba(101, 202, 117, 1)",
              borderRadius: "100%",
            }}
            className="bg-white d-flex align-items-center justify-content-center mx-auto mt-5"
          >
            <FaCheck
              style={{
                width: "41.33px",
                height: "30.75px",
                color: "rgba(101, 202, 117, 1)",
              }}
              className=""
            />{" "}
          </div>
          <div
            style={{
              fontWeight: "700",
              fontFamily: "inter",
              fontSize: "18px",
              lineHeight: "21.78px",
            }}
            className="primary_color mb-1 mt-3"
          >
            Job Creation Successful
          </div>
          <div
            style={{
              fontWeight: "400",
              fontFamily: "inter",
              fontSize: "16px",
              lineHeight: "28px",
              color: "rgba(134, 146, 166, 1)",
            }}
            className=""
          >
            Your job has been created.
          </div>

          <div
            onClick={() => (window.location.href = "/jobs")}
            style={{
              fontWeight: "500",
              fontFamily: "inter",
              fontSize: "20px",
              lineHeight: "24.2px",
            }}
            className="primary_bg_color white rounded py-3 mb-5 mt-2 cursor-pointer "
          >
            OK
          </div>
        </div>
      </div>
    </article>
  );
}
