import React, { useState } from "react";
import { useGlobalContext } from "../../context";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import Popup from "../popup/Popup";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { api_host } from "../../libs/api";

export default function ConfirmPassword() {
  const user = useGlobalContext();
  const [searchParams, setSearchParams] = useSearchParams("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [success, setSuccess] = useState(false);
  const [text, setText] = useState("");
  const [prevPath, setPrevPath] = useState("");

  const login = (e) => {
    e.preventDefault();
    const key = JSON.parse(localStorage.getItem("key"));

    axios
      .post(`${api_host}/rest-auth/password/reset/confirm/`, {
        new_password1: password,
        new_password2: password2,
        uid: searchParams.get("uid"),
        token: searchParams.get("token"),
      })
      .then((res) => {
        setPassword("");
        setPassword2("");

        // Display response from API
        if (res.data) {
          const responseKeys = Object.keys(res.data);
          let responseText = "";
          responseKeys.forEach((key) => {
            responseText += `${key}: ${res.data[key]}\n`;
          });
          const notify = () => toast.success(responseText);
          notify();
        }
      })
      .catch((err) => {
        // Display error response from API
        if (err.response) {
          const errorKeys = Object.keys(err.response.data);
          let errorText = "";
          errorKeys.forEach((key) => {
            errorText += `${key}: ${err.response.data[key]}\n`;
          });
          setText(errorText);
          const notify = () => toast.error(errorText);
          notify();
        }
      });
  };

  useEffect(() => {
    setTimeout(() => {
      if (success) {
        setSuccess(false);
      }
    }, 4000);
  }, [success]);

  useEffect(() => {
    const path = JSON.parse(localStorage.getItem("login"));
    setPrevPath(path);
  }, []);

  return (
    <div
      className="position-fixed"
      style={{
        zIndex: "200000000000000",
        top: "61px",
        right: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
        backgroundColor: "#f1fcff",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <ToastContainer />
      <div className="bg-white container-fluid h-100">
        <div
          style={{
            position: "fixed",
            zIndex: "2",
            top: "61px",
            left: "0",
            width: "50%",
            height: "100vh",
          }}
          className=" d-none d-md-block"
        >
          <img
            src={`/static/media/figma ${prevPath !== 2 ? "2" : "1"}.png`}
            alt=""
            className="w-100"
            style={{ height: "100%", objectFit: "cover" }}
          />
        </div>
        <div className="container-fluid">
          <div className="position-relative pt-5 pb-2 auth-margin ">
            <form onSubmit={login} className="mx-xl-5 mt-5 px-xl-3 pt-md-4">
              <div
                className="black"
                style={{
                  fontFamily: "roboto",
                  fontSize: "1.13rem",
                  fontWeight: "600",
                }}
              >
                Enter new password to reset your password.
              </div>
              <div
                style={{
                  fontFamily: "roboto",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
                className="mt-3 mt-md-4"
              >
                Password*
              </div>
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder="Enter your password"
                style={{
                  color: "rgba(124, 146, 166, 1)",
                  fontFamily: "roboto",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
                required
                className={` py-2 px-3 login_input rounded`}
              />
              <div
                style={{
                  fontFamily: "roboto",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
                className="mt-3 mt-md-4"
              >
                Confirm password*
              </div>
              <input
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
                type="password"
                placeholder="Enter your password"
                style={{
                  color: "rgba(124, 146, 166, 1)",
                  fontFamily: "roboto",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
                required
                className={` py-2 px-3 login_input rounded`}
              />

              <button
                style={{
                  fontFamily: "roboto",
                  fontWeight: "600",
                  letterSpacing: "0.2vw",
                }}
                type="submit"
                className="rounded primary_bg_color text-center py-3 text-white cursor-pointer w-100 border-0 mt-3 "
              >
                Confirm
              </button>
            </form>
          </div>
        </div>
      </div>
      <nav
        style={{
          position: "fixed",
          zIndex: "1",
          top: "0",
          left: "0",
          right: "0",
        }}
        className="py-2 shadow-sm  d-none d-sm-block bg-white"
      >
        <div className={"row px-3"}>
          <div className="col-12 mx-auto">
            <div className="d-flex justify-content-between align-items-center">
              <img
                src="./static/media/into sales.png"
                style={{
                  width: "174px",
                  height: "45px",
                  objectFit: "contain",
                }}
                className=""
                alt=""
              />
              <div className="d-flex align-items-center">
                <div
                  style={{
                    fontStyle: "inter",
                    background: "#F19306",
                    borderRadius: "100%",
                    fontSize: "1rem",
                    fontWeight: "500",
                  }}
                  className="rounded mx-3 white px-3 py-2 cursor-pointer btnHover"
                >
                  {"Update Password"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
