export const salesData = [
  { selected: false, label: "Outside Sales", value: "Outside sales" },
  { selected: false, label: "Inside Sales", value: "Inside sales" },
  { selected: false, label: "B2B Sales", value: "B2B sales" },
  { selected: false, label: "B2C Sales", value: "B2C sales" },
];

export const jobData = [
  { selected: false, label: "Full Time", value: "Full time" },
  { selected: false, label: "Part Time", value: "Part time" },
  { selected: false, label: "Remote", value: "Remote" },
  { selected: false, label: "Shifts", value: "Shifts" },
  { selected: false, label: "Commission Only", value: "Commission Only" },
];

export const roleData = [
  {
    selected: false,
    label: "Outside Sales Representative",
    value: "Outside sales representative",
  },
  {
    selected: false,
    label: "Inside Sales Representative",
    value: "Inside sales representative",
  },
  { selected: false, label: "Sales Manager", value: "Sales manager" },
  { selected: false, label: "Director of Sales", value: "Director of sales" },
  {
    selected: false,
    label: "Regional Sales Manager",
    value: "Regional sales manager",
  },
  { selected: false, label: "Account Manager", value: "Account manager" },
  {
    selected: false,
    label: "Sales Representative",
    value: "Sales representative",
  },
  { selected: false, label: "Sales Agent", value: "Sales agent" },
  {
    selected: false,
    label: "Sales Operations Manager",
    value: "Sales operations manager",
  },
  { selected: false, label: "Sales Associate", value: "Sales associate" },
  {
    selected: false,
    label: "Technical Sales Engineer",
    value: "Technical sales engineer",
  },
  {
    selected: false,
    label: "Sales Development Rep",
    value: "Sales Development Rep",
  },
  { selected: false, label: "Sales Consultant", value: "Sales Consultant" },
  { selected: false, label: "Account Executive", value: "Account Executive" },
  {
    selected: false,
    label: "Customer Success Manager",
    value: "Customer success manager",
  },
  { selected: false, label: "Sales Engineer", value: "Sales engineer" },
  {
    selected: false,
    label: "Vice President of Sales",
    value: "Vice president of sales",
  },
  {
    selected: false,
    label: "Chief Sales Officer",
    value: "Chief sales officer",
  },
  {
    selected: false,
    label: "Real Estate Broker",
    value: "Real Estate Broker",
  },
  {
    selected: false,
    label: "Cold Calling",
    value: "Cold Calling",
  },
  {
    selected: false,
    label: "Telemarketing",
    value: "Telemarketing",
  },
  {
    selected: false,
    label: "Appointment Maker",
    value: "Appointment Maker",
  },
  {
    selected: false,
    label: "Lead Qualification",
    value: "Lead Qualification",
  },
  {
    selected: false,
    label: "Sales Assistant",
    value: "Sales Assistant",
  },
  {
    selected: false,
    label: "Sales Advisor",
    value: "Sales Advisor",
  },
  {
    selected: false,
    label: "Team Manager",
    value: "Team Manager",
  },
];

export const industryData = [
  { selected: false, label: "Car Dealership", value: "Car dealership" },
  { selected: false, label: "Cosmetics", value: "Cosmetics" },
  { selected: false, label: "Electronics", value: "Electronics" },
  { selected: false, label: "Real Estate", value: "Real estate" },
  { selected: false, label: "Insurance", value: "Insurance" },
  { selected: false, label: "Technology", value: "Technology" },
  { selected: false, label: "Production", value: "Production" },
  { selected: false, label: "Media", value: "Media" },
  { selected: false, label: "Pharmaceutical", value: "Pharmaceutical" },
  { selected: false, label: "Fitness", value: "Fitness" },
  { selected: false, label: "Distribution", value: "Distribution" },
  { selected: false, label: "Finance", value: "Finance" },
  { selected: false, label: "Investment", value: "Investment" },
  { selected: false, label: "Health Care", value: "Health care" },
  {
    selected: false,
    label: "Personal Development",
    value: "Personal Development",
  },
  { selected: false, label: "Telecommunications", value: "Telecommunications" },
  { selected: false, label: "Logistics", value: "Logistics" },
  { selected: false, label: "Education", value: "Education" },
  { selected: false, label: "Advertising", value: "Advertising" },
  { selected: false, label: "Foodservice", value: "Foodservice" },
  { selected: false, label: "Consulting", value: "Consulting" },
  { selected: false, label: "Fashion", value: "Fashion" },
  { selected: false, label: "Financial Services", value: "Financial services" },
];

export const salaryData = [
  // { selected: false, label: "3k - 5k USD", value: "3k - 5k USD" },
  // { selected: false, label: "5k - 10k USD", value: "5k - 10k USD" },
  // { selected: false, label: "5k - 15k USD", value: "5k - 15k USD" },
  { selected: false, label: "10k - 15k USD", value: "10k - 15k USD" },
  { selected: false, label: "10k - 25k USD", value: "10k - 25k USD" },
  { selected: false, label: "10k - 35k USD", value: "10k- 35k USD" },
  { selected: false, label: "20k - 50k USD", value: "20k - 50k USD" },
  { selected: false, label: "50k - 100k USD", value: "50k - 100k USD" },
  { selected: false, label: "100k - 150k USD", value: "100k - 150k USD" },
  { selected: false, label: "100k - 250k USD", value: "100k - 250k USD" },
  { selected: false, label: "High Commission", value: "High commission" },
  { selected: false, label: "High Base Salary", value: "High base salary" },
  { selected: false, label: "High Salary", value: "High salary" },
];

export const expData = [
  {
    selected: false,
    label: "No experience required",
    value: "Experience not required",
  },
  {
    selected: false,
    label: "1 Year Of Experience",
    value: "1 year of experience",
  },
  {
    selected: false,
    label: "2 Years Of Experience",
    value: "2 year of experience",
  },
  {
    selected: false,
    label: "3 Years Of Experience",
    value: "3 year of experience",
  },
  {
    selected: false,
    label: "4 Years Of Experience",
    value: "4 year of experience",
  },
  {
    selected: false,
    label: "5 Years Of Experience",
    value: "5 year of experience",
  },
  {
    selected: false,
    label: "6 Years Of Experience",
    value: "6 year of experience",
  },
  {
    selected: false,
    label: "7 Years Of Experience",
    value: "7 year of experience",
  },
  {
    selected: false,
    label: "8 Years Of Experience",
    value: "8 year of experience",
  },
  {
    selected: false,
    label: "Experience Required",
    value: "Experience Required",
  },
];

export const locations = [
  {
    selected: false,
    label: "Alabama",
    value: "Alabama",
  },
  {
    selected: false,
    label: "Alaska",
    value: "Alaska",
  },
  {
    selected: false,
    label: "Arizona",
    value: "Arizona",
  },
  {
    selected: false,
    label: "Arkansas",
    value: "Arkansas",
  },
  {
    selected: false,
    label: "California",
    value: "California",
  },
  {
    selected: false,
    label: "Colorado",
    value: "Colorado",
  },
  {
    selected: false,
    label: "Connecticut",
    value: "Connecticut",
  },
  {
    selected: false,
    label: "Delaware",
    value: "Delaware",
  },
  {
    selected: false,
    label: "Florida",
    value: "Florida",
  },
  {
    selected: false,
    label: "Georgia",
    value: "Georgia",
  },
  {
    selected: false,
    label: "Hawaii",
    value: "Hawaii",
  },
  {
    selected: false,
    label: "Idaho",
    value: "Idaho",
  },
  {
    selected: false,
    label: "Illinois",
    value: "Illinois",
  },
  {
    selected: false,
    label: "Indiana",
    value: "Indiana",
  },
  {
    selected: false,
    label: "Iowa",
    value: "Iowa",
  },
  {
    selected: false,
    label: "Kansas",
    value: "Kansas",
  },
  {
    selected: false,
    label: "Kentucky",
    value: "Kentucky",
  },
  {
    selected: false,
    label: "Louisiana",
    value: "Louisiana",
  },
  {
    selected: false,
    label: "Maine",
    value: "Maine",
  },
  {
    selected: false,
    label: "Maryland",
    value: "Maryland",
  },
  {
    selected: false,
    label: "Massachusetts",
    value: "Massachusetts",
  },
  {
    selected: false,
    label: "Michigan",
    value: "Michigan",
  },
  {
    selected: false,
    label: "Minnesota",
    value: "Minnesota",
  },
  {
    selected: false,
    label: "Mississippi",
    value: "Mississippi",
  },
  {
    selected: false,
    label: "Missouri",
    value: "Missouri",
  },
  {
    selected: false,
    label: "Montana",
    value: "Montana",
  },
  {
    selected: false,
    label: "Nebraska",
    value: "Nebraska",
  },
  {
    selected: false,
    label: "Nevada",
    value: "Nevada",
  },
  {
    selected: false,
    label: "New Hampshire",
    value: "New Hampshire",
  },
  {
    selected: false,
    label: "New Jersey",
    value: "New Jersey",
  },
  {
    selected: false,
    label: "New Mexico",
    value: "New Mexico",
  },
  {
    selected: false,
    label: "New York",
    value: "New York",
  },
  {
    selected: false,
    label: "North Carolina",
    value: "North Carolina",
  },
  {
    selected: false,
    label: "North Dakota",
    value: "North Dakota",
  },
  {
    selected: false,
    label: "Ohio",
    value: "Ohio",
  },
  {
    selected: false,
    label: "Oklahoma",
    value: "Oklahoma",
  },
  {
    selected: false,
    label: "Oregon",
    value: "Oregon",
  },
  {
    selected: false,
    label: "Pennsylvania",
    value: "Pennsylvania",
  },
  {
    selected: false,
    label: "Rhode Island",
    value: "Rhode Island",
  },
  {
    selected: false,
    label: "South Carolina",
    value: "South Carolina",
  },
  {
    selected: false,
    label: "South Dakota",
    value: "South Dakota",
  },
  {
    selected: false,
    label: "Tennessee",
    value: "Tennessee",
  },
  {
    selected: false,
    label: "Texas",
    value: "Texas",
  },
  {
    selected: false,
    label: "Utah",
    value: "Utah",
  },
  {
    selected: false,
    label: "Vermont",
    value: "Vermont",
  },
  {
    selected: false,
    label: "Virginia",
    value: "Virginia",
  },
  {
    selected: false,
    label: "Washington",
    value: "Washington",
  },
  {
    selected: false,
    label: "West Virginia",
    value: "West Virginia",
  },
  {
    selected: false,
    label: "Wisconsin",
    value: "Wisconsin",
  },
  {
    selected: false,
    label: "Wyoming",
    value: "Wyoming",
  },
];

export const cities = [
  { selected: false, label: "New York" },
  { selected: false, label: "Buffalo" },
  { selected: false, label: "Rochester" },
  { selected: false, label: "Yonkers" },
  { selected: false, label: "Syracuse" },
  { selected: false, label: "Albany" },
  { selected: false, label: "New Rochelle" },
  { selected: false, label: "Mount Vernon" },
  { selected: false, label: "Schenectady" },
  { selected: false, label: "Utica" },
  { selected: false, label: "White Plains" },
  { selected: false, label: "Hempstead" },
  { selected: false, label: "Troy" },
  { selected: false, label: "Niagara Falls" },
  { selected: false, label: "Binghamton" },
  { selected: false, label: "Freeport" },
  { selected: false, label: "Valley Stream" },
  { selected: false, label: "Los Angeles" },
  { selected: false, label: "San Diego" },
  { selected: false, label: "San Jose" },
  { selected: false, label: "San Francisco" },
  { selected: false, label: "Fresno" },
  { selected: false, label: "Sacramento" },
  { selected: false, label: "Long Beach" },
  { selected: false, label: "Oakland" },
  { selected: false, label: "Bakersfield" },
  { selected: false, label: "Anaheim" },
  { selected: false, label: "Santa Ana" },
  { selected: false, label: "Riverside" },
  { selected: false, label: "Stockton" },
  { selected: false, label: "Chula Vista" },
  { selected: false, label: "Irvine" },
  { selected: false, label: "Fremont" },
  { selected: false, label: "San Bernardino" },
  { selected: false, label: "Modesto" },
  { selected: false, label: "Fontana" },
  { selected: false, label: "Oxnard" },
  { selected: false, label: "Moreno Valley" },
  { selected: false, label: "Huntington Beach" },
  { selected: false, label: "Glendale" },
  { selected: false, label: "Santa Clarita" },
  { selected: false, label: "Garden Grove" },
  { selected: false, label: "Oceanside" },
  { selected: false, label: "Rancho Cucamonga" },
  { selected: false, label: "Santa Rosa" },
  { selected: false, label: "Ontario" },
  { selected: false, label: "Lancaster" },
  { selected: false, label: "Elk Grove" },
  { selected: false, label: "Corona" },
  { selected: false, label: "Palmdale" },
  { selected: false, label: "Salinas" },
  { selected: false, label: "Pomona" },
  { selected: false, label: "Hayward" },
  { selected: false, label: "Escondido" },
  { selected: false, label: "Torrance" },
  { selected: false, label: "Sunnyvale" },
  { selected: false, label: "Orange" },
  { selected: false, label: "Fullerton" },
  { selected: false, label: "Pasadena" },
  { selected: false, label: "Thousand Oaks" },
  { selected: false, label: "Visalia" },
  { selected: false, label: "Simi Valley" },
  { selected: false, label: "Concord" },
  { selected: false, label: "Roseville" },
  { selected: false, label: "Victorville" },
  { selected: false, label: "Santa Clara" },
  { selected: false, label: "Vallejo" },
  { selected: false, label: "Berkeley" },
  { selected: false, label: "El Monte" },
  { selected: false, label: "Downey" },
  { selected: false, label: "Costa Mesa" },
  { selected: false, label: "Inglewood" },
  { selected: false, label: "Carlsbad" },
  { selected: false, label: "San Buenaventura (Ventura)" },
  { selected: false, label: "Fairfield" },
  { selected: false, label: "West Covina" },
  { selected: false, label: "Murrieta" },
  { selected: false, label: "Richmond" },
  { selected: false, label: "Norwalk" },
  { selected: false, label: "Antioch" },
  { selected: false, label: "Temecula" },
  { selected: false, label: "Burbank" },
  { selected: false, label: "Daly City" },
  { selected: false, label: "Rialto" },
  { selected: false, label: "Santa Maria" },
  { selected: false, label: "El Cajon" },
  { selected: false, label: "San Mateo" },
  { selected: false, label: "Clovis" },
  { selected: false, label: "Compton" },
  { selected: false, label: "Jurupa Valley" },
  { selected: false, label: "Vista" },
  { selected: false, label: "South Gate" },
  { selected: false, label: "Mission Viejo" },
  { selected: false, label: "Vacaville" },
  { selected: false, label: "Carson" },
  { selected: false, label: "Hesperia" },
  { selected: false, label: "Santa Monica" },
  { selected: false, label: "Westminster" },
  { selected: false, label: "Redding" },
  { selected: false, label: "Santa Barbara" },
  { selected: false, label: "Chico" },
  { selected: false, label: "Newport Beach" },
  { selected: false, label: "San Leandro" },
  { selected: false, label: "San Marcos" },
  { selected: false, label: "Whittier" },
  { selected: false, label: "Hawthorne" },
  { selected: false, label: "Citrus Heights" },
  { selected: false, label: "Tracy" },
  { selected: false, label: "Alhambra" },
  { selected: false, label: "Livermore" },
  { selected: false, label: "Buena Park" },
  { selected: false, label: "Menifee" },
  { selected: false, label: "Hemet" },
  { selected: false, label: "Lakewood" },
  { selected: false, label: "Merced" },
  { selected: false, label: "Chino" },
  { selected: false, label: "Indio" },
  { selected: false, label: "Redwood City" },
  { selected: false, label: "Lake Forest" },
  { selected: false, label: "Napa" },
  { selected: false, label: "Tustin" },
  { selected: false, label: "Bellflower" },
  { selected: false, label: "Mountain View" },
  { selected: false, label: "Chino Hills" },
  { selected: false, label: "Baldwin Park" },
  { selected: false, label: "Alameda" },
  { selected: false, label: "Upland" },
  { selected: false, label: "San Ramon" },
  { selected: false, label: "Folsom" },
  { selected: false, label: "Pleasanton" },
  { selected: false, label: "Union City" },
  { selected: false, label: "Perris" },
  { selected: false, label: "Manteca" },
  { selected: false, label: "Lynwood" },
  { selected: false, label: "Apple Valley" },
  { selected: false, label: "Redlands" },
  { selected: false, label: "Turlock" },
  { selected: false, label: "Milpitas" },
  { selected: false, label: "Redondo Beach" },
  { selected: false, label: "Rancho Cordova" },
  { selected: false, label: "Yorba Linda" },
  { selected: false, label: "Palo Alto" },
  { selected: false, label: "Davis" },
  { selected: false, label: "Camarillo" },
  { selected: false, label: "Walnut Creek" },
  { selected: false, label: "Pittsburg" },
  { selected: false, label: "South San Francisco" },
  { selected: false, label: "Yuba City" },
  { selected: false, label: "San Clemente" },
  { selected: false, label: "Laguna Niguel" },
  { selected: false, label: "Pico Rivera" },
  { selected: false, label: "Montebello" },
  { selected: false, label: "Lodi" },
  { selected: false, label: "Madera" },
  { selected: false, label: "Santa Cruz" },
  { selected: false, label: "La Habra" },
  { selected: false, label: "Encinitas" },
  { selected: false, label: "Monterey Park" },
  { selected: false, label: "Tulare" },
  { selected: false, label: "Cupertino" },
  { selected: false, label: "Gardena" },
  { selected: false, label: "National City" },
  { selected: false, label: "Rocklin" },
  { selected: false, label: "Petaluma" },
  { selected: false, label: "Huntington Park" },
  { selected: false, label: "San Rafael" },
  { selected: false, label: "La Mesa" },
  { selected: false, label: "Arcadia" },
  { selected: false, label: "Fountain Valley" },
  { selected: false, label: "Diamond Bar" },
  { selected: false, label: "Woodland" },
  { selected: false, label: "Santee" },
  { selected: false, label: "Lake Elsinore" },
  { selected: false, label: "Porterville" },
  { selected: false, label: "Paramount" },
  { selected: false, label: "Eastvale" },
  { selected: false, label: "Rosemead" },
  { selected: false, label: "Hanford" },
  { selected: false, label: "Highland" },
  { selected: false, label: "Brentwood" },
  { selected: false, label: "Novato" },
  { selected: false, label: "Colton" },
  { selected: false, label: "Cathedral City" },
  { selected: false, label: "Delano" },
  { selected: false, label: "Yucaipa" },
  { selected: false, label: "Watsonville" },
  { selected: false, label: "Placentia" },
  { selected: false, label: "Glendora" },
  { selected: false, label: "Gilroy" },
  { selected: false, label: "Palm Desert" },
  { selected: false, label: "Cerritos" },
  { selected: false, label: "West Sacramento" },
  { selected: false, label: "Aliso Viejo" },
  { selected: false, label: "Poway" },
  { selected: false, label: "La Mirada" },
  { selected: false, label: "Rancho Santa Margarita" },
  { selected: false, label: "Cypress" },
  { selected: false, label: "Dublin" },
  { selected: false, label: "Covina" },
  { selected: false, label: "Azusa" },
  { selected: false, label: "Palm Springs" },
  { selected: false, label: "San Luis Obispo" },
  { selected: false, label: "Ceres" },
  { selected: false, label: "San Jacinto" },
  { selected: false, label: "Lincoln" },
  { selected: false, label: "Newark" },
  { selected: false, label: "Lompoc" },
  { selected: false, label: "El Centro" },
  { selected: false, label: "Danville" },
  { selected: false, label: "Bell Gardens" },
  { selected: false, label: "Coachella" },
  { selected: false, label: "Rancho Palos Verdes" },
  { selected: false, label: "San Bruno" },
  { selected: false, label: "Rohnert Park" },
  { selected: false, label: "Brea" },
  { selected: false, label: "La Puente" },
  { selected: false, label: "Campbell" },
  { selected: false, label: "San Gabriel" },
  { selected: false, label: "Beaumont" },
  { selected: false, label: "Morgan Hill" },
  { selected: false, label: "Culver City" },
  { selected: false, label: "Calexico" },
  { selected: false, label: "Stanton" },
  { selected: false, label: "La Quinta" },
  { selected: false, label: "Pacifica" },
  { selected: false, label: "Montclair" },
  { selected: false, label: "Oakley" },
  { selected: false, label: "Monrovia" },
  { selected: false, label: "Los Banos" },
  { selected: false, label: "Martinez" },
  { selected: false, label: "Chicago" },
  { selected: false, label: "Aurora" },
  { selected: false, label: "Rockford" },
  { selected: false, label: "Joliet" },
  { selected: false, label: "Naperville" },
  { selected: false, label: "Springfield" },
  { selected: false, label: "Peoria" },
  { selected: false, label: "Elgin" },
  { selected: false, label: "Waukegan" },
  { selected: false, label: "Cicero" },
  { selected: false, label: "Champaign" },
  { selected: false, label: "Bloomington" },
  { selected: false, label: "Arlington Heights" },
  { selected: false, label: "Evanston" },
  { selected: false, label: "Decatur" },
  { selected: false, label: "Schaumburg" },
  { selected: false, label: "Bolingbrook" },
  { selected: false, label: "Palatine" },
  { selected: false, label: "Skokie" },
  { selected: false, label: "Des Plaines" },
  { selected: false, label: "Orland Park" },
  { selected: false, label: "Tinley Park" },
  { selected: false, label: "Oak Lawn" },
  { selected: false, label: "Berwyn" },
  { selected: false, label: "Mount Prospect" },
  { selected: false, label: "Normal" },
  { selected: false, label: "Wheaton" },
  { selected: false, label: "Hoffman Estates" },
  { selected: false, label: "Oak Park" },
  { selected: false, label: "Downers Grove" },
  { selected: false, label: "Elmhurst" },
  { selected: false, label: "Glenview" },
  { selected: false, label: "DeKalb" },
  { selected: false, label: "Lombard" },
  { selected: false, label: "Belleville" },
  { selected: false, label: "Moline" },
  { selected: false, label: "Buffalo Grove" },
  { selected: false, label: "Bartlett" },
  { selected: false, label: "Urbana" },
  { selected: false, label: "Quincy" },
  { selected: false, label: "Crystal Lake" },
  { selected: false, label: "Plainfield" },
  { selected: false, label: "Streamwood" },
  { selected: false, label: "Carol Stream" },
  { selected: false, label: "Romeoville" },
  { selected: false, label: "Rock Island" },
  { selected: false, label: "Hanover Park" },
  { selected: false, label: "Carpentersville" },
  { selected: false, label: "Wheeling" },
  { selected: false, label: "Park Ridge" },
  { selected: false, label: "Addison" },
  { selected: false, label: "Calumet City" },
  { selected: false, label: "Houston" },
  { selected: false, label: "San Antonio" },
  { selected: false, label: "Dallas" },
  { selected: false, label: "Austin" },
  { selected: false, label: "Fort Worth" },
  { selected: false, label: "El Paso" },
  { selected: false, label: "Arlington" },
  { selected: false, label: "Corpus Christi" },
  { selected: false, label: "Plano" },
  { selected: false, label: "Laredo" },
  { selected: false, label: "Lubbock" },
  { selected: false, label: "Garland" },
  { selected: false, label: "Irving" },
  { selected: false, label: "Amarillo" },
  { selected: false, label: "Grand Prairie" },
  { selected: false, label: "Brownsville" },
  { selected: false, label: "Pasadena" },
  { selected: false, label: "McKinney" },
  { selected: false, label: "Mesquite" },
  { selected: false, label: "McAllen" },
  { selected: false, label: "Killeen" },
  { selected: false, label: "Frisco" },
  { selected: false, label: "Waco" },
  { selected: false, label: "Carrollton" },
  { selected: false, label: "Denton" },
  { selected: false, label: "Midland" },
  { selected: false, label: "Abilene" },
  { selected: false, label: "Beaumont" },
  { selected: false, label: "Round Rock" },
  { selected: false, label: "Odessa" },
  { selected: false, label: "Wichita Falls" },
  { selected: false, label: "Richardson" },
  { selected: false, label: "Lewisville" },
  { selected: false, label: "Tyler" },
  { selected: false, label: "College Station" },
  { selected: false, label: "Pearland" },
  { selected: false, label: "San Angelo" },
  { selected: false, label: "Allen" },
  { selected: false, label: "League City" },
  { selected: false, label: "Sugar Land" },
  { selected: false, label: "Longview" },
  { selected: false, label: "Edinburg" },
  { selected: false, label: "Mission" },
  { selected: false, label: "Bryan" },
  { selected: false, label: "Baytown" },
  { selected: false, label: "Pharr" },
  { selected: false, label: "Temple" },
  { selected: false, label: "Missouri City" },
  { selected: false, label: "Flower Mound" },
  { selected: false, label: "Harlingen" },
  { selected: false, label: "North Richland Hills" },
  { selected: false, label: "Victoria" },
  { selected: false, label: "Conroe" },
  { selected: false, label: "New Braunfels" },
  { selected: false, label: "Mansfield" },
  { selected: false, label: "Cedar Park" },
  { selected: false, label: "Rowlett" },
  { selected: false, label: "Port Arthur" },
  { selected: false, label: "Euless" },
  { selected: false, label: "Georgetown" },
  { selected: false, label: "Pflugerville" },
  { selected: false, label: "DeSoto" },
  { selected: false, label: "San Marcos" },
  { selected: false, label: "Grapevine" },
  { selected: false, label: "Bedford" },
  { selected: false, label: "Galveston" },
  { selected: false, label: "Cedar Hill" },
  { selected: false, label: "Texas City" },
  { selected: false, label: "Wylie" },
  { selected: false, label: "Haltom City" },
  { selected: false, label: "Keller" },
  { selected: false, label: "Coppell" },
  { selected: false, label: "Rockwall" },
  { selected: false, label: "Huntsville" },
  { selected: false, label: "Duncanville" },
  { selected: false, label: "Sherman" },
  { selected: false, label: "The Colony" },
  { selected: false, label: "Burleson" },
  { selected: false, label: "Hurst" },
  { selected: false, label: "Lancaster" },
  { selected: false, label: "Texarkana" },
  { selected: false, label: "Friendswood" },
  { selected: false, label: "Weslaco" },
  { selected: false, label: "Philadelphia" },
  { selected: false, label: "Pittsburgh" },
  { selected: false, label: "Allentown" },
  { selected: false, label: "Erie" },
  { selected: false, label: "Reading" },
  { selected: false, label: "Scranton" },
  { selected: false, label: "Bethlehem" },
  { selected: false, label: "Lancaster" },
  { selected: false, label: "Harrisburg" },
  { selected: false, label: "Altoona" },
  { selected: false, label: "York" },
  { selected: false, label: "State College" },
  { selected: false, label: "Wilkes-Barre" },
  { selected: false, label: "Phoenix" },
  { selected: false, label: "Tucson" },
  { selected: false, label: "Mesa" },
  { selected: false, label: "Chandler" },
  { selected: false, label: "Glendale" },
  { selected: false, label: "Scottsdale" },
  { selected: false, label: "Gilbert" },
  { selected: false, label: "Tempe" },
  { selected: false, label: "Peoria" },
  { selected: false, label: "Surprise" },
  { selected: false, label: "Yuma" },
  { selected: false, label: "Avondale" },
  { selected: false, label: "Goodyear" },
  { selected: false, label: "Flagstaff" },
  { selected: false, label: "Buckeye" },
  { selected: false, label: "Lake Havasu City" },
  { selected: false, label: "Casa Grande" },
  { selected: false, label: "Sierra Vista" },
  { selected: false, label: "Maricopa" },
  { selected: false, label: "Oro Valley" },
  { selected: false, label: "Prescott" },
  { selected: false, label: "Bullhead City" },
  { selected: false, label: "Prescott Valley" },
  { selected: false, label: "Marana" },
  { selected: false, label: "Apache Junction" },
  { selected: false, label: "Jacksonville" },
  { selected: false, label: "Miami" },
  { selected: false, label: "Tampa" },
  { selected: false, label: "Orlando" },
  { selected: false, label: "St. Petersburg" },
  { selected: false, label: "Hialeah" },
  { selected: false, label: "Tallahassee" },
  { selected: false, label: "Fort Lauderdale" },
  { selected: false, label: "Port St. Lucie" },
  { selected: false, label: "Cape Coral" },
  { selected: false, label: "Pembroke Pines" },
  { selected: false, label: "Hollywood" },
  { selected: false, label: "Miramar" },
  { selected: false, label: "Gainesville" },
  { selected: false, label: "Coral Springs" },
  { selected: false, label: "Miami Gardens" },
  { selected: false, label: "Clearwater" },
  { selected: false, label: "Palm Bay" },
  { selected: false, label: "Pompano Beach" },
  { selected: false, label: "West Palm Beach" },
  { selected: false, label: "Lakeland" },
  { selected: false, label: "Davie" },
  { selected: false, label: "Miami Beach" },
  { selected: false, label: "Sunrise" },
  { selected: false, label: "Plantation" },
  { selected: false, label: "Boca Raton" },
  { selected: false, label: "Deltona" },
  { selected: false, label: "Largo" },
  { selected: false, label: "Deerfield Beach" },
  { selected: false, label: "Palm Coast" },
  { selected: false, label: "Melbourne" },
  { selected: false, label: "Boynton Beach" },
  { selected: false, label: "Lauderhill" },
  { selected: false, label: "Weston" },
  { selected: false, label: "Fort Myers" },
  { selected: false, label: "Kissimmee" },
  { selected: false, label: "Homestead" },
  { selected: false, label: "Tamarac" },
  { selected: false, label: "Delray Beach" },
  { selected: false, label: "Daytona Beach" },
  { selected: false, label: "North Miami" },
  { selected: false, label: "Wellington" },
  { selected: false, label: "North Port" },
  { selected: false, label: "Jupiter" },
  { selected: false, label: "Ocala" },
  { selected: false, label: "Port Orange" },
  { selected: false, label: "Margate" },
  { selected: false, label: "Coconut Creek" },
  { selected: false, label: "Sanford" },
  { selected: false, label: "Sarasota" },
  { selected: false, label: "Pensacola" },
  { selected: false, label: "Bradenton" },
  { selected: false, label: "Palm Beach Gardens" },
  { selected: false, label: "Pinellas Park" },
  { selected: false, label: "Coral Gables" },
  { selected: false, label: "Doral" },
  { selected: false, label: "Bonita Springs" },
  { selected: false, label: "Apopka" },
  { selected: false, label: "Titusville" },
  { selected: false, label: "North Miami Beach" },
  { selected: false, label: "Oakland Park" },
  { selected: false, label: "Fort Pierce" },
  { selected: false, label: "North Lauderdale" },
  { selected: false, label: "Cutler Bay" },
  { selected: false, label: "Altamonte Springs" },
  { selected: false, label: "St. Cloud" },
  { selected: false, label: "Greenacres" },
  { selected: false, label: "Ormond Beach" },
  { selected: false, label: "Ocoee" },
  { selected: false, label: "Hallandale Beach" },
  { selected: false, label: "Winter Garden" },
  { selected: false, label: "Aventura" },
  { selected: false, label: "Indianapolis" },
  { selected: false, label: "Fort Wayne" },
  { selected: false, label: "Evansville" },
  { selected: false, label: "South Bend" },
  { selected: false, label: "Carmel" },
  { selected: false, label: "Bloomington" },
  { selected: false, label: "Fishers" },
  { selected: false, label: "Hammond" },
  { selected: false, label: "Gary" },
  { selected: false, label: "Muncie" },
  { selected: false, label: "Lafayette" },
  { selected: false, label: "Terre Haute" },
  { selected: false, label: "Kokomo" },
  { selected: false, label: "Anderson" },
  { selected: false, label: "Noblesville" },
  { selected: false, label: "Greenwood" },
  { selected: false, label: "Elkhart" },
  { selected: false, label: "Mishawaka" },
  { selected: false, label: "Lawrence" },
  { selected: false, label: "Jeffersonville" },
  { selected: false, label: "Columbus" },
  { selected: false, label: "Portage" },
  { selected: false, label: "Columbus" },
  { selected: false, label: "Cleveland" },
  { selected: false, label: "Cincinnati" },
  { selected: false, label: "Toledo" },
  { selected: false, label: "Akron" },
  { selected: false, label: "Dayton" },
  { selected: false, label: "Parma" },
  { selected: false, label: "Canton" },
  { selected: false, label: "Youngstown" },
  { selected: false, label: "Lorain" },
  { selected: false, label: "Hamilton" },
  { selected: false, label: "Springfield" },
  { selected: false, label: "Kettering" },
  { selected: false, label: "Elyria" },
  { selected: false, label: "Lakewood" },
  { selected: false, label: "Cuyahoga Falls" },
  { selected: false, label: "Middletown" },
  { selected: false, label: "Euclid" },
  { selected: false, label: "Newark" },
  { selected: false, label: "Mansfield" },
  { selected: false, label: "Mentor" },
  { selected: false, label: "Beavercreek" },
  { selected: false, label: "Cleveland Heights" },
  { selected: false, label: "Strongsville" },
  { selected: false, label: "Dublin" },
  { selected: false, label: "Fairfield" },
  { selected: false, label: "Findlay" },
  { selected: false, label: "Warren" },
  { selected: false, label: "Lancaster" },
  { selected: false, label: "Lima" },
  { selected: false, label: "Huber Heights" },
  { selected: false, label: "Westerville" },
  { selected: false, label: "Marion" },
  { selected: false, label: "Grove City" },
  { selected: false, label: "Charlotte" },
  { selected: false, label: "Raleigh" },
  { selected: false, label: "Greensboro" },
  { selected: false, label: "Durham" },
  { selected: false, label: "Winston-Salem" },
  { selected: false, label: "Fayetteville" },
  { selected: false, label: "Cary" },
  { selected: false, label: "Wilmington" },
  { selected: false, label: "High Point" },
  { selected: false, label: "Greenville" },
  { selected: false, label: "Asheville" },
  { selected: false, label: "Concord" },
  { selected: false, label: "Gastonia" },
  { selected: false, label: "Jacksonville" },
  { selected: false, label: "Chapel Hill" },
  { selected: false, label: "Rocky Mount" },
  { selected: false, label: "Burlington" },
  { selected: false, label: "Wilson" },
  { selected: false, label: "Huntersville" },
  { selected: false, label: "Kannapolis" },
  { selected: false, label: "Apex" },
  { selected: false, label: "Hickory" },
  { selected: false, label: "Goldsboro" },
  { selected: false, label: "Detroit" },
  { selected: false, label: "Grand Rapids" },
  { selected: false, label: "Warren" },
  { selected: false, label: "Sterling Heights" },
  { selected: false, label: "Ann Arbor" },
  { selected: false, label: "Lansing" },
  { selected: false, label: "Flint" },
  { selected: false, label: "Dearborn" },
  { selected: false, label: "Livonia" },
  { selected: false, label: "Westland" },
  { selected: false, label: "Troy" },
  { selected: false, label: "Farmington Hills" },
  { selected: false, label: "Kalamazoo" },
  { selected: false, label: "Wyoming" },
  { selected: false, label: "Southfield" },
  { selected: false, label: "Rochester Hills" },
  { selected: false, label: "Taylor" },
  { selected: false, label: "Pontiac" },
  { selected: false, label: "St. Clair Shores" },
  { selected: false, label: "Royal Oak" },
  { selected: false, label: "Novi" },
  { selected: false, label: "Dearborn Heights" },
  { selected: false, label: "Battle Creek" },
  { selected: false, label: "Saginaw" },
  { selected: false, label: "Kentwood" },
  { selected: false, label: "East Lansing" },
  { selected: false, label: "Roseville" },
  { selected: false, label: "Portage" },
  { selected: false, label: "Midland" },
  { selected: false, label: "Lincoln Park" },
  { selected: false, label: "Muskegon" },
  { selected: false, label: "Memphis" },
  { selected: false, label: "Nashville-Davidson" },
  { selected: false, label: "Knoxville" },
  { selected: false, label: "Chattanooga" },
  { selected: false, label: "Clarksville" },
  { selected: false, label: "Murfreesboro" },
  { selected: false, label: "Jackson" },
  { selected: false, label: "Franklin" },
  { selected: false, label: "Johnson City" },
  { selected: false, label: "Bartlett" },
  { selected: false, label: "Hendersonville" },
  { selected: false, label: "Kingsport" },
  { selected: false, label: "Collierville" },
  { selected: false, label: "Cleveland" },
  { selected: false, label: "Smyrna" },
  { selected: false, label: "Germantown" },
  { selected: false, label: "Brentwood" },
  { selected: false, label: "Boston" },
  { selected: false, label: "Worcester" },
  { selected: false, label: "Springfield" },
  { selected: false, label: "Lowell" },
  { selected: false, label: "Cambridge" },
  { selected: false, label: "New Bedford" },
  { selected: false, label: "Brockton" },
  { selected: false, label: "Quincy" },
  { selected: false, label: "Lynn" },
  { selected: false, label: "Fall River" },
  { selected: false, label: "Newton" },
  { selected: false, label: "Lawrence" },
  { selected: false, label: "Somerville" },
  { selected: false, label: "Waltham" },
  { selected: false, label: "Haverhill" },
  { selected: false, label: "Malden" },
  { selected: false, label: "Medford" },
  { selected: false, label: "Taunton" },
  { selected: false, label: "Chicopee" },
  { selected: false, label: "Weymouth Town" },
  { selected: false, label: "Revere" },
  { selected: false, label: "Peabody" },
  { selected: false, label: "Methuen" },
  { selected: false, label: "Barnstable Town" },
  { selected: false, label: "Pittsfield" },
  { selected: false, label: "Attleboro" },
  { selected: false, label: "Everett" },
  { selected: false, label: "Salem" },
  { selected: false, label: "Westfield" },
  { selected: false, label: "Leominster" },
  { selected: false, label: "Fitchburg" },
  { selected: false, label: "Beverly" },
  { selected: false, label: "Holyoke" },
  { selected: false, label: "Marlborough" },
  { selected: false, label: "Woburn" },
  { selected: false, label: "Chelsea" },
  { selected: false, label: "Seattle" },
  { selected: false, label: "Spokane" },
  { selected: false, label: "Tacoma" },
  { selected: false, label: "Vancouver" },
  { selected: false, label: "Bellevue" },
  { selected: false, label: "Kent" },
  { selected: false, label: "Everett" },
  { selected: false, label: "Renton" },
  { selected: false, label: "Yakima" },
  { selected: false, label: "Federal Way" },
  { selected: false, label: "Spokane Valley" },
  { selected: false, label: "Bellingham" },
  { selected: false, label: "Kennewick" },
  { selected: false, label: "Auburn" },
  { selected: false, label: "Pasco" },
  { selected: false, label: "Marysville" },
  { selected: false, label: "Lakewood" },
  { selected: false, label: "Redmond" },
  { selected: false, label: "Shoreline" },
  { selected: false, label: "Richland" },
  { selected: false, label: "Kirkland" },
  { selected: false, label: "Burien" },
  { selected: false, label: "Sammamish" },
  { selected: false, label: "Olympia" },
  { selected: false, label: "Lacey" },
  { selected: false, label: "Edmonds" },
  { selected: false, label: "Bremerton" },
  { selected: false, label: "Puyallup" },
  { selected: false, label: "Denver" },
  { selected: false, label: "Colorado Springs" },
  { selected: false, label: "Aurora" },
  { selected: false, label: "Fort Collins" },
  { selected: false, label: "Lakewood" },
  { selected: false, label: "Thornton" },
  { selected: false, label: "Arvada" },
  { selected: false, label: "Westminster" },
  { selected: false, label: "Pueblo" },
  { selected: false, label: "Centennial" },
  { selected: false, label: "Boulder" },
  { selected: false, label: "Greeley" },
  { selected: false, label: "Longmont" },
  { selected: false, label: "Loveland" },
  { selected: false, label: "Grand Junction" },
  { selected: false, label: "Broomfield" },
  { selected: false, label: "Castle Rock" },
  { selected: false, label: "Commerce City" },
  { selected: false, label: "Parker" },
  { selected: false, label: "Littleton" },
  { selected: false, label: "Northglenn" },
  { selected: false, label: "Washington" },
  { selected: false, label: "Baltimore" },
  { selected: false, label: "Frederick" },
  { selected: false, label: "Rockville" },
  { selected: false, label: "Gaithersburg" },
  { selected: false, label: "Bowie" },
  { selected: false, label: "Hagerstown" },
  { selected: false, label: "Annapolis" },
  { selected: false, label: "Louisville/Jefferson County" },
  { selected: false, label: "Lexington-Fayette" },
  { selected: false, label: "Bowling Green" },
  { selected: false, label: "Owensboro" },
  { selected: false, label: "Covington" },
  { selected: false, label: "Portland" },
  { selected: false, label: "Eugene" },
  { selected: false, label: "Salem" },
  { selected: false, label: "Gresham" },
  { selected: false, label: "Hillsboro" },
  { selected: false, label: "Beaverton" },
  { selected: false, label: "Bend" },
  { selected: false, label: "Medford" },
  { selected: false, label: "Springfield" },
  { selected: false, label: "Corvallis" },
  { selected: false, label: "Albany" },
  { selected: false, label: "Tigard" },
  { selected: false, label: "Lake Oswego" },
  { selected: false, label: "Keizer" },
  { selected: false, label: "Oklahoma City" },
  { selected: false, label: "Tulsa" },
  { selected: false, label: "Norman" },
  { selected: false, label: "Broken Arrow" },
  { selected: false, label: "Lawton" },
  { selected: false, label: "Edmond" },
  { selected: false, label: "Moore" },
  { selected: false, label: "Midwest City" },
  { selected: false, label: "Enid" },
  { selected: false, label: "Stillwater" },
  { selected: false, label: "Muskogee" },
  { selected: false, label: "Milwaukee" },
  { selected: false, label: "Madison" },
  { selected: false, label: "Green Bay" },
  { selected: false, label: "Kenosha" },
  { selected: false, label: "Racine" },
  { selected: false, label: "Appleton" },
  { selected: false, label: "Waukesha" },
  { selected: false, label: "Eau Claire" },
  { selected: false, label: "Oshkosh" },
  { selected: false, label: "Janesville" },
  { selected: false, label: "West Allis" },
  { selected: false, label: "La Crosse" },
  { selected: false, label: "Sheboygan" },
  { selected: false, label: "Wauwatosa" },
  { selected: false, label: "Fond du Lac" },
  { selected: false, label: "New Berlin" },
  { selected: false, label: "Wausau" },
  { selected: false, label: "Brookfield" },
  { selected: false, label: "Greenfield" },
  { selected: false, label: "Beloit" },
  { selected: false, label: "Las Vegas" },
  { selected: false, label: "Henderson" },
  { selected: false, label: "Reno" },
  { selected: false, label: "North Las Vegas" },
  { selected: false, label: "Sparks" },
  { selected: false, label: "Carson City" },
  { selected: false, label: "Albuquerque" },
  { selected: false, label: "Las Cruces" },
  { selected: false, label: "Rio Rancho" },
  { selected: false, label: "Santa Fe" },
  { selected: false, label: "Roswell" },
  { selected: false, label: "Farmington" },
  { selected: false, label: "Clovis" },
  { selected: false, label: "Kansas City" },
  { selected: false, label: "St. Louis" },
  { selected: false, label: "Springfield" },
  { selected: false, label: "Independence" },
  { selected: false, label: "Columbia" },
  { selected: false, label: "Lee's Summit" },
  { selected: false, label: "O'Fallon" },
  { selected: false, label: "St. Joseph" },
  { selected: false, label: "St. Charles" },
  { selected: false, label: "St. Peters" },
  { selected: false, label: "Blue Springs" },
  { selected: false, label: "Florissant" },
  { selected: false, label: "Joplin" },
  { selected: false, label: "Chesterfield" },
  { selected: false, label: "Jefferson City" },
  { selected: false, label: "Cape Girardeau" },
  { selected: false, label: "Virginia Beach" },
  { selected: false, label: "Norfolk" },
  { selected: false, label: "Chesapeake" },
  { selected: false, label: "Richmond" },
  { selected: false, label: "Newport News" },
  { selected: false, label: "Alexandria" },
  { selected: false, label: "Hampton" },
  { selected: false, label: "Roanoke" },
  { selected: false, label: "Portsmouth" },
  { selected: false, label: "Suffolk" },
  { selected: false, label: "Lynchburg" },
  { selected: false, label: "Harrisonburg" },
  { selected: false, label: "Leesburg" },
  { selected: false, label: "Charlottesville" },
  { selected: false, label: "Danville" },
  { selected: false, label: "Blacksburg" },
  { selected: false, label: "Manassas" },
  { selected: false, label: "Atlanta" },
  { selected: false, label: "Columbus" },
  { selected: false, label: "Augusta-Richmond County" },
  { selected: false, label: "Savannah" },
  { selected: false, label: "Athens-Clarke County" },
  { selected: false, label: "Sandy Springs" },
  { selected: false, label: "Roswell" },
  { selected: false, label: "Macon" },
  { selected: false, label: "Johns Creek" },
  { selected: false, label: "Albany" },
  { selected: false, label: "Warner Robins" },
  { selected: false, label: "Alpharetta" },
  { selected: false, label: "Marietta" },
  { selected: false, label: "Valdosta" },
  { selected: false, label: "Smyrna" },
  { selected: false, label: "Dunwoody" },
  { selected: false, label: "Omaha" },
  { selected: false, label: "Lincoln" },
  { selected: false, label: "Bellevue" },
  { selected: false, label: "Grand Island" },
  { selected: false, label: "Minneapolis" },
  { selected: false, label: "St. Paul" },
  { selected: false, label: "Rochester" },
  { selected: false, label: "Duluth" },
  { selected: false, label: "Bloomington" },
  { selected: false, label: "Brooklyn Park" },
  { selected: false, label: "Plymouth" },
  { selected: false, label: "St. Cloud" },
  { selected: false, label: "Eagan" },
  { selected: false, label: "Woodbury" },
  { selected: false, label: "Maple Grove" },
  { selected: false, label: "Eden Prairie" },
  { selected: false, label: "Coon Rapids" },
  { selected: false, label: "Burnsville" },
  { selected: false, label: "Blaine" },
  { selected: false, label: "Lakeville" },
  { selected: false, label: "Minnetonka" },
  { selected: false, label: "Apple Valley" },
  { selected: false, label: "Edina" },
  { selected: false, label: "St. Louis Park" },
  { selected: false, label: "Mankato" },
  { selected: false, label: "Maplewood" },
  { selected: false, label: "Moorhead" },
  { selected: false, label: "Shakopee" },
  { selected: false, label: "Wichita" },
  { selected: false, label: "Overland Park" },
  { selected: false, label: "Kansas City" },
  { selected: false, label: "Olathe" },
  { selected: false, label: "Topeka" },
  { selected: false, label: "Lawrence" },
  { selected: false, label: "Shawnee" },
  { selected: false, label: "Manhattan" },
  { selected: false, label: "Lenexa" },
  { selected: false, label: "Salina" },
  { selected: false, label: "Hutchinson" },
  { selected: false, label: "New Orleans" },
  { selected: false, label: "Baton Rouge" },
  { selected: false, label: "Shreveport" },
  { selected: false, label: "Lafayette" },
  { selected: false, label: "Lake Charles" },
  { selected: false, label: "Kenner" },
  { selected: false, label: "Bossier City" },
  { selected: false, label: "Monroe" },
  { selected: false, label: "Alexandria" },
  { selected: false, label: "Honolulu" },
  { selected: false, label: "Anchorage" },
  { selected: false, label: "Newark" },
  { selected: false, label: "Jersey City" },
  { selected: false, label: "Paterson" },
  { selected: false, label: "Elizabeth" },
  { selected: false, label: "Clifton" },
  { selected: false, label: "Trenton" },
  { selected: false, label: "Camden" },
  { selected: false, label: "Passaic" },
  { selected: false, label: "Union City" },
  { selected: false, label: "Bayonne" },
  { selected: false, label: "East Orange" },
  { selected: false, label: "Vineland" },
  { selected: false, label: "New Brunswick" },
  { selected: false, label: "Hoboken" },
  { selected: false, label: "Perth Amboy" },
  { selected: false, label: "West New York" },
  { selected: false, label: "Plainfield" },
  { selected: false, label: "Hackensack" },
  { selected: false, label: "Sayreville" },
  { selected: false, label: "Kearny" },
  { selected: false, label: "Linden" },
  { selected: false, label: "Atlantic City" },
  { selected: false, label: "Boise City" },
  { selected: false, label: "Nampa" },
  { selected: false, label: "Meridian" },
  { selected: false, label: "Idaho Falls" },
  { selected: false, label: "Pocatello" },
  { selected: false, label: "Caldwell" },
  { selected: false, label: "Coeur d'Alene" },
  { selected: false, label: "Twin Falls" },
  { selected: false, label: "Birmingham" },
  { selected: false, label: "Montgomery" },
  { selected: false, label: "Mobile" },
  { selected: false, label: "Huntsville" },
  { selected: false, label: "Tuscaloosa" },
  { selected: false, label: "Hoover" },
  { selected: false, label: "Dothan" },
  { selected: false, label: "Auburn" },
  { selected: false, label: "Decatur" },
  { selected: false, label: "Madison" },
  { selected: false, label: "Florence" },
  { selected: false, label: "Gadsden" },
  { selected: false, label: "Des Moines" },
  { selected: false, label: "Cedar Rapids" },
  { selected: false, label: "Davenport" },
  { selected: false, label: "Sioux City" },
  { selected: false, label: "Iowa City" },
  { selected: false, label: "Waterloo" },
  { selected: false, label: "Council Bluffs" },
  { selected: false, label: "Ames" },
  { selected: false, label: "West Des Moines" },
  { selected: false, label: "Dubuque" },
  { selected: false, label: "Ankeny" },
  { selected: false, label: "Urbandale" },
  { selected: false, label: "Cedar Falls" },
  { selected: false, label: "Little Rock" },
  { selected: false, label: "Fort Smith" },
  { selected: false, label: "Fayetteville" },
  { selected: false, label: "Springdale" },
  { selected: false, label: "Jonesboro" },
  { selected: false, label: "North Little Rock" },
  { selected: false, label: "Conway" },
  { selected: false, label: "Rogers" },
  { selected: false, label: "Pine Bluff" },
  { selected: false, label: "Bentonville" },
  { selected: false, label: "Salt Lake City" },
  { selected: false, label: "West Valley City" },
  { selected: false, label: "Provo" },
  { selected: false, label: "West Jordan" },
  { selected: false, label: "Orem" },
  { selected: false, label: "Sandy" },
  { selected: false, label: "Ogden" },
  { selected: false, label: "St. George" },
  { selected: false, label: "Layton" },
  { selected: false, label: "Taylorsville" },
  { selected: false, label: "South Jordan" },
  { selected: false, label: "Lehi" },
  { selected: false, label: "Logan" },
  { selected: false, label: "Murray" },
  { selected: false, label: "Draper" },
  { selected: false, label: "Bountiful" },
  { selected: false, label: "Riverton" },
  { selected: false, label: "Roy" },
  { selected: false, label: "Providence" },
  { selected: false, label: "Warwick" },
  { selected: false, label: "Cranston" },
  { selected: false, label: "Pawtucket" },
  { selected: false, label: "East Providence" },
  { selected: false, label: "Woonsocket" },
  { selected: false, label: "Jackson" },
  { selected: false, label: "Gulfport" },
  { selected: false, label: "Southaven" },
  { selected: false, label: "Hattiesburg" },
  { selected: false, label: "Biloxi" },
  { selected: false, label: "Meridian" },
  { selected: false, label: "Sioux Falls" },
  { selected: false, label: "Rapid City" },
  { selected: false, label: "Bridgeport" },
  { selected: false, label: "New Haven" },
  { selected: false, label: "Stamford" },
  { selected: false, label: "Hartford" },
  { selected: false, label: "Waterbury" },
  { selected: false, label: "Norwalk" },
  { selected: false, label: "Danbury" },
  { selected: false, label: "New Britain" },
  { selected: false, label: "Meriden" },
  { selected: false, label: "Bristol" },
  { selected: false, label: "West Haven" },
  { selected: false, label: "Milford" },
  { selected: false, label: "Middletown" },
  { selected: false, label: "Norwich" },
  { selected: false, label: "Shelton" },
  { selected: false, label: "Columbia" },
  { selected: false, label: "Charleston" },
  { selected: false, label: "North Charleston" },
  { selected: false, label: "Mount Pleasant" },
  { selected: false, label: "Rock Hill" },
  { selected: false, label: "Greenville" },
  { selected: false, label: "Summerville" },
  { selected: false, label: "Sumter" },
  { selected: false, label: "Goose Creek" },
  { selected: false, label: "Hilton Head Island" },
  { selected: false, label: "Florence" },
  { selected: false, label: "Spartanburg" },
  { selected: false, label: "Manchester" },
  { selected: false, label: "Nashua" },
  { selected: false, label: "Concord" },
  { selected: false, label: "Fargo" },
  { selected: false, label: "Bismarck" },
  { selected: false, label: "Grand Forks" },
  { selected: false, label: "Minot" },
  { selected: false, label: "Billings" },
  { selected: false, label: "Missoula" },
  { selected: false, label: "Great Falls" },
  { selected: false, label: "Bozeman" },
  { selected: false, label: "Wilmington" },
  { selected: false, label: "Dover" },
  { selected: false, label: "Portland" },
  { selected: false, label: "Cheyenne" },
  { selected: false, label: "Casper" },
  { selected: false, label: "Charleston" },
  { selected: false, label: "Huntington" },
  { selected: false, label: "Burlington" },
];

export const ho = {
  "New York": [
    "New York",
    "Buffalo",
    "Rochester",
    "Yonkers",
    "Syracuse",
    "Albany",
    "New Rochelle",
    "Mount Vernon",
    "Schenectady",
    "Utica",
    "White Plains",
    "Hempstead",
    "Troy",
    "Niagara Falls",
    "Binghamton",
    "Freeport",
    "Valley Stream",
  ],
  California: [
    "Los Angeles",
    "San Diego",
    "San Jose",
    "San Francisco",
    "Fresno",
    "Sacramento",
    "Long Beach",
    "Oakland",
    "Bakersfield",
    "Anaheim",
    "Santa Ana",
    "Riverside",
    "Stockton",
    "Chula Vista",
    "Irvine",
    "Fremont",
    "San Bernardino",
    "Modesto",
    "Fontana",
    "Oxnard",
    "Moreno Valley",
    "Huntington Beach",
    "Glendale",
    "Santa Clarita",
    "Garden Grove",
    "Oceanside",
    "Rancho Cucamonga",
    "Santa Rosa",
    "Ontario",
    "Lancaster",
    "Elk Grove",
    "Corona",
    "Palmdale",
    "Salinas",
    "Pomona",
    "Hayward",
    "Escondido",
    "Torrance",
    "Sunnyvale",
    "Orange",
    "Fullerton",
    "Pasadena",
    "Thousand Oaks",
    "Visalia",
    "Simi Valley",
    "Concord",
    "Roseville",
    "Victorville",
    "Santa Clara",
    "Vallejo",
    "Berkeley",
    "El Monte",
    "Downey",
    "Costa Mesa",
    "Inglewood",
    "Carlsbad",
    "San Buenaventura (Ventura)",
    "Fairfield",
    "West Covina",
    "Murrieta",
    "Richmond",
    "Norwalk",
    "Antioch",
    "Temecula",
    "Burbank",
    "Daly City",
    "Rialto",
    "Santa Maria",
    "El Cajon",
    "San Mateo",
    "Clovis",
    "Compton",
    "Jurupa Valley",
    "Vista",
    "South Gate",
    "Mission Viejo",
    "Vacaville",
    "Carson",
    "Hesperia",
    "Santa Monica",
    "Westminster",
    "Redding",
    "Santa Barbara",
    "Chico",
    "Newport Beach",
    "San Leandro",
    "San Marcos",
    "Whittier",
    "Hawthorne",
    "Citrus Heights",
    "Tracy",
    "Alhambra",
    "Livermore",
    "Buena Park",
    "Menifee",
    "Hemet",
    "Lakewood",
    "Merced",
    "Chino",
    "Indio",
    "Redwood City",
    "Lake Forest",
    "Napa",
    "Tustin",
    "Bellflower",
    "Mountain View",
    "Chino Hills",
    "Baldwin Park",
    "Alameda",
    "Upland",
    "San Ramon",
    "Folsom",
    "Pleasanton",
    "Union City",
    "Perris",
    "Manteca",
    "Lynwood",
    "Apple Valley",
    "Redlands",
    "Turlock",
    "Milpitas",
    "Redondo Beach",
    "Rancho Cordova",
    "Yorba Linda",
    "Palo Alto",
    "Davis",
    "Camarillo",
    "Walnut Creek",
    "Pittsburg",
    "South San Francisco",
    "Yuba City",
    "San Clemente",
    "Laguna Niguel",
    "Pico Rivera",
    "Montebello",
    "Lodi",
    "Madera",
    "Santa Cruz",
    "La Habra",
    "Encinitas",
    "Monterey Park",
    "Tulare",
    "Cupertino",
    "Gardena",
    "National City",
    "Rocklin",
    "Petaluma",
    "Huntington Park",
    "San Rafael",
    "La Mesa",
    "Arcadia",
    "Fountain Valley",
    "Diamond Bar",
    "Woodland",
    "Santee",
    "Lake Elsinore",
    "Porterville",
    "Paramount",
    "Eastvale",
    "Rosemead",
    "Hanford",
    "Highland",
    "Brentwood",
    "Novato",
    "Colton",
    "Cathedral City",
    "Delano",
    "Yucaipa",
    "Watsonville",
    "Placentia",
    "Glendora",
    "Gilroy",
    "Palm Desert",
    "Cerritos",
    "West Sacramento",
    "Aliso Viejo",
    "Poway",
    "La Mirada",
    "Rancho Santa Margarita",
    "Cypress",
    "Dublin",
    "Covina",
    "Azusa",
    "Palm Springs",
    "San Luis Obispo",
    "Ceres",
    "San Jacinto",
    "Lincoln",
    "Newark",
    "Lompoc",
    "El Centro",
    "Danville",
    "Bell Gardens",
    "Coachella",
    "Rancho Palos Verdes",
    "San Bruno",
    "Rohnert Park",
    "Brea",
    "La Puente",
    "Campbell",
    "San Gabriel",
    "Beaumont",
    "Morgan Hill",
    "Culver City",
    "Calexico",
    "Stanton",
    "La Quinta",
    "Pacifica",
    "Montclair",
    "Oakley",
    "Monrovia",
    "Los Banos",
    "Martinez",
  ],
  Illinois: [
    "Chicago",
    "Aurora",
    "Rockford",
    "Joliet",
    "Naperville",
    "Springfield",
    "Peoria",
    "Elgin",
    "Waukegan",
    "Cicero",
    "Champaign",
    "Bloomington",
    "Arlington Heights",
    "Evanston",
    "Decatur",
    "Schaumburg",
    "Bolingbrook",
    "Palatine",
    "Skokie",
    "Des Plaines",
    "Orland Park",
    "Tinley Park",
    "Oak Lawn",
    "Berwyn",
    "Mount Prospect",
    "Normal",
    "Wheaton",
    "Hoffman Estates",
    "Oak Park",
    "Downers Grove",
    "Elmhurst",
    "Glenview",
    "DeKalb",
    "Lombard",
    "Belleville",
    "Moline",
    "Buffalo Grove",
    "Bartlett",
    "Urbana",
    "Quincy",
    "Crystal Lake",
    "Plainfield",
    "Streamwood",
    "Carol Stream",
    "Romeoville",
    "Rock Island",
    "Hanover Park",
    "Carpentersville",
    "Wheeling",
    "Park Ridge",
    "Addison",
    "Calumet City",
  ],
  Texas: [
    "Houston",
    "San Antonio",
    "Dallas",
    "Austin",
    "Fort Worth",
    "El Paso",
    "Arlington",
    "Corpus Christi",
    "Plano",
    "Laredo",
    "Lubbock",
    "Garland",
    "Irving",
    "Amarillo",
    "Grand Prairie",
    "Brownsville",
    "Pasadena",
    "McKinney",
    "Mesquite",
    "McAllen",
    "Killeen",
    "Frisco",
    "Waco",
    "Carrollton",
    "Denton",
    "Midland",
    "Abilene",
    "Beaumont",
    "Round Rock",
    "Odessa",
    "Wichita Falls",
    "Richardson",
    "Lewisville",
    "Tyler",
    "College Station",
    "Pearland",
    "San Angelo",
    "Allen",
    "League City",
    "Sugar Land",
    "Longview",
    "Edinburg",
    "Mission",
    "Bryan",
    "Baytown",
    "Pharr",
    "Temple",
    "Missouri City",
    "Flower Mound",
    "Harlingen",
    "North Richland Hills",
    "Victoria",
    "Conroe",
    "New Braunfels",
    "Mansfield",
    "Cedar Park",
    "Rowlett",
    "Port Arthur",
    "Euless",
    "Georgetown",
    "Pflugerville",
    "DeSoto",
    "San Marcos",
    "Grapevine",
    "Bedford",
    "Galveston",
    "Cedar Hill",
    "Texas City",
    "Wylie",
    "Haltom City",
    "Keller",
    "Coppell",
    "Rockwall",
    "Huntsville",
    "Duncanville",
    "Sherman",
    "The Colony",
    "Burleson",
    "Hurst",
    "Lancaster",
    "Texarkana",
    "Friendswood",
    "Weslaco",
  ],
  Pennsylvania: [
    "Philadelphia",
    "Pittsburgh",
    "Allentown",
    "Erie",
    "Reading",
    "Scranton",
    "Bethlehem",
    "Lancaster",
    "Harrisburg",
    "Altoona",
    "York",
    "State College",
    "Wilkes-Barre",
  ],
  Arizona: [
    "Phoenix",
    "Tucson",
    "Mesa",
    "Chandler",
    "Glendale",
    "Scottsdale",
    "Gilbert",
    "Tempe",
    "Peoria",
    "Surprise",
    "Yuma",
    "Avondale",
    "Goodyear",
    "Flagstaff",
    "Buckeye",
    "Lake Havasu City",
    "Casa Grande",
    "Sierra Vista",
    "Maricopa",
    "Oro Valley",
    "Prescott",
    "Bullhead City",
    "Prescott Valley",
    "Marana",
    "Apache Junction",
  ],
  Florida: [
    "Jacksonville",
    "Miami",
    "Tampa",
    "Orlando",
    "St. Petersburg",
    "Hialeah",
    "Tallahassee",
    "Fort Lauderdale",
    "Port St. Lucie",
    "Cape Coral",
    "Pembroke Pines",
    "Hollywood",
    "Miramar",
    "Gainesville",
    "Coral Springs",
    "Miami Gardens",
    "Clearwater",
    "Palm Bay",
    "Pompano Beach",
    "West Palm Beach",
    "Lakeland",
    "Davie",
    "Miami Beach",
    "Sunrise",
    "Plantation",
    "Boca Raton",
    "Deltona",
    "Largo",
    "Deerfield Beach",
    "Palm Coast",
    "Melbourne",
    "Boynton Beach",
    "Lauderhill",
    "Weston",
    "Fort Myers",
    "Kissimmee",
    "Homestead",
    "Tamarac",
    "Delray Beach",
    "Daytona Beach",
    "North Miami",
    "Wellington",
    "North Port",
    "Jupiter",
    "Ocala",
    "Port Orange",
    "Margate",
    "Coconut Creek",
    "Sanford",
    "Sarasota",
    "Pensacola",
    "Bradenton",
    "Palm Beach Gardens",
    "Pinellas Park",
    "Coral Gables",
    "Doral",
    "Bonita Springs",
    "Apopka",
    "Titusville",
    "North Miami Beach",
    "Oakland Park",
    "Fort Pierce",
    "North Lauderdale",
    "Cutler Bay",
    "Altamonte Springs",
    "St. Cloud",
    "Greenacres",
    "Ormond Beach",
    "Ocoee",
    "Hallandale Beach",
    "Winter Garden",
    "Aventura",
  ],
  Indiana: [
    "Indianapolis",
    "Fort Wayne",
    "Evansville",
    "South Bend",
    "Carmel",
    "Bloomington",
    "Fishers",
    "Hammond",
    "Gary",
    "Muncie",
    "Lafayette",
    "Terre Haute",
    "Kokomo",
    "Anderson",
    "Noblesville",
    "Greenwood",
    "Elkhart",
    "Mishawaka",
    "Lawrence",
    "Jeffersonville",
    "Columbus",
    "Portage",
  ],
  Ohio: [
    "Columbus",
    "Cleveland",
    "Cincinnati",
    "Toledo",
    "Akron",
    "Dayton",
    "Parma",
    "Canton",
    "Youngstown",
    "Lorain",
    "Hamilton",
    "Springfield",
    "Kettering",
    "Elyria",
    "Lakewood",
    "Cuyahoga Falls",
    "Middletown",
    "Euclid",
    "Newark",
    "Mansfield",
    "Mentor",
    "Beavercreek",
    "Cleveland Heights",
    "Strongsville",
    "Dublin",
    "Fairfield",
    "Findlay",
    "Warren",
    "Lancaster",
    "Lima",
    "Huber Heights",
    "Westerville",
    "Marion",
    "Grove City",
  ],
  "North Carolina": [
    "Charlotte",
    "Raleigh",
    "Greensboro",
    "Durham",
    "Winston-Salem",
    "Fayetteville",
    "Cary",
    "Wilmington",
    "High Point",
    "Greenville",
    "Asheville",
    "Concord",
    "Gastonia",
    "Jacksonville",
    "Chapel Hill",
    "Rocky Mount",
    "Burlington",
    "Wilson",
    "Huntersville",
    "Kannapolis",
    "Apex",
    "Hickory",
    "Goldsboro",
  ],
  Michigan: [
    "Detroit",
    "Grand Rapids",
    "Warren",
    "Sterling Heights",
    "Ann Arbor",
    "Lansing",
    "Flint",
    "Dearborn",
    "Livonia",
    "Westland",
    "Troy",
    "Farmington Hills",
    "Kalamazoo",
    "Wyoming",
    "Southfield",
    "Rochester Hills",
    "Taylor",
    "Pontiac",
    "St. Clair Shores",
    "Royal Oak",
    "Novi",
    "Dearborn Heights",
    "Battle Creek",
    "Saginaw",
    "Kentwood",
    "East Lansing",
    "Roseville",
    "Portage",
    "Midland",
    "Lincoln Park",
    "Muskegon",
  ],
  Tennessee: [
    "Memphis",
    "Nashville-Davidson",
    "Knoxville",
    "Chattanooga",
    "Clarksville",
    "Murfreesboro",
    "Jackson",
    "Franklin",
    "Johnson City",
    "Bartlett",
    "Hendersonville",
    "Kingsport",
    "Collierville",
    "Cleveland",
    "Smyrna",
    "Germantown",
    "Brentwood",
  ],
  Massachusetts: [
    "Boston",
    "Worcester",
    "Springfield",
    "Lowell",
    "Cambridge",
    "New Bedford",
    "Brockton",
    "Quincy",
    "Lynn",
    "Fall River",
    "Newton",
    "Lawrence",
    "Somerville",
    "Waltham",
    "Haverhill",
    "Malden",
    "Medford",
    "Taunton",
    "Chicopee",
    "Weymouth Town",
    "Revere",
    "Peabody",
    "Methuen",
    "Barnstable Town",
    "Pittsfield",
    "Attleboro",
    "Everett",
    "Salem",
    "Westfield",
    "Leominster",
    "Fitchburg",
    "Beverly",
    "Holyoke",
    "Marlborough",
    "Woburn",
    "Chelsea",
  ],
  Washington: [
    "Seattle",
    "Spokane",
    "Tacoma",
    "Vancouver",
    "Bellevue",
    "Kent",
    "Everett",
    "Renton",
    "Yakima",
    "Federal Way",
    "Spokane Valley",
    "Bellingham",
    "Kennewick",
    "Auburn",
    "Pasco",
    "Marysville",
    "Lakewood",
    "Redmond",
    "Shoreline",
    "Richland",
    "Kirkland",
    "Burien",
    "Sammamish",
    "Olympia",
    "Lacey",
    "Edmonds",
    "Bremerton",
    "Puyallup",
  ],
  Colorado: [
    "Denver",
    "Colorado Springs",
    "Aurora",
    "Fort Collins",
    "Lakewood",
    "Thornton",
    "Arvada",
    "Westminster",
    "Pueblo",
    "Centennial",
    "Boulder",
    "Greeley",
    "Longmont",
    "Loveland",
    "Grand Junction",
    "Broomfield",
    "Castle Rock",
    "Commerce City",
    "Parker",
    "Littleton",
    "Northglenn",
  ],
  "District of Columbia": ["Washington"],
  Maryland: [
    "Baltimore",
    "Frederick",
    "Rockville",
    "Gaithersburg",
    "Bowie",
    "Hagerstown",
    "Annapolis",
  ],
  Kentucky: [
    "Louisville/Jefferson County",
    "Lexington-Fayette",
    "Bowling Green",
    "Owensboro",
    "Covington",
  ],
  Oregon: [
    "Portland",
    "Eugene",
    "Salem",
    "Gresham",
    "Hillsboro",
    "Beaverton",
    "Bend",
    "Medford",
    "Springfield",
    "Corvallis",
    "Albany",
    "Tigard",
    "Lake Oswego",
    "Keizer",
  ],
  Oklahoma: [
    "Oklahoma City",
    "Tulsa",
    "Norman",
    "Broken Arrow",
    "Lawton",
    "Edmond",
    "Moore",
    "Midwest City",
    "Enid",
    "Stillwater",
    "Muskogee",
  ],
  Wisconsin: [
    "Milwaukee",
    "Madison",
    "Green Bay",
    "Kenosha",
    "Racine",
    "Appleton",
    "Waukesha",
    "Eau Claire",
    "Oshkosh",
    "Janesville",
    "West Allis",
    "La Crosse",
    "Sheboygan",
    "Wauwatosa",
    "Fond du Lac",
    "New Berlin",
    "Wausau",
    "Brookfield",
    "Greenfield",
    "Beloit",
  ],
  Nevada: [
    "Las Vegas",
    "Henderson",
    "Reno",
    "North Las Vegas",
    "Sparks",
    "Carson City",
  ],
  "New Mexico": [
    "Albuquerque",
    "Las Cruces",
    "Rio Rancho",
    "Santa Fe",
    "Roswell",
    "Farmington",
    "Clovis",
  ],
  Missouri: [
    "Kansas City",
    "St. Louis",
    "Springfield",
    "Independence",
    "Columbia",
    "Lee's Summit",
    "O'Fallon",
    "St. Joseph",
    "St. Charles",
    "St. Peters",
    "Blue Springs",
    "Florissant",
    "Joplin",
    "Chesterfield",
    "Jefferson City",
    "Cape Girardeau",
  ],
  Virginia: [
    "Virginia Beach",
    "Norfolk",
    "Chesapeake",
    "Richmond",
    "Newport News",
    "Alexandria",
    "Hampton",
    "Roanoke",
    "Portsmouth",
    "Suffolk",
    "Lynchburg",
    "Harrisonburg",
    "Leesburg",
    "Charlottesville",
    "Danville",
    "Blacksburg",
    "Manassas",
  ],
  Georgia: [
    "Atlanta",
    "Columbus",
    "Augusta-Richmond County",
    "Savannah",
    "Athens-Clarke County",
    "Sandy Springs",
    "Roswell",
    "Macon",
    "Johns Creek",
    "Albany",
    "Warner Robins",
    "Alpharetta",
    "Marietta",
    "Valdosta",
    "Smyrna",
    "Dunwoody",
  ],
  Nebraska: ["Omaha", "Lincoln", "Bellevue", "Grand Island"],
  Minnesota: [
    "Minneapolis",
    "St. Paul",
    "Rochester",
    "Duluth",
    "Bloomington",
    "Brooklyn Park",
    "Plymouth",
    "St. Cloud",
    "Eagan",
    "Woodbury",
    "Maple Grove",
    "Eden Prairie",
    "Coon Rapids",
    "Burnsville",
    "Blaine",
    "Lakeville",
    "Minnetonka",
    "Apple Valley",
    "Edina",
    "St. Louis Park",
    "Mankato",
    "Maplewood",
    "Moorhead",
    "Shakopee",
  ],
  Kansas: [
    "Wichita",
    "Overland Park",
    "Kansas City",
    "Olathe",
    "Topeka",
    "Lawrence",
    "Shawnee",
    "Manhattan",
    "Lenexa",
    "Salina",
    "Hutchinson",
  ],
  Louisiana: [
    "New Orleans",
    "Baton Rouge",
    "Shreveport",
    "Lafayette",
    "Lake Charles",
    "Kenner",
    "Bossier City",
    "Monroe",
    "Alexandria",
  ],
  Hawaii: ["Honolulu"],
  Alaska: ["Anchorage"],
  "New Jersey": [
    "Newark",
    "Jersey City",
    "Paterson",
    "Elizabeth",
    "Clifton",
    "Trenton",
    "Camden",
    "Passaic",
    "Union City",
    "Bayonne",
    "East Orange",
    "Vineland",
    "New Brunswick",
    "Hoboken",
    "Perth Amboy",
    "West New York",
    "Plainfield",
    "Hackensack",
    "Sayreville",
    "Kearny",
    "Linden",
    "Atlantic City",
  ],
  Idaho: [
    "Boise City",
    "Nampa",
    "Meridian",
    "Idaho Falls",
    "Pocatello",
    "Caldwell",
    "Coeur d'Alene",
    "Twin Falls",
  ],
  Alabama: [
    "Birmingham",
    "Montgomery",
    "Mobile",
    "Huntsville",
    "Tuscaloosa",
    "Hoover",
    "Dothan",
    "Auburn",
    "Decatur",
    "Madison",
    "Florence",
    "Gadsden",
  ],
  Iowa: [
    "Des Moines",
    "Cedar Rapids",
    "Davenport",
    "Sioux City",
    "Iowa City",
    "Waterloo",
    "Council Bluffs",
    "Ames",
    "West Des Moines",
    "Dubuque",
    "Ankeny",
    "Urbandale",
    "Cedar Falls",
  ],
  Arkansas: [
    "Little Rock",
    "Fort Smith",
    "Fayetteville",
    "Springdale",
    "Jonesboro",
    "North Little Rock",
    "Conway",
    "Rogers",
    "Pine Bluff",
    "Bentonville",
  ],
  Utah: [
    "Salt Lake City",
    "West Valley City",
    "Provo",
    "West Jordan",
    "Orem",
    "Sandy",
    "Ogden",
    "St. George",
    "Layton",
    "Taylorsville",
    "South Jordan",
    "Lehi",
    "Logan",
    "Murray",
    "Draper",
    "Bountiful",
    "Riverton",
    "Roy",
  ],
  "Rhode Island": [
    "Providence",
    "Warwick",
    "Cranston",
    "Pawtucket",
    "East Providence",
    "Woonsocket",
  ],
  Mississippi: [
    "Jackson",
    "Gulfport",
    "Southaven",
    "Hattiesburg",
    "Biloxi",
    "Meridian",
  ],
  "South Dakota": ["Sioux Falls", "Rapid City"],
  Connecticut: [
    "Bridgeport",
    "New Haven",
    "Stamford",
    "Hartford",
    "Waterbury",
    "Norwalk",
    "Danbury",
    "New Britain",
    "Meriden",
    "Bristol",
    "West Haven",
    "Milford",
    "Middletown",
    "Norwich",
    "Shelton",
  ],
  "South Carolina": [
    "Columbia",
    "Charleston",
    "North Charleston",
    "Mount Pleasant",
    "Rock Hill",
    "Greenville",
    "Summerville",
    "Sumter",
    "Goose Creek",
    "Hilton Head Island",
    "Florence",
    "Spartanburg",
  ],
  "New Hampshire": ["Manchester", "Nashua", "Concord"],
  "North Dakota": ["Fargo", "Bismarck", "Grand Forks", "Minot"],
  Montana: ["Billings", "Missoula", "Great Falls", "Bozeman"],
  Delaware: ["Wilmington", "Dover"],
  Maine: ["Portland"],
  Wyoming: ["Cheyenne", "Casper"],
  "West Virginia": ["Charleston", "Huntington"],
  Vermont: ["Burlington"],
};
