import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaCheck, FaEye, FaUpload } from "react-icons/fa";
import "./Index.css";
import { useGlobalContext } from "../../context";
import ShareBox from "../../reusableComponents/shareBox/ShareBox";
import Modal from "../../reusableComponents/modal/Modal";
import { Navbar2 } from "../../navigation/Navbar";
import Questionaire from "../../reusableComponents/questionaire/Questionaire";
import { PageDescription } from "../../reusableComponents/pageDescription";
import Inputs from "./inputs";
import Textareas from "./textarea";
// import SuccessModal from "../../reusableComponents/successModal";
import UploadImg from "./uploadImg";

export const CreateJob = () => {
  const { setPreviewJob, previewJob } = useGlobalContext();
  const [form, setForm] = useState(previewJob.image ? previewJob : {});
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [incorrectEmail, setIncorrectEmail] = useState(false);
  const [error, setError] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [questionaire, setQuestionaire] = useState({
    state: false,
    questions: [],
    score: "",
  });

  const isEmailValid = ["@", "."].every((char) => form.email?.includes(char));

  const preview = () => {
    if (Object.values(form).length < 15) {
      setError(true);
      window.scrollTo(0, 0);
    } else if (form.email && !isEmailValid) {
      setIncorrectEmail(true);
    } else {
      setError(false);
      const date = new Date();
      setPreviewJob({
        ...form,
        country: "USA",
        questionnaire_required: true,
        questionnaire: questionaire.questions,
        passing_score: questionaire.score,
        creation_date: `${date.getFullYear()}-${
          date.getUTCMonth() + 1
        }-${date.getDate()}`,
      });
      navigate("/preview-job");
    }
  };

  const props = {
    success,
    questionaire,
    setQuestionaire,
    error,
    form,
    setForm,
  };

  return (
    <article
      style={{ zIndex: "0", background: "#fff" }}
      className={`job_searc b`}
    >
      <PageDescription title={"Post a Job "} content={""} />
      <Navbar2 />
      <div
        style={{ color: "rgb(0, 60, 0)" }}
        className="container-fluid px-md-5"
      >
        <div className="">
          <div
            className="d-none d-md-block fixed h-screen left-0"
            style={{
              zIndex: "3",
              top: "61.99px",
              width: "17%",
            }}
          >
            <img
              src="./static/media/figma 4.png"
              alt=""
              className="w-full h-full"
              style={{ objectFit: "cover", background: "#cfeffe" }}
            />
          </div>
          <div className="job-margin mb-5 mt-4">
            <div className="container">
              <div className="d-flex justify-content-between align-items-stretch">
                <UploadImg {...props} />

                <div className="my-3 my-md-0 d-none d-lg-block col-12 col-sm-7">
                  <div className="relative w-full h-full z-[0] cursor-default">
                    <div className="fine-border-1"></div>
                    <div className="fine-border-2"></div>
                    <div
                      style={{
                        background: "#f2f9ff",
                        paddingRight: "8px",
                        paddingLeft: "24px",
                      }}
                      className="rounded pb-4 pt-2 h-100 "
                    >
                      <div
                        style={{
                          fontFamily: "inter",
                          fontSize: "1.5rem",
                          fontWeight: "700",
                        }}
                        className="mb-3"
                      >
                        Tips
                      </div>
                      <ul>
                        <li
                          style={{
                            fontSize:
                              "calc(19.3*(clamp(800px,100vw,1366px)/1366))",
                            fontWeight: "400",
                          }}
                          className="mt-4"
                        >
                          <span className="text-capitalize">u</span>se a catchy
                          job title to attract potential candidates.
                        </li>
                        <li
                          style={{
                            fontSize:
                              "calc(19.3*(clamp(800px,100vw,1366px)/1366))",
                            fontWeight: "400",
                          }}
                          className="mt-4"
                        >
                          <span className="text-capitalize">H</span>ighlight the
                          benefits of the job and any growth opportunities.
                        </li>
                        <li
                          style={{
                            fontSize:
                              "calc(19.3*(clamp(800px,100vw,1366px)/1366))",
                            fontWeight: "400",
                          }}
                          className="mt-4"
                        >
                          <span className="text-capitalize">B</span>e specific
                          about the required sales experience and skills.
                        </li>
                        <li
                          style={{
                            fontSize:
                              "calc(19.3*(clamp(800px,100vw,1366px)/1366))",
                            fontWeight: "400",
                          }}
                          className="mt-4"
                        >
                          <span className="text-capitalize">I</span>nclude
                          information on compensation and benefits.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-center my-4">
                <div className="black font-bold font-inter text-lg w-96">
                  General information
                </div>
              </div>
              <section>
                <div className="row">
                  <Inputs {...props} />
                </div>
              </section>

              <Textareas {...props} />
              <div className="my-3">
                <div className="black mb-1 font-semibold text-base font-inter text-capitalize">
                  Email to recieve candidates resume*
                </div>
                <div className="d-flex flex-sm-row flex-sm-row flex-col my-3 gap-3 items-start flex-wrap">
                  <div className="col-md-6">
                    <div className="relative w-full">
                      <input
                        type="email"
                        required
                        value={form.email}
                        onChange={(e) =>
                          setForm({ ...form, email: e.target.value })
                        }
                        placeholder={"Your Best Email"}
                        style={{
                          color: "#6B778C",
                          border: `2px solid ${
                            error && !form.email
                              ? "red"
                              : form.email && incorrectEmail && !isEmailValid
                              ? "red"
                              : "#dfe1e6"
                          }`,
                        }}
                        className="px-2 font-inter text-lg font-normal bg-white py-1.5 w-full text-start rounded login_input"
                      />
                      <img
                        src="/static/media/email icon.png"
                        alt=""
                        className="emailIcon"
                      />
                    </div>

                    {error && !form.email && (
                      <small className="text-danger ">
                        Please enter your email
                      </small>
                    )}
                    {form.email && incorrectEmail && !isEmailValid && (
                      <small className="text-danger ">
                        Please enter correct email
                      </small>
                    )}
                  </div>
                  <Modal />
                </div>
              </div>
              <div className="flex flex-wrap flex-sm-row mt-4 gap-3">
                <div className="rounded cursor-pointer w-fit primary_bg_color text-center">
                  <div
                    onClick={() => preview()}
                    className="d-flex align-items-center text-white px-4 py-2 link"
                  >
                    <div className="d-flex align-items-center">
                      <FaEye
                        style={{
                          fontSize: "18px",
                          marginRight: "5px",
                        }}
                      />{" "}
                    </div>
                    <div className=" font-inter text-base font-semibold">
                      Preview Job
                    </div>
                  </div>
                </div>
                <Questionaire {...props} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};
