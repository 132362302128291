export const data = [
  {
    title: " How long will my job be active?",
    desc: "The job positions will remain active for 14 days. After the post expires, it is saved on my jobs page, and can be easily reposted.",
  },
  {
    title: " How do I get candidates?",
    desc: "Resumes will be sent directly to your email inbox.When a job seeker clicks to Apply, their resume is sent directly to the email address you provided when creating the job.",
  },
  {
    title: "How many applications can I expect to receive?",
    desc: "No limits on the number of applications that can be accepted,we welcome all interested candidates to apply for your position.",
  },
  {
    title: " How do I filter candidates?",
    desc: "The questionnaire serves as a filtering system and functions as a type of test that you can create for your candidates to help prevent irrelevant applications.",
    list: [
      "You can create up to 5 custom questions",
      " You have the option to add answers to those questions ",
      " You can designate correct answers ",
      " You can set a passing score ",
    ],
    text: [
      "Prospective candidates will be required to pass your questionnaire when applying, in order for their resumes to be submitted.",
      "Please note that once you've posted the job, you cannot change the questionnaire. However, you will have the opportunity to edit the questionnaire if you decide to repost the same job in the future.",
    ],
  },
  {
    title: "What happens if I don't receive applicants at all?",
    desc: "If you have not received any candidates at all, you can contact us so that we can understand the reason and try to find a solution.",
  },
  {
    title: "How much does it cost?",
    desc: "Currently, you can post a single job for $29, or alternatively, subscribe to our 89$ monthly unlimited plan in which you can post unlimited job positions and receive a 1-month free trial to see if it suits your needs.",
  },
];

export const infos = [
  { text: "You can create up to 5 custom questions" },
  { text: "You can create answers to those questions" },
  { text: " You can choose the correct answers" },
  { text: " You can choose a passing score" },
];

export const Minus = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="23"
      viewBox="0 0 20 23"
      fill="none"
    >
      <path
        d="M1.17436 12.14H18.399C19.0477 12.14 19.5735 11.5491 19.5735 10.8199C19.5735 10.0909 19.0479 9.5 18.399 9.5H1.17436C0.525795 9.50018 0 10.0911 0 10.8201C0 11.5491 0.525795 12.14 1.17436 12.14Z"
        fill="white"
      />
    </svg>
  );
};

export const Plus = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
    >
      <path
        d="M20.8999 10.4H12.1V1.59993C12.1 0.992864 11.6072 0.5 10.9999 0.5C10.3928 0.5 9.89997 0.992864 9.89997 1.59993V10.4H1.09993C0.492864 10.4 0 10.8928 0 11.4999C0 12.1072 0.492864 12.6 1.09993 12.6H9.89997V21.3999C9.89997 22.0071 10.3928 22.5 10.9999 22.5C11.6072 22.5 12.1 22.0071 12.1 21.3999V12.6H20.8999C21.5071 12.6 22 12.1072 22 11.4999C22 10.8928 21.5071 10.4 20.8999 10.4Z"
        fill="white"
      />
    </svg>
  );
};
