import React from "react";
import { inputs } from "../../libs/mockdata";

export default function Textareas({ error, form, setForm }) {
  return (
    <>
      {inputs.map(({ name, title, placeholder, value, subtext, data }, idx) => {
        if (idx > 9) {
          return (
            <>
              <div
                key={idx}
                className="d-sm-flex align-items-center gap-2 my-4"
              >
                <div
                  style={{
                    fontFamily: "inter",
                    fontSize: "1.1rem",
                    fontWeight: "700",
                  }}
                  className="black capitalize"
                >
                  {title}
                </div>

                <small>({subtext}).</small>
              </div>

              <div className="col-12">
                <textarea
                  value={form[name]}
                  onChange={(e) => setForm({ ...form, [name]: e.target.value })}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && idx == 10) e.preventDefault();
                  }}
                  placeholder={placeholder}
                  className="w-100 rounded p-3 placholder"
                  style={{
                    height: "350px",
                    color: "black",
                    whiteSpace: "pre-wrap",
                    background: "#EBECF0",
                    border: `2px solid ${
                      error && !form[name] ? "red" : "#dfe1e6"
                    }`,
                    boxShadow:
                      "2px 2px 3px rgb(213, 213, 213, 0.4), -2px -2px 3px rgb(213, 213, 213, 0.4)",
                  }}
                ></textarea>
                {error && !form[name] && (
                  <small className="text-danger">Please enter {title}</small>
                )}
              </div>
            </>
          );
        }
      })}
    </>
  );
}
