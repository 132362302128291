import {
  FaStackExchange,
  FaUserAlt,
  FaEraser,
  FaBriefcase,
  FaBook,
  FaHome,
  FaCheck,
  FaBell,
} from "react-icons/fa";
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useGlobalContext } from "../context";
import "./Navbar.css";
import axios from "axios";
import styles from "./index.module.scss";
import { api_url } from "../libs/api";
import { handleClickEvent } from "../libs/utils";

function Navbar() {
  const user = useGlobalContext();
  const [resume, setResume] = useState(false);

  const navigate = useNavigate();

  const links = [
    { icon: <FaUserAlt />, text: "profile", url: "/applicant-profile" },
    { icon: <FaHome />, text: "job feed", url: "/" },
  ];

  const linkR = [
    { icon: <FaUserAlt />, text: "profile", url: "/recruiter-profile" },
    // { icon: <FaEraser />, text: " Billing", url: "/subscription" },
  ];

  const logout = () => {
    handleClickEvent({
      action: "sign out",
      label: "Logout",
    });
    localStorage.removeItem("key");
    localStorage.removeItem("id");
    localStorage.removeItem("subscription_end_date");
    // user.setUser({
    //   address: "",
    //   applicant: null,
    //   email: "",
    //   id: "",
    //   name: "",
    //   phone: "",
    // });
    // user.setRecruiter({
    //   recruiter: null,
    //   email: "",
    //   id: "",
    //   name: "",
    //   phone: "",
    // });
    window.location.href = "/";
  };

  const key = JSON.parse(localStorage.getItem("key"));
  const handleChange = (e) => {
    e.preventDefault();
    const cv = e.target.files[0];
    axios
      .post(
        `${api_url}resume/`,
        {
          resume: cv,
        },
        {
          headers: {
            Authorization: `Token ${key}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setResume(true);
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setTimeout(() => {
      setResume(false);
    }, 5000);
    if (resume) {
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  }, [resume]);

  useEffect(() => {
    setTimeout(() => {
      document.body.style.visibility = "visible";
    }, 1300);
  }, []);

  return (
    <article className="container-fluid  bg-white ">
      <nav
        style={{
          position: "fixed",
          zIndex: "3",
          top: "0",
          left: "0",
          right: "0",
        }}
        className="py-2 shadow-sm  d-none d-sm-block bg-white"
      >
        <div className={"row px-3"}>
          <div className="col-12 mx-auto">
            <div className="d-flex justify-content-between align-items-center">
              <img
                src="./static/media/into sales.svg"
                style={{
                  width: "174px",
                  height: "45px",
                  objectFit: "contain",
                }}
                className=""
                alt=""
              />
              <div className="d-flex align-items-center">
                <label
                  for="id"
                  style={{
                    fontStyle: "inter",
                    background: "#F19306",
                    borderRadius: "100%",
                    fontSize: "1rem",
                    fontWeight: "500",
                  }}
                  className="rounded mx-3 white px-3 py-2 cursor-pointer btnHover"
                >
                  {user.user.resume ? "Update Resume" : "Upload Resume"}
                </label>
                <input
                  type={"file"}
                  id="id"
                  onChange={(e) => handleChange(e)}
                />
                <Link
                  to="/applicant-profile"
                  onClick={() => user.setPath("/applicant-profile")}
                  className=""
                >
                  <img
                    style={{
                      width: "42px",
                      height: "42px",
                    }}
                    src="./static/media/user.png"
                    alt=""
                    className="imgHover"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <section
        className={`nav_section d-none d-sm-block  ${
          window.location.pathname === "/" ? "d-sm-none" : ""
        }`}
      >
        <div className="h-100 d-flex flex-column">
          <img
            style={{
              width: "82px",
              height: "82px",
            }}
            src="./static/media/user.png"
            alt=""
            className="mx-auto mt-5 mb-3"
          />
          <div
            style={{
              fontWeight: "400",
              fontSize: "1rem",
            }}
            className="white text-center"
          >
            Hello {user.user.name}
          </div>
          <div
            style={{
              fontWeight: "400",
              fontSize: "0.8rem",
            }}
            className="white text-center mb-4"
          >
            Sales{" "}
          </div>
          <div className="my-2">
            {links.map(({ icon, text, url }, index) => {
              return (
                <Link
                  to={url}
                  onClick={() => user.setPath(url)}
                  style={{
                    fontWeight: "700",
                    fontSize: "20px",
                    pointerEvents: `${
                      (url === "/plans" && "none") ||
                      (url === "/billing" && "none") ||
                      ""
                    }`,
                  }}
                  key={index}
                  className={
                    user.path === url
                      ? "active align-items-center d-flex white py-2 text-capitalize nav_link link"
                      : "align-items-center d-flex white py-2 text-capitalize nav_link link "
                  }
                >
                  <div className="mx-3 mx-lg-4">{icon}</div>
                  <div style={{ fontFamily: "roboto" }}>{text}</div>
                </Link>
              );
            })}
          </div>
          <div
            onClick={logout}
            style={{ border: "0.1rem solid rgba(255, 255, 255, 1)" }}
            className="mt-auto rounded align-items-center d-flex mx-auto px-3 py-2 my-5 cursor-pointer text-white logout"
          >
            <FaUserAlt style={{ fontSize: "20px", fontWeight: "400" }} />
            <div
              style={{ fontSize: "14px", fontWeight: "400" }}
              className="mx-2"
            >
              Log Out
            </div>
          </div>
        </div>
      </section>
      {/* RESUME POP-UP */}
      <div className={`${resume ? "show" : ""} account_success d-flex`}>
        <div
          style={{ height: "fit-content" }}
          className="col-10 col-sm-10 col-md-6 col-lg-5 text-center bg-white mx-auto py-5 px-5 position-relative"
        >
          <div
            style={{
              width: "103.33px",
              height: "102.5px",
              border: "0.3rem solid rgba(101, 202, 117, 1)",
              borderRadius: "100%",
            }}
            className="bg-white d-flex align-items-center justify-content-center mx-auto mt-5"
          >
            <FaCheck
              style={{
                width: "41.33px",
                height: "30.75px",
                color: "rgba(101, 202, 117, 1)",
              }}
              className=""
            />{" "}
          </div>
          <div
            style={{
              fontWeight: "700",
              fontSize: "18px",
              lineHeight: "26.78px",
              fontFamily: "poppins",
            }}
            className="primary_color mb-1 mt-3 text-capitalize"
          >
            you have successfully {user.user.resume ? "updated" : "uploaded"}{" "}
            your resume
          </div>
          <div
            onClick={() => setResume(false)}
            style={{
              fontWeight: "500",
              fontSize: "20px",
              lineHeight: "24.2px",
            }}
            className="primary_bg_color white rounded py-3 mb-5 mt-2 cursor-pointer "
          >
            OK
          </div>
        </div>
      </div>
    </article>
  );
}

export const Navbar2 = () => {
  const user = useGlobalContext();
  const [resume, setResume] = useState(false);

  const navigate = useNavigate();

  const links = [
    { icon: <FaUserAlt />, text: "profile", url: "/applicant-profile" },
    { icon: <FaHome />, text: "job feed", url: "/" },
  ];

  const linkR = [
    { icon: <FaUserAlt />, text: "profile", url: "/recruiter-profile" },
    // { icon: <FaEraser />, text: " Billing", url: "/subscription" },
  ];

  const logout = () => {
    handleClickEvent({
      action: "sign out",
      label: "Logout",
    });
    localStorage.removeItem("key");
    localStorage.removeItem("id");
    localStorage.removeItem("subscription_end_date");
    // user.setUser({
    //   address: "",
    //   applicant: null,
    //   email: "",
    //   id: "",
    //   name: "",
    //   phone: "",
    // });
    // user.setRecruiter({
    //   recruiter: null,
    //   email: "",
    //   id: "",
    //   name: "",
    //   phone: "",
    // });
    window.location.href = "/";
  };

  const key = JSON.parse(localStorage.getItem("key"));
  const handleChange = (e) => {
    e.preventDefault();
    const cv = e.target.files[0];
    axios
      .post(
        `${api_url}resume/`,
        {
          resume: cv,
        },
        {
          headers: {
            Authorization: `Token ${key}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setResume(true);
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  const { name, plan, subscription_end_date } = user.recruiter;

  useEffect(() => {
    setTimeout(() => {
      setResume(false);
    }, 5000);
    if (resume) {
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  }, [resume]);

  useEffect(() => {
    setTimeout(() => {
      document.body.style.visibility = "visible";
    }, 1300);
  }, []);

  const lastDateValue = Number(subscription_end_date?.slice(-1)) + 1;

  const expiryDate = new Date(
    subscription_end_date?.slice(0, -1) + lastDateValue
  ).toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  return (
    <article className="container-fluid bg-white ">
      <nav
        style={{
          position: "fixed",
          zIndex: "3",
          top: "0",
          left: "0",
          right: "0",
          // boxShadow: "0 0 10px 0.2px rgb(0,0,0,.15)",
          borderBottom: "1px solid #dfe1e6",
        }}
        className="py-2 bg-white px-2 shadow-sm d-none d-sm-block"
      >
        <div className="row px-2">
          <div className="col-12 mx-auto">
            <div className="d-flex justify-content-between align-items-center">
              <img
                src="./static/media/into sales.svg"
                style={{
                  width: "174px",
                  height: "45px",
                  objectFit: "contain",
                }}
                className=""
                alt=""
              />
              <div className="d-flex align-items-center gap-4">
                <div
                  onClick={() => navigate("/jobs")}
                  style={{
                    fontStyle: "inter",
                    background: "#F19306",
                    borderRadius: "100%",
                    fontSize: "1rem",
                    fontWeight: "500",
                    width: "159px",
                  }}
                  className="rounded white px-3 py-2 cursor-pointer opacity text-center btnHover"
                >
                  My Jobs
                </div>

                {plan != "none" && (
                  <div
                    style={{ zIndex: "1" }}
                    className="position-relative notify-container"
                  >
                    <FaBell
                      style={{ color: "#4897d8", fontSize: "30px" }}
                      className="cursor-pointer"
                    />
                    <div className="notify">1</div>
                    <div className="d-flex bg-white align-items-center gap-4 shadow-lg expiry">
                      <div
                        style={{
                          height: "93px",
                          width: "93px",
                          fontSize: "2rem",
                          fontWeight: "600",
                          fontFamily: "clash display",
                          background:
                            "linear-gradient(90deg, #A1C4FD 0%, #C2E9FB 100%)",
                        }}
                        className="d-flex text-capitalize align-items-center justify-content-center rounded-3 text-white"
                      >
                        {name.substring(0, 1)}
                      </div>
                      <div className="d-flex flex-column gap-2">
                        <div
                          style={{
                            fontSize: "1.2rem",
                            fontWeight: "600",
                            fontFamily: "poppins",
                          }}
                          className=""
                        >
                          Hi {name}
                        </div>
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            fontFamily: "inter",
                          }}
                          className=""
                        >
                          {plan?.includes("limited")
                            ? `Your unlimited subscription expires on ${expiryDate}`
                            : "You are on individual plan"}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <Link
                  onClick={() => user.setPath("/recruiter-profile")}
                  to="/recruiter-profile"
                  style={{
                    borderRadius: "100%",
                  }}
                >
                  <img
                    style={{
                      width: "42px",
                      height: "42px",
                    }}
                    src="./static/media/user.png"
                    alt=""
                    className="imgHover"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <section
        className={`${
          window.location.pathname === "/jobs"
            ? "d-sm-none"
            : window.location.pathname === "/edit-jobs"
            ? "d-sm-none"
            : window.location.pathname === "/preview-job"
            ? "d-sm-none"
            : window.location.pathname === "/"
            ? "d-sm-none"
            : window.location.pathname === "/create-job"
            ? "d-sm-none"
            : window.location.pathname === "/subscription"
            ? "d-sm-none"
            : ""
        } nav_section d-none d-sm-block`}
      >
        <div className="h-100 d-flex flex-column">
          <img
            style={{
              width: "82px",
              height: "82px",
            }}
            src="./static/media/user.png"
            alt=""
            className="mx-auto mt-5 mb-3"
          />
          <div
            style={{
              fontWeight: "400",
              fontSize: "1rem",
            }}
            className="white text-center text capitalize"
          >
            Hello {user.recruiter.name}
          </div>
          <div
            style={{
              fontWeight: "400",
              fontSize: "0.8rem",
            }}
            className="white text-center mb-3"
          >
            Recruiter{" "}
          </div>
          <div className="my-2">
            {linkR.map(({ icon, text, url }, index) => {
              return (
                <Link
                  to={url}
                  onClick={() => user.setPath(url)}
                  style={{ fontWeight: "700", fontSize: "20px" }}
                  key={index}
                  className={
                    user.path === `${url}`
                      ? "active align-items-center d-flex white py-2 text-capitalize nav_link link"
                      : "align-items-center d-flex white py-2 text-capitalize nav_link link "
                  }
                >
                  <div className="mx-3 mx-lg-4">{icon}</div>
                  <div style={{ fontFamily: "roboto" }}>{text}</div>
                </Link>
              );
            })}
          </div>
          <div
            onClick={logout}
            style={{ border: "0.1rem solid rgba(255, 255, 255, 1)" }}
            className="mt-auto rounded align-items-center d-flex mx-auto px-3 py-2 my-5 cursor-pointer text-white logout"
          >
            <FaUserAlt style={{ fontSize: "20px", fontWeight: "400" }} />
            <div
              style={{ fontSize: "14px", fontWeight: "400" }}
              className="mx-2"
            >
              Log Out
            </div>
          </div>
        </div>
      </section>
    </article>
  );
};

export const Navbar1 = () => {
  if (window.location.pathname === "/password-reset-confirm") {
    return null;
  }

  return (
    <>
      <div
        style={{ zIndex: "1000" }}
        className={`${styles.nav}  d-none d-md-flex shadow-sm ${
          window.location.pathname === "/reset-password" ? "bg-white" : ""
        }`}
      >
        <div
          style={{
            backgroundImage: `url('/static/media/into sales.svg')`,
            cursor: "pointer",
          }}
          className={styles.logo}
          alt=""
        />
        <div style={{ cursor: "pointer" }} className={styles.active}>
          {" "}
          Home
        </div>
        <div className={`${styles.btns} d-non`}>
          <Link
            to="/signin"
            style={{
              textDecoration: "none",
              boxShadow:
                " 4px 4px 7px rgb(0, 0, 0, .2),  -4px -4px 7px rgb(0, 0, 0, .2)",
            }}
            className={styles.btn}
          >
            Login for recruiters
          </Link>
          <Link
            to="/login"
            style={{
              textDecoration: "none",
              boxShadow:
                " 4px 4px 7px rgb(0, 0, 0, .2),  -4px -4px 7px rgb(0, 0, 0, .2)",
            }}
            className={styles.btn}
          >
            Login for salespeople
          </Link>
        </div>
      </div>
    </>
  );
};

export default Navbar;
