import Select from "react-select";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import {
  FaCheck,
  FaChevronDown,
  FaEdit,
  FaEye,
  FaTrash,
  FaUpload,
} from "react-icons/fa";
import { Dropdown } from "../../components/Dropdown";
import { useGlobalContext } from "../../context";
import "./Index.css";
import { locations } from "../jobs/data";
import Navbar from "../../navigation/Navbar";
import { displaySpinner } from "../recruiter/CreateAccount";
import { removeSpinner } from "../recruiter/CreateAccount";
import { PageDescription } from "../../reusableComponents/pageDescription";
import { api_url, api_host } from "../../libs/api";

export default function Index() {
  const user = useGlobalContext();
  const [edit, setEdit] = useState(false);
  const [success, setSuccess] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [name, setName] = useState(user.user.name);
  const [state, setState] = useState(user.user.address);
  const [phone, setPhone] = useState(user.user.phone);
  const [email, setEmail] = useState(user.user.email);
  const [emptyArray, setEmptyArray] = useState([]);
  const [resume, setResume] = useState(false);

  const ref = useRef();

  const statusOptions = [
    { value: "employed", selected: false, label: "employed" },
    { value: "Unemployed", selected: false, label: "Unemployed" },
  ];

  const onChange = (e) => {
    const re = /^(?=.*[+])[- +()0-9 ]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setPhone(e.target.value);
    }
  };

  const deleteApi = () => {
    const key = JSON.parse(localStorage.getItem("key"));

    axios
      .delete(`${api_url}resume_delete/`, {
        headers: { Authorization: `Token ${key}` },
      })
      .then((res) => console.log(res.data))
      .catch((err) => console.log(err));
  };

  const submit = () => {
    deleteApi();
    setDeleted(true);
  };

  const updateProfile = () => {
    const id = JSON.parse(localStorage.getItem("id"));
    const key = JSON.parse(localStorage.getItem("key"));

    const elements = displaySpinner(); // Display spinner

    axios
      .put(
        `${api_url}applicant/${id}/profile/`,
        {
          name: name,
          phone: phone,
          email: email,
          address: state,
        },
        {
          headers: {
            Authorization: `Token ${key}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        removeSpinner(elements); // Remove spinner
        setUpdated(true);
        console.log(res.data);
      })
      .catch((err) => {
        removeSpinner(elements); // Remove spinner
        console.log(err.data);
      });
  };

  const editProfile = (e) => {
    e.preventDefault();
    setEdit(true);
    if (edit) {
      updateProfile();
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const cv = e.target.files[0];
    const key = JSON.parse(localStorage.getItem("key"));
    axios
      .post(
        `${api_url}resume/`,
        {
          resume: cv,
        },
        {
          headers: {
            Authorization: `Token ${key}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setResume(true);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setTimeout(() => {
      setResume(false);
    }, 5000);
    if (resume) {
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  }, [resume]);

  useEffect(() => {
    const leave = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setSuccess(false);
      }
    };
    document.addEventListener("mousedown", leave);
  }, [ref]);

  useEffect(() => {
    setTimeout(() => {
      setDeleted(false);
    }, 3000);
    if (deleted) {
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  }, [deleted]);

  useEffect(() => {
    if (edit) {
      document.getElementById("name").readOnly = false;
      document.getElementById("phone").readOnly = false;
      document.getElementById("email").readOnly = false;
    }
  }, [edit]);

  useEffect(() => {
    setTimeout(() => {
      setUpdated(false);
    }, 3000);
    if (updated) {
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  }, [updated]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={`margin bg-white`}>
      <PageDescription title={"Profile"} content={"Profile Information"} />

      <Navbar />
      <div
        style={{ minHeight: "100vh", background: "#fff" }}
        className="container-fluid b"
      >
        <div className="px-md-5 pb-md-5 pt-4">
          <div className="row">
            <div className="col-lg-11 mb-md-5 py-4">
              <div className="d-flex justify-content-between align-items-center">
                <div
                  style={{
                    fontFamily: "inter",
                    fontSize: "1.87rem",
                    fontWeight: "700",
                  }}
                  className="black"
                >
                  Account info
                </div>
                <div className="d-flex align-items-between">
                  <div
                    onClick={() => setSuccess(true)}
                    className={`${
                      user.user.resume ? "" : "d-none"
                    } mx-md-3 rounded 
                  cursor-pointer primary_bg_color`}
                  >
                    <div className="d-flex align-items-center text-white px-2 py-2">
                      <div>
                        <FaEye className="mx-2" />{" "}
                      </div>
                      <div
                        className="d-none d-md-block"
                        style={{
                          fontFamily: "inter",
                          fontSize: "0.87rem",
                          fontWeight: "500",
                        }}
                      >
                        My Resume
                      </div>
                    </div>
                  </div>
                  <label
                    for="input"
                    className="rounded cursor-pointer primary_bg_color mx-2 d-md-none no-hover"
                  >
                    <div
                      className="d-flex align-items-center text-white 
                    px-2 py-2"
                    >
                      <div>
                        <FaUpload className="mx-2" />{" "}
                      </div>
                      <div
                        className="d-none d-md-block"
                        style={{
                          fontFamily: "inter",
                          fontSize: "0.87rem",
                          fontWeight: "500",
                        }}
                      >
                        Upload Resume
                      </div>
                    </div>
                  </label>
                  <input
                    type={"file"}
                    id="input"
                    className="d-none"
                    onChange={(e) => handleChange(e)}
                  />
                  <div
                    onClick={submit}
                    className={`${user.user.resume ? "" : "d-none"}
                   rounded cursor-pointer bg-danger`}
                  >
                    <div className="d-flex align-items-center text-white px-2 py-2">
                      <div>
                        <FaTrash className="mx-2 text-white" />{" "}
                      </div>
                      <div
                        className="d-none d-md-block"
                        style={{
                          fontFamily: "inter",
                          fontSize: "0.87rem",
                          fontWeight: "500",
                        }}
                      >
                        Delete Resume
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <form onSubmit={editProfile} className="row mt-2">
                <div className="col-12 col-md-6 my-2">
                  <div
                    style={{
                      fontFamily: "inter",
                      fontSize: "0.95rem",
                      fontWeight: "500",
                    }}
                    className=""
                  >
                    Name
                  </div>
                  <input
                    type="text"
                    readOnly
                    id="name"
                    required
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    style={{
                      fontFamily: "inter",
                      fontSize: "0.87rem",
                      fontWeight: "400",
                      color: "#6B778C",
                      background: "white",
                      border: `2px solid  #DFE1E6`,
                    }}
                    className="py-2 px-3 w-100 text-start rounded login_input"
                  />
                </div>
                <div className="col-12 col-md-6 my-2" style={{ marginTop: "" }}>
                  <div
                    style={{
                      fontFamily: "inter",
                      fontSize: "0.95rem",
                      fontWeight: "500",
                    }}
                    className=""
                  >
                    State
                  </div>
                  {edit ? (
                    <Select
                      onChange={(e) => setState(e.value)}
                      // defaultValue={{
                      //   label: "user.user.address",
                      //   value: "user.user.address",
                      // }}
                      options={edit ? locations : locations}
                      styles={{
                        input: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: "4px 0",
                          fontFamily: "inter",
                          fontSize: "0.3rrem !important",
                          fontWeight: "400",
                          color: "#6B778C",
                        }),
                        placeholder: (baseStyles, state) => ({
                          ...baseStyles,
                          color: "black",
                        }),
                        control: (base, state) => ({
                          ...base,
                          boxShadow: "none",
                          border: "2px solid #dfe1e6",
                          borderRadius: "6px",
                          fontSize: "0.87rem",
                          height: "49.8px",

                          "&:hover": {
                            // boxShadow: "0 0 0 2px black !important",
                            borderColor: "#4897d8 !important",
                          },
                          "&:focus": {},
                          boxShadow: state.isFocused
                            ? "2px 2px 3px rgb(213, 213, 213, 0.4), -2px -2px 3px rgb(213, 213, 213, 0.4) !important"
                            : "2px 2px 3px rgb(213, 213, 213, 0.4), -2px -2px 3px rgb(213, 213, 213, 0.4) !important",
                        }),
                      }}
                    />
                  ) : (
                    <input
                      readOnly
                      id="state"
                      required
                      value={state}
                      style={{
                        fontFamily: "inter",
                        fontSize: "0.87rem",
                        fontWeight: "400",
                        color: "#6B778C",
                        background: "white",
                        border: `2px solid  #DFE1E6`,
                      }}
                      className="py-2 px-3 w-100 text-start rounded login_input"
                    />
                  )}
                </div>
                <div className="col-12 col-md-6 my-2">
                  <div
                    style={{
                      fontFamily: "inter",
                      fontSize: "0.95rem",
                      fontWeight: "500",
                    }}
                    className=""
                  >
                    Phone
                  </div>
                  <input
                    readOnly
                    id="phone"
                    required
                    onChange={onChange}
                    maxLength="11"
                    value={phone}
                    style={{
                      fontFamily: "inter",
                      fontSize: "0.87rem",
                      fontWeight: "400",
                      color: "#6B778C",
                      background: "white",
                      border: `2px solid  #DFE1E6`,
                    }}
                    className="py-2 px-3 w-100 text-start rounded login_input"
                  />
                </div>
                <div className="col-12 col-md-6 my-2">
                  <div
                    style={{
                      fontFamily: "inter",
                      fontSize: "0.95rem",
                      fontWeight: "500",
                    }}
                    className=""
                  >
                    Email
                  </div>
                  <input
                    type="email"
                    readOnly
                    id="email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    style={{
                      fontFamily: "inter",
                      fontSize: "0.87rem",
                      fontWeight: "400",
                      color: "#6B778C",
                      background: "white",
                      border: `2px solid  #DFE1E6`,
                    }}
                    className="py-2 px-3 w-100 text-start rounded login_input"
                  />
                </div>
                <div className="col-12 col-md-6 my-2">
                  <button
                    type="submit"
                    style={{ width: "fit-content", border: "none" }}
                    className={`rounded 
                  cursor-pointer orange`}
                  >
                    <div className="d-flex align-items-center text-white px-2 py-2">
                      <div>
                        <FaEdit className="mx-2" />{" "}
                      </div>
                      <div
                        className=" d-md-block text-capitalize"
                        style={{
                          fontFamily: "inter",
                          fontSize: "0.87rem",
                          fontWeight: "500",
                        }}
                      >
                        {edit ? "update profile" : "edit profile"}
                      </div>
                    </div>
                  </button>
                </div>
              </form>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </div>
      {/* PDF POP-UP */}
      <div className={`${success ? "show" : ""} account_success d-flex`}>
        <div
          style={{ height: "fit-content" }}
          className="col-10 
 col-sm-10 col-md-6 col-lg-5 text-center bg-white mx-auto position-relative"
        >
          <object
            ref={ref}
            data={`${api_host}/${user.user.resume}`}
            type="application/pdf"
            className="pf "
            style={{ width: "100%", height: "80vh" }}
          ></object>
        </div>
      </div>

      {/*DELETE POPUP*/}
      <div className={`${deleted ? "show" : ""} delete_success d-flex`}>
        <div
          style={{ height: "fit-content" }}
          className={`${deleted ? "show" : ""} delete_content d-flex`}
        >
          resume deleted successfully
        </div>
      </div>

      {/*UDATE PROFILE POPUP*/}
      <div className={`${updated ? "show" : ""} delete_success d-flex`}>
        <div
          style={{ height: "fit-content" }}
          className={`${updated ? "show" : ""} delete_content `}
        >
          profile updated successfully
        </div>
      </div>

      {/* RESUME POP-UP */}
      <div className={`${resume ? "show" : ""} account_success d-flex`}>
        <div
          style={{ height: "fit-content" }}
          className="col-10 col-sm-10 col-md-6 col-lg-5 text-center bg-white mx-auto py-5 px-5 position-relative"
        >
          <div
            style={{
              width: "103.33px",
              height: "102.5px",
              border: "0.3rem solid rgba(101, 202, 117, 1)",
              borderRadius: "100%",
            }}
            className="bg-white d-flex align-items-center justify-content-center mx-auto mt-5"
          >
            <FaCheck
              style={{
                width: "41.33px",
                height: "30.75px",
                color: "rgba(101, 202, 117, 1)",
              }}
              className=""
            />{" "}
          </div>
          <div
            style={{
              fontWeight: "700",
              fontSize: "18px",
              fontFamily: "poppins",
              lineHeight: "21.78px",
            }}
            className="primary_color mb-1 mt-3 text-capitalize"
          >
            you have successfully {user.user.resume ? "updated" : "uploaded"}{" "}
            your resume
          </div>
          <div
            onClick={() => setResume(false)}
            style={{
              fontWeight: "500",
              fontSize: "20px",
              lineHeight: "24.2px",
            }}
            className="primary_bg_color white rounded py-3 mb-5 mt-2 cursor-pointer "
          >
            OK
          </div>
        </div>
      </div>
    </div>
  );
}
