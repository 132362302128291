import axios from "axios";
import {
  displaySpinner,
  removeSpinner,
} from "../screens/recruiter/CreateAccount";
import { user_key } from "./mockdata";
import { api_url } from "./api";
import ReactGA from "react-ga";

export const publishJob = ({
  previewJob,
  setLoading,
  setSuccess,
  navigate,
  setCreateJob,
}) => {
  setLoading(true);
  const spinner = displaySpinner(); // Display spinner
  const date = new Date();
  const requestBody = {
    ...previewJob,
    country: "USA",
    creation_date: previewJob.time,
    questionnaire_required: previewJob.questionnaire.length > 0 ? true : false,
    questionnaire: previewJob.questionnaire,
    passing_score: previewJob.passing_score,
  };

  axios
    .post(
      `${api_url}createjob/`,
      { ...requestBody },
      {
        headers: {
          Authorization: `Token ${user_key}`,
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((res) => {
      removeSpinner(spinner); // Remove spinner
      console.log(res.data);
      if (res.data.msg === "Job created successfully") {
        setLoading(false);
        setSuccess(true);
      } else if (res.data.error === "Plan type not provided.") {
        navigate("/subscription");
        setCreateJob(requestBody);
      } else if (res.data.error === "redirect_for_payment") {
        navigate("/subscription");
      } else {
        setSuccess(false);
      }
    })
    .catch((err) => {
      console.log(err);
      removeSpinner(spinner); // Remove spinner
      setSuccess(false);
    });
};

export const removeObjectKeys = (form) => {
  const _object = { ...form };
  delete _object.creation_date;
  delete _object.email;
  delete _object.end_date;
  delete _object.is_published;
  delete _object.passing_score;
  delete _object.questionnaire_required;
  delete _object.questions;
  delete _object.recruiter;
  delete _object.start_date;
  delete _object.status;
  delete _object.timeleft;
  delete _object.period;
  delete _object.id;
  delete _object.country;
  return _object;
};

export const handleClickEvent = ({ action, label }) => {
  // Track a custom event
  ReactGA.event({ category: "Button Click", action, label });
  // Continue with your button's click handler
};
