import React, { useState, useEffect, useRef } from "react";
import { FaCheck, FaChevronDown } from "react-icons/fa";
import "./Index.css";
import { ho } from "../screens/jobs/data";
import { GoLocation } from "react-icons/go";

export const Dropdown3 = ({
  border,
  error,
  city,
  form,
  setForm,
  state,
  defaultValue,
}) => {
  const [input, setInput] = useState("");
  const [inputs, setInputs] = useState("");
  const [show, setShow] = useState(false);
  const [first, setFirst] = useState([]);

  useEffect(() => {
    if (state || !state) {
      const u = ho[state];
      setFirst(u?.map((srt) => ({ value: srt, selected: false })));
    }
  }, [state]);

  useEffect(() => {
    const item = () =>
      first?.filter((idx) => {
        if (!defaultValue) {
          if (idx.value !== city) {
            setForm({ ...form, city: "" });
          }
        }
      });
    item();
  }, [first]);

  const filtered = first?.filter((item) =>
    item.value.toLowerCase().includes(input.toLocaleLowerCase())
  );
  const ref = useRef(null);
  useEffect(() => {
    const muf = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", muf);
  }, [ref]);

  useEffect(() => {
    setInput("");
    setInputs(city);
  }, [city]);

  useEffect(() => {
    if (!show) {
      setInput("");
    }
  }, [show]);

  return (
    <div ref={ref} className="position-relative w-100">
      <div className="position-relative" style={{ cursor: "pointer" }}>
        <input
          type="text"
          value={input}
          onClick={() => setShow(!show)}
          onChange={(e) => setInput(e.target.value)}
          placeholder={city ? city : "Select"}
          className={"input_2 px-3"}
          style={{
            borderColor: error && !city ? "red" : "",
            border: border ? border : "",
            height: "45.59px",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        />
        <div
          className="arrow_container items-center flex"
          style={{ borderColor: "#f19306" }}
        >
          <FaChevronDown style={{ color: "#f19306" }} className="arrow2 " />
        </div>
      </div>
      {state ? (
        <section
          className={`${
            show ? "z-10 mt-2 visible" : "-z-10 mt4 invisible"
          } absolute duration-500 top-full h-fit rounded left-0 w-full shadow-lg p-2 bg-white`}
        >
          <div
            className={` max-h-[300px] h-auto custom-scrollbar overflow-y-auto pr-2 mx-auto flex flex-col gap-0.5 bg-white`}
          >
            {filtered?.length > 0 &&
              filtered?.map(({ value, selected, id }, index) => {
                return (
                  <div key={index} className="">
                    <div
                      style={{ background: city === value ? "" : "" }}
                      className={`d-flex rounded cursor-default justify-content-between duration-500 py-2 px-3 text-capitalize ${
                        city === value
                          ? "text-white primary_bg_color"
                          : "hover:bg-opacity-10 hover:bg-primary"
                      }`}
                      onClick={() => {
                        setForm({ ...form, city: value });
                        setShow(false);
                        console.log(value);
                      }}
                    >
                      <div className="">{value}</div>
                    </div>
                  </div>
                );
              })}

            {filtered?.length === 0 && (
              <div className="py-3 text-center">No Matches</div>
            )}
          </div>
        </section>
      ) : (
        ""
      )}
    </div>
  );
};
