import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-elastic-carousel";
import {
  FaDotCircle,
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaRegEdit,
  FaYoutube,
} from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import styles from "./index.module.scss";
import { icons } from "./mockData";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { api_url } from "../../libs/api";

export const HeaderComponent = () => {
  return (
    <div className="">
      <div className={styles.HeaderComponent}>
        <div className="">
          <div className={styles.title}>
            A Wide Variety Of Sales Jobs{" "}
            <span className="primary_color">Await</span> You Here!
          </div>
          <div className={styles.text}>
            Intoosales will filter through and deliver the best options, whether
            you're looking for a job or searching for someone to fill a sales
            position within your company.
          </div>
          <div className={styles.button}>
            <Link to="/createaccount" className={styles.btnHeader}>
              {/* <img src="/static/media/searc 1.png" alt="" /> */}
              <FaRegEdit className={styles.img} />
              <div>
                Post a <br /> sales job
              </div>
            </Link>
            <Link to="/signup" className={`orange ${styles.btnHeader}`}>
              <img src="/static/media/search 1.png" alt="" />
              <div>
                Find a <br /> sales job
              </div>
            </Link>
          </div>
        </div>{" "}
        <img
          src="./static/media/Untitled design (20).png"
          alt=""
          className={styles.imgHeader}
        />
        <span className={styles.span}></span>
        <span className={styles.wavy}></span>
        <span className={styles.wave}></span>
      </div>
    </div>
  );
};

export const RolesComponent = () => {
  return (
    <div className={styles.RolesComponent + " bg-white"}>
      <div className={styles.title}>Choose Your Next Role here</div>
      <div className={styles.text}>
        There is nothing but career opportunities in sales! Choose within a
        variety of sales positions throughout many different industries.
      </div>
    </div>
  );
};

export const CardListComponent = ({ props }) => {
  return (
    <div className={styles.CardListComponent}>
      {props.map(({ text, title, img }, index) => {
        return (
          <div key={index} className={`${styles.card} shadow`}>
            <div
              style={{ backgroundImage: `url('${img}')` }}
              className={styles.img}
            />
            <div className={styles.title}>{title}</div>
            <div className={styles.text}>{text}</div>
          </div>
        );
      })}
      {/* MOBILE VIEW */}
      {/* {card.map(({ text, title, img }, index) => {
            return (
              <div
                key={index}
                className="col-11 mx-auto col-sm-6 col-md-4 col-lg-3 my-3 d-md-none"
              >
                <div
                  className={
                    "rounded shadow px-3 py-4 card_hover  bg-white h-100 flex-column mx-1"
                  }
                >
                  <img
                    src={img}
                    alt=""
                    className=" px-0"
                    style={{ width: "72px", height: "52.03px" }}
                  />
                  <div
                    style={{
                      wordBreak: "break-all",
                      fontFamily: "poppins",
                      fontSize: "1.3rem",
                      fontWeight: "700",
                      lineHeight: "23.03px",
                    }}
                    className="mb-3"
                  >
                    {title}
                  </div>
                  <div
                    style={{
                      lineHeight: "22.03px",

                      fontFamily: "poppins",
                      fontSize: "14.72px",
                      fontWeight: "700",
                    }}
                    className="mt-auto text-muted text_muted "
                  >
                    {text}
                  </div>
                </div>
              </div>
            );
          })} */}
    </div>
  );
};

export const CarouselComponent = () => {
  const ref = React.useRef(null);

  const next = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      ref.current.goTo(0);
    }
  };
  const prev = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      ref.current.goTo(icons.length);
    }
  };
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 4 },
    { width: 768, itemsToShow: 5 },
  ];

  return (
    <div className={styles.RolesComponent}>
      <div className={`text-center mx-auto ${styles.title}`}>
        Choose Your Industry
      </div>
      <div className={styles.carousel}>
        <Carousel
          ref={ref}
          disableArrowsOnEnd={false}
          onNextStart={next}
          onPrevEnd={prev}
          breakPoints={breakPoints}
        >
          {icons.map(({ icon }) => {
            return (
              <div
                style={{ backgroundImage: `url('${icon}')` }}
                className={styles.carouselItem}
              />
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export const AboutComponent = ({ props }) => {
  return (
    <div className={styles.AboutComponent}>
      <div className={styles.container}>
        <div className="">
          <div className={styles.title}>For Salespeople</div>
          <div className={styles.text}>
            Our platform makes it easy to search and apply for the best jobs in
            sales{" "}
          </div>
          <div className={`${styles.descMargi} ${styles.desc}`}>
            Are you a sales professional looking for your next opportunity? Look
            no further! Our job board connects you with top employers and
            exciting sales positions. With our user-friendly platform, you can
            easily browse and apply for open positions that match your skills
            and experience.
          </div>
          <Link to={"/signup"} style={{ width: "fit-content" }}>
            <button
              style={{ border: "none" }}
              className={`btnAnime ${styles.AboutButton}`}
            >
              Start Searching
            </button>
          </Link>
        </div>
        <img
          src="./static/media/Untitled design (18).png"
          alt=""
          className={styles.AboutImg}
        />
      </div>
      <div className={styles.container}>
        <img
          src="./static/media/Untitled design (19).png"
          alt=""
          className={styles.AboutImg}
        />
        <div className="">
          <div className={styles.title}>For Recruiters</div>
          <div style={{ textTransform: "lowercase" }} className={styles.text}>
            <span className="text-uppercase">e</span>xtend Your Reach to a Wide
            Network of Sales Candidates for Enhanced Talent Discovery
          </div>
          <div className={`${styles.desc}`}>
            Intoosales empowers recruiters to effortlessly post their sales
            positions, offering seamless access to a diverse pool of exceptional
            sales candidates.
          </div>
          {/* <div className={`${styles.desc} ${styles.descP}`}>
            <div className={`${styles.handle}`}></div>
            Posting a job is quick and easy with our platform. Simply create an
            account, provide the details of the position you're looking to fill,
            and submit your job listing.
          </div>{" "}
          <div className={`${styles.desc} ${styles.descP} mt-0`}>
            <div className={`${styles.handle}`}></div>
            Posting your sales job on our job board is quick and easy. Simply
            fill out our job posting form and receive candidate resumes directly
            to your email.
          </div>{" "}
          <div className={styles.descMargi}></div> */}
          {/* <div className={styles.aboutBox}>
            {[
              {
                title: "Post Your Job in Minutes",
                text: "Posting a job is quick and easy with our platform. Simply create an account, provide the details of the position you're looking to fill, and submit your job listing.",
              },
              {
                title: "Receive Resumes via Email",
                text: " Posting your sales job on our job board is quick and easy. Simply fill out our job posting form and receive candidate resumes directly to your email.",
              },
            ].map(({ title, text }, index) => {
              return (
                <div className="">
                  <div className={styles.title}>{title}</div>
                  <div className={styles.text}>{text}</div>
                </div>
              );
            })}
          </div> */}
          <Link
            to={"/createaccount"}
            style={{ width: "fit-content" }}
            className="mt-5"
          >
            <button
              style={{ border: "none" }}
              className={`btnAnime ${styles.AboutButton}`}
            >
              Start Recruiting
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export const FooterComponent = ({
  setPolicyModal,
  setTearmsModal,
  setSubscribeSuccess,
}) => {
  const [email, setEmail] = useState("");

  const subscribe = (e) => {
    e.preventDefault();
    axios
      .post(`${api_url}subscribe/`, { email: email })
      .then((res) => {
        if (!res.data.msg) {
          setSubscribeSuccess(true);
        } else {
          const notify = () => toast(res.data.msg);
          notify();
        }
      })
      .catch((err) => {
        console.log(err.response.data.email);
        const notify = () => toast.error(err.response.data.email.toString());
        notify();
      });
  };

  return (
    <footer className="w-100" style={{ backgroundColor: "#CFEFFE" }}>
      <ToastContainer />
      <div className={styles.FooterComponent}>
        <section>
          {[
            {
              title: "Contact us",
              text: " Our friendly and knowledgeable support team is available to help you with any inquiries or issues you may have. If you have any questions or need assistance, please don't hesitate to contact us via chat.",
            },
            {
              title: "About us",
              text: " Intoosales is dedicated to helping sales professionals find the right job opportunities that match their skills, experience, and career goals. We connect job seekers with top companies and  organisations across various industries.",
            },
          ].map(({ title, text }) => {
            return (
              <div className="">
                <div className={styles.title}>{title}</div>
                <div className={styles.text}>{text}</div>
              </div>
            );
          })}
          <form onSubmit={subscribe} className="">
            <div className={styles.title}>Join our community</div>
            <input
              type="email"
              name=""
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email..."
              id=""
              required
            />
            <button
              type="submit"
              style={{ width: "fit-content", border: "none" }}
              className="rounded primary_bg_color py-2 px-4 mx-sm-2 my-2 my-sm-0 cursor"
            >
              Subscribe
            </button>
            <div className={styles.footerIcons}>
              <div className={styles._tooltip}>
                <FaFacebookF className={"cursor-pointer"} />
                <div className={styles._tooltipContent}>Coming soon!</div>
              </div>
              <div className={styles._tooltip}>
                <FaInstagram className={"cursor-pointer"} />
                <div className={styles._tooltipContent}>Coming soon!</div>
              </div>
              <div className={styles._tooltip}>
                <FaYoutube className={"cursor-pointer"} />
                <div className={styles._tooltipContent}>Coming soon!</div>
              </div>
              <div className={styles._tooltip}>
                <FaLinkedin className={"cursor-pointer"} />
                <div className={styles._tooltipContent}>Coming soon!</div>
              </div>
            </div>
          </form>
        </section>
        <hr className="my-3 my-md-0" />
        <div className="col-10 mx-auto">
          <div className="d-md-flex d-block container-fluid justify-content-between align-items-center">
            <div className={styles.copy}>
              &copy;2022, All right reserved to intoosales.
            </div>
            <div
              style={{ gap: "calc(20*(clamp(10px,100vw,140000px)/1366))" }}
              className="d-md-flex d-block align-items-center"
            >
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setPolicyModal(true)}
                className={styles.copy}
              >
                Privacy Policy
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setTearmsModal(true)}
                className={styles.copy}
              >
                Terms of Service
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export const SubscribeComponent = ({
  subscribeSuccess,
  setSubscribeSuccess,
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (subscribeSuccess) {
        setSubscribeSuccess(false);
      }
    }, 6000);

    return () => {
      clearTimeout(timer);
    };
  }, [subscribeSuccess]);

  return (
    <div
      className={`${styles.SubscribeComponent} ${
        subscribeSuccess ? styles.show : ""
      }`}
    >
      <div className={styles.content}>
        <div
          style={{ backgroundImage: "url('/static/media/success.svg')" }}
          className={styles.check}
        ></div>
        <AiOutlineClose
          onClick={() => setSubscribeSuccess(false)}
          className={styles.closeIcon}
        />
        <div className={styles.title}>Well done!</div>
        <div className={styles.text}>
          You successfully subscribed to intoosales daily business mail.
        </div>
        <div
          style={{
            height: "100%",
            width: "100px",
            position: "absolute",
            borderRadius: "32px 0 0 32px",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              backgroundImage: "url('/static/media/green bubbles.svg')",
            }}
            className={styles.bubble}
          ></div>
        </div>
      </div>
    </div>
  );
};
