import { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import { FaChevronDown, FaInfoCircle, FaPlus, FaTrash } from "react-icons/fa";
import { AiOutlineUpload } from "react-icons/ai";
import { MdEditNote } from "react-icons/md";
import { Option } from "./option";

export const Question = ({
  questions,
  setQuestions,
  item,
  index,
  openQuestion,
  setOpenQuestion,
  editQuestion,
  setQuestTitle,
  editQuestionId,
  setEditQuestionId,
}) => {
  const [height, setHeight] = useState(0);
  const [correct, setCorrect] = useState("");
  const [title, setTitle] = useState("");
  const [emptyTitle, setemptyTitle] = useState(false);
  const [editVal, setEditVal] = useState("");
  const [editId, setEditId] = useState("");
  const ref = useRef();
  const answers = item.options;

  const showAnswers = () => {
    if (openQuestion !== item?.questionTitle) {
      setOpenQuestion(item.questionTitle);
    } else {
      setOpenQuestion("");
    }
  };

  const deleteQuestion = () => {
    const item = questions.filter((item, idx) => idx !== index);
    setQuestions(item);
  };

  const createAnswer = (e) => {
    e.preventDefault();
    const questionOPtion = [
      ...answers,
      { text: title, id: `${new Date()}`, is_correct: false },
    ];
    const items = questions.find((item, idx) => idx === index);
    items.options = questionOPtion;
    setTitle("");
  };

  const editAnswer = (e) => {
    e.preventDefault();
    const items = answers.find((item) => item.id === editId);
    items.text = title;
    setTitle("");
    setEditId("");
  };

  // THIS USEFFECT IS USED TO GET DIV HEIGHT
  useEffect(() => {
    setHeight(ref.current?.scrollHeight);
    window.addEventListener("resize", () => {
      setHeight(ref.current?.scrollHeight);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setHeight(0);
      });
    };
  }, [answers]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (emptyTitle) {
        setemptyTitle(false);
      }
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [emptyTitle]);

  return (
    <div key={index} className={styles}>
      <div onClick={showAnswers} className={styles.question}>
        <div className={styles.index}>{index + 1}</div>
        {item.questionTitle.length > 20 &&
        openQuestion !== item.questionTitle ? (
          <div
            style={{ whiteSpace: "nowrap" }}
            className={styles.questionTitle}
          >
            {item.questionTitle.substring(0, 20)}...
          </div>
        ) : openQuestion !== item.questionTitle ? (
          <div
            style={{ whiteSpace: "nowrap" }}
            className={styles.questionTitle}
          >
            {item.questionTitle}
          </div>
        ) : (
          ""
        )}
        <div className={styles.bar} />
        <div className={styles.iconBox}>
          add answer
          <FaChevronDown className={styles.icon} />
        </div>
      </div>

      <section
        ref={ref}
        style={{
          height: openQuestion === item.questionTitle ? `${height}px` : "0px",
        }}
        className="overflow-hidden pl-5 duration-300 w-100"
      >
        <div className="d-flex mt-3 gap-3">
          <div className={styles.questionTitle}>{item.questionTitle}</div>
          <div className="flex ml-auto gap-3 items-center justify-end">
            <FaTrash onClick={deleteQuestion} className="text-danger cursor" />
            <MdEditNote
              onClick={() => {
                setQuestTitle(item.questionTitle);
                setEditQuestionId(item.id);
              }}
              className={
                "!text-secondary cursor-pointer rounded-md gap-1 text-3xl capitalize"
              }
            />{" "}
          </div>
        </div>

        <form
          onSubmit={editId ? editAnswer : createAnswer}
          style={{ outline: emptyTitle ? "1px solid red" : "" }}
          className={styles.addInput2}
        >
          {answers.length < 4 ? (
            <input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              autoFocus
              type="text"
              placeholder="Type your answer.."
              className={styles.tagInput}
            />
          ) : (
            <input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              autoFocus
              type="text"
              readOnly
              placeholder="Option must be less than 5"
              className={styles.tagInput}
            />
          )}

          <button type="submit" className={styles.tagSubmit}>
            {editId ? <AiOutlineUpload /> : <FaPlus />}
          </button>
        </form>
        <div className="d-flex flex-column">
          {answers.map(({ text, id, is_correct }, index) => {
            const props = {
              index,
              text,
              id,
              answers,
              correct,
              setCorrect,
              setTitle,
              editId,
              setEditId,
              is_correct,
            };
            return <Option {...props} />;
          })}
        </div>
        <div className={`mt-3 ${styles.bar}`} />
      </section>
    </div>
  );
};
