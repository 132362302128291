import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { MdEditNote } from "react-icons/md";

export const Option = ({
  index,
  answers,
  text,
  id,
  correct,
  setCorrect,
  setTitle,
  editId,
  setEditId,
  is_correct,
}) => {
  const [checked, setChecked] = useState(false);
  console.log(is_correct);
  useEffect(() => {
    const edit = () => {
      if (correct === id) {
        const items = answers.find((item) => item.id === id);
        items.is_correct = true;
      } else if (is_correct) {
        const items = answers.find((item) => item.id === id);
        items.is_correct = true;
        console.log(is_correct);
      } else {
        const items = answers?.find((item) => item.id === id);
        items.is_correct = false;
      }
    };

    edit();
  }, [correct]);
  useEffect(() => {
    if (is_correct) {
      setCorrect(id);
    }
  }, []);

  return (
    <>
      {editId === id ? (
        <div style={{ position: "relative" }} className={styles.option}>
          <span className="">{index + 1}).</span>
          <div className={styles.loading}>Editing</div>
        </div>
      ) : (
        <div className={styles.option}>
          <div className={styles.optionText}>
            <span className="">{index + 1}).</span> {text}
          </div>
          {correct == id ? (
            <small className={styles.badge}>
              {correct == id ? "correct" : "incorrect"}
            </small>
          ) : (
            ""
          )}

          <div
            className={correct != id ? "" : "hidden"}
            style={{ marginLeft: "auto" }}
          ></div>
          <input
            type="checkbox"
            onClick={() => setCorrect(id)}
            checked={correct == id ? true : false}
            name=""
            id=""
          />
          <div className="">
            <button
              onClick={() => {
                setTitle(text);
                setEditId(id);
              }}
              className={
                "flex !bg-secondary rounded-md py-1 px-2 gap-1 text-white items-center capitalize text-base"
              }
            >
              <MdEditNote className="text-3xl" /> edit
            </button>
          </div>
        </div>
      )}
    </>
  );
};
