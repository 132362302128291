import React from "react";
import MetaTags from "react-meta-tags";

export const PageDescription = ({ title, content }) => {
  return (
    <div className="wrapper">
      <MetaTags>
        <title>{title}</title>
        <meta name="description" content={content} />
        {/* <meta property="og:title" content="MyApp" />
        <meta property="og:image" content="path/to/image.jpg" /> */}
      </MetaTags>
    </div>
  );
};
