import styles from "./index.module.scss";
import { useState } from "react";
import { FaBars, FaUserAlt, FaEraser, FaHome, FaBook } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Sidebar.css";
import { useGlobalContext } from "../context";
import { handleClickEvent } from "../libs/utils";

function SideBar() {
  const user = useGlobalContext();

  const [show, setShow] = useState(false);

  // LOGOUT FUNCTION
  const logout = () => {
    handleClickEvent({
      action: "sign out",
      label: "Logout",
    });
    localStorage.removeItem("subscription_end_date");
    localStorage.removeItem("key");
    localStorage.removeItem("id");
    user.setUser({
      address: "",
      applicant: null,
      email: "",
      id: "",
      name: "",
      phone: "",
    });
    user.setRecruiter({
      recruiter: null,
      email: "",
      id: "",
      name: "",
      phone: "",
    });
    window.location.href = "/";
  };

  const link = [{ text: "home" }];
  const links = [
    { icon: <FaUserAlt />, text: "profile", url: "/applicant-profile" },
    { icon: <FaHome />, text: "job feed", url: "/" },

    //{ icon: <FaStackExchange />, text: "plans", url: "" },
    // { icon: <FaEraser />, text: "billing", url: "" },
    // { icon: <FaBriefcase />, text: "applied jobs", url: "/applied-jobs" },
  ];
  const linkR = [
    { icon: <FaUserAlt />, text: "profile", url: "/recruiter-profile" },
    // { icon: <FaEraser />, text: "Billing", url: "/subscription" },
    { icon: <FaBook />, text: "My Jobs", url: "/jobs" },
  ];

  const submit = (url) => {
    setShow(false);
    user.setPath(url);
  };

  return (
    <div
      style={{ borderBottom: "1px solid #dfe1e6" }}
      className={
        user.user.email
          ? "container-fluid d-sm-none bg-white"
          : "containr bg-white d-md-none"
      }
    >
      <div className="position-relative d-flex align-items-center justify-content-between px-3 py-2 ">
        <div className="">
          <img
            src="./static/media/into sales.svg"
            style={{ width: "174px", height: "45px", objectFit: "contain" }}
            className=""
            alt=""
          />
        </div>
        <FaBars
          onClick={() => setShow(true)}
          className="mx- primary_color cursor-pointer"
          style={{ fontFamily: "roboto", fontSize: "1.3rem" }}
        />
      </div>

      {/* SIDEBAR */}
      <div className={!user.user.email && show ? "sidebar show" : "sidebar"}>
        <div className={show ? "sidebar_content true" : "sidebar_content"}>
          <div
            className="close_tab px-2 py-2 cursor-pointer"
            onClick={() => setShow(false)}
          >
            X
          </div>
          <div className="h-100 d-flex flex-column pb-5 overflow-auto">
            <div className="my-2 mt-4">
              {link.map(({ icon, text }, index) => {
                return (
                  <div
                    style={{
                      fontWeight: "700",
                      fontFamily: "roboto",
                      fontSize: "20px",
                    }}
                    onClick={() => setShow(false)}
                    key={index}
                    className="align-items-center justify-content-center d-flex white py-2 text-capitalize nav_link"
                  >
                    <div>{icon}</div>
                    <div className="mx-5 mx-lg-4">{text}</div>
                  </div>
                );
              })}
            </div>
            <div className={styles.btns}>
              <Link
                onClick={() => setShow(false)}
                to="/signin"
                style={{ textDecoration: "none" }}
                className={styles.btn}
              >
                Login for recruiters
              </Link>
              <Link
                onClick={() => setShow(false)}
                to="/login"
                style={{ textDecoration: "none" }}
                className={styles.btn}
              >
                Login for salespeople
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className={user.user.email && show ? "sidebar show" : "sidebar"}>
        <div className={show ? "sidebar_content true" : "sidebar_content"}>
          <div
            className="close_tab px-2 py-2 cursor-pointer"
            onClick={() => setShow(false)}
          >
            X
          </div>
          <div className="h-100 d-flex flex-column">
            <div
              style={{
                background: "rgba(145, 193, 232, 1)",
                borderRadius: "100%",
                width: "77px",
                height: "76px",
              }}
              className="white align-items-center d-flex justify-content-center mt-5 mb-2 mx-auto"
            >
              <div
                style={{
                  fontWeight: "500",
                  fontFamily: "roboto",
                  fontSize: "1.8rem",
                }}
              >
                <FaUserAlt />
              </div>
            </div>
            <div
              style={{
                fontWeight: "400",
                fontFamily: "roboto",
                fontSize: "1rem",
              }}
              className="white text-center"
            >
              Hello {user.user.name}
            </div>
            <div
              style={{
                fontWeight: "400",
                fontFamily: "roboto",
                fontSize: "0.8rem",
              }}
              className="white text-center mb-2"
            >
              Sales{" "}
            </div>
            <div className="my-2">
              {links.map(({ icon, text, url }, index) => {
                return (
                  <Link
                    to={url}
                    style={{
                      fontWeight: "700",
                      fontFamily: "roboto",
                      fontSize: "20px",
                    }}
                    onClick={() => submit(url)}
                    key={index}
                    className={
                      user.path === `${url}`
                        ? "active align-items-center justify-content-center d-flex white py-2 text-capitalize nav_link link"
                        : "align-items-center justify-content-center d-flex white py-2 text-capitalize nav_link link"
                    }
                  >
                    {icon}
                    <div
                      className="mx-2 mx-lg-4"
                      style={{ fontFamily: "roboto" }}
                    >
                      {text}
                    </div>
                  </Link>
                );
              })}
            </div>
            <div
              onClick={logout}
              style={{ border: "0.1rem solid rgba(255, 255, 255, 1)" }}
              className="mt-auto rounded align-items-center d-flex mx-auto px-3 py-2 my-5 cursor-pointer text-white"
            >
              <FaUserAlt
                style={{
                  fontFamily: "roboto",
                  fontSize: "20px",
                  fontWeight: "400",
                }}
              />
              <div
                style={{
                  fontFamily: "roboto",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
                className="mx-2"
              >
                Log Out
              </div>
            </div>
          </div>
          <div
            className="close_tab px-2 py-2 cursor-pointer"
            onClick={() => setShow(false)}
          >
            X
          </div>
        </div>
      </div>

      <div
        className={user.recruiter.email && show ? "sidebar show" : "sidebar"}
      >
        <div className={show ? "sidebar_content true" : "sidebar_content"}>
          <div
            className="close_tab px-2 py-2 cursor-pointer"
            onClick={() => setShow(false)}
          >
            X
          </div>
          <div className="h-100 d-flex flex-column">
            <div
              style={{
                background: "rgba(145, 193, 232, 1)",
                borderRadius: "100%",
                width: "77px",
                height: "76px",
              }}
              className="white align-items-center d-flex justify-content-center mt-5 mb-2 mx-auto"
            >
              <div
                style={{
                  fontWeight: "500",
                  fontFamily: "roboto",
                  fontSize: "1.8rem",
                }}
              >
                <FaUserAlt />
              </div>
            </div>
            <div
              style={{
                fontWeight: "400",
                fontFamily: "roboto",
                fontSize: "1rem",
              }}
              className="white text-center"
            >
              Hello Obi
            </div>
            <div
              style={{
                fontWeight: "400",
                fontFamily: "roboto",
                fontSize: "0.8rem",
                fontFamily: "roboto",
              }}
              className="white text-center mb-2"
            >
              Sales{" "}
            </div>
            <div className="my-2">
              {linkR.map(({ icon, text, url }, index) => {
                return (
                  <Link
                    to={url}
                    style={{
                      fontWeight: "700",
                      fontFamily: "roboto",
                      fontSize: "20px",
                    }}
                    onClick={() => submit(url)}
                    key={index}
                    className={
                      user.path === `${url}`
                        ? "active align-items-center justify-content-center d-flex white py-2 text-capitalize nav_link link"
                        : "align-items-center justify-content-center d-flex white py-2 text-capitalize nav_link link"
                    }
                  >
                    <div>{icon}</div>
                    <div
                      className="mx-2 mx-lg-4"
                      style={{ fontFamily: "roboto" }}
                    >
                      {text}
                    </div>
                  </Link>
                );
              })}
            </div>
            <div
              onClick={logout}
              style={{ border: "0.1rem solid rgba(255, 255, 255, 1)" }}
              className="mt-auto rounded align-items-center d-flex mx-auto px-3 py-2 my-5 cursor-pointer text-white"
            >
              <FaUserAlt
                style={{
                  fontFamily: "roboto",
                  fontSize: "20px",
                  fontWeight: "400",
                }}
              />
              <div
                style={{
                  fontFamily: "roboto",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
                className="mx-2"
              >
                Log Out
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
