import React, { useLayoutEffect } from "react";
import styles from "./index.module.scss";
import { FaChevronLeft, FaChevronRight, FaInfoCircle } from "react-icons/fa";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useGlobalContext } from "../../context";

export default function Questions({
  quiz,
  applied,
  resume,
  onClick,
  setAnswers,
  resumeModal,
  setResumeModal,
  answers,
  id,
  answerQuiz,
  setAnswerQuiz,
}) {
  const user = useGlobalContext();
  const [openModal, setOpenModal] = useState(false);
  const [questions, setQuestions] = useState(quiz);
  const [answerId, setAnswerId] = useState("");
  const [answer, setAnswer] = useState([]);
  const [activeQuestion, setActiveQuestion] = useState(quiz[0]?.text || "");

  const ref = useRef(null);
  useEffect(() => {
    const leave = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpenModal(false);
      }
    };
    document.addEventListener("mousedown", leave);
  }, [ref]);

  const createAnswer = (text, id) => {
    const item = answer.find((item) => item.question_id === id);
    if (item) {
      item.answer = text;
    } else {
      const item = { answer: text, question_id: id };
      setAnswer([...answer, item]);
    }
  };

  useEffect(() => {
    if (answer.length > 0 && answerQuiz) {
      setOpenModal(false);
      onClick(id);
    }
  }, [answers, answerQuiz]);

  return (
    <div>
      <div
        style={{ width: "fit-content" }}
        className="rounded cursor-pointer orange"
      >
        {activeQuestion ? (
          <div
            onClick={() =>
              user.user.resume ? setOpenModal(true) : setResumeModal(true)
            }
            style={{
              background: "rgba(241, 147, 6, 1)",
              borderRadius: "3px",
              pointerEvents: applied && resume === true ? "none" : "",
            }}
            className="btnHover text-white px-3 py-2"
          >
            {applied && resume === true ? "Applied" : " Click To Apply"}
          </div>
        ) : (
          <div
            onClick={() =>
              user.user.resume ? onClick(id) : setResumeModal(true)
            }
            style={{
              background: "rgba(241, 147, 6, 1)",
              borderRadius: "3px",
              pointerEvents: applied && resume === true ? "none" : "",
            }}
            className="btnHover text-white px-3 py-2"
          >
            {applied && resume === true ? "Applied" : " Click To Apply"}
          </div>
        )}
      </div>

      <article
        className={`${openModal ? styles.show : ""} ${
          styles.shareBoxContainer
        }`}
      >
        <div ref={ref} className={styles.box}>
          <div className="">
            <div className={styles.nums}>
              {questions.map(({ id, text }, index) => {
                return (
                  <div
                    style={{
                      background: activeQuestion === text ? "#4897d8" : "",
                    }}
                    onClick={() => setActiveQuestion(text)}
                    className={styles.num}
                  >
                    {index + 1}
                  </div>
                );
              })}
            </div>
            <div className="w-full border h-0 my-3" />
            <div className="">
              {questions.map(({ id, text, choices }, index) => {
                const props = { text, choices, id, createAnswer, answer };
                return (
                  <div className={activeQuestion === text ? "" : "d-none"}>
                    <SingleQuestion {...props} />
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.sticky}>
            <div
              onClick={() => {
                setAnswers(answer);
                setAnswerQuiz(true);
              }}
              style={{
                opacity: answer.length === questions.length ? "" : "0.3",
                pointerEvents: answer.length === questions.length ? "" : "none",
              }}
              className={styles.stickyBtn}
            >
              Submit Quiz
            </div>
          </div>
        </div>
      </article>
    </div>
  );
}

const SingleQuestion = ({ text, choices, createAnswer, id }) => {
  const [select, setSelect] = useState("");

  return (
    <div className={styles}>
      <div className={styles.title + " first-letter:!capitalize"}>{text}</div>
      <div className={styles.choices}>
        {choices.map(({ text }, index) => {
          const props = { text, id, index, createAnswer, select, setSelect };
          return <SingleChoice {...props} />;
        })}
      </div>
    </div>
  );
};

const SingleChoice = ({ text, id, createAnswer, select, setSelect, index }) => {
  return (
    <div
      className={styles.choice}
      onClick={() => {
        createAnswer(text, id);
        setSelect(text);
      }}
    >
      <div
        style={{ background: select === text ? "#4897d8" : "" }}
        className={styles.choiceIndex}
      >
        {index === 0
          ? "a"
          : index === 1
          ? "b"
          : index === 2
          ? "c"
          : index === 3
          ? "d"
          : "e"}
      </div>
      <div
        style={{ color: select === text ? "#4897d8" : "" }}
        className={styles.choiceTitle}
      >
        {text}
      </div>
      {/* <input type="checkbox" name="" id="" /> */}
    </div>
  );
};
