import React, { useEffect } from "react";
import { FaAngleDoubleRight, FaCheck, FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Navbar2 } from "../../navigation/Navbar";
import styles from "./index.module.scss";
import { useGlobalContext } from "../../context";
import { displaySpinner, removeSpinner } from "../recruiter/CreateAccount";
import axios from "axios";
import { useState } from "react";
import {
  PaymentSuccessModal,
  RepostSuccessModal,
} from "../../reusableComponents/paymentSuccess/paymentSuccess";
import { IoMdFastforward } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import { api_url } from "../../libs/api";
import { handleClickEvent } from "../../libs/utils";

export default function Subscription() {
  const [openShareModal, setOpenShareModal] = useState(false);
  const [openRepostModal, setOpenRepostModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [count, setCount] = useState(0);
  const plans = [
    {
      title: "Individual",
      features: [
        "Pay for each job seperately",
        "No limits on accepting applications that can be accepted",
        " Customize a questionnaire for each position",
      ],
      type: "Per-post",
      price: "29",
    },
    {
      title: "Unlimited",
      features: [
        "Create unlimited job positions",
        "No limits on the number of applications that can be accepted",
        "Customize a questionnaire for each position",
        // "1 MONTH FREE TRIAL",
        // "NO CREDIT CARD NEEDED ",
      ],
      type: "Monthly ",
      price: "89",
    },
  ];
  const { createJob, setCreateJob } = useGlobalContext();
  const key = JSON.parse(localStorage.getItem("key"));
  const pk = JSON.parse(localStorage.getItem("repost"));

  const repostJob = (plan) => {
    // setLoading(true);
    const spinner = displaySpinner(); // Display spinner

    axios
      .post(
        `${api_url}repost/${pk}/`,
        { plan: plan.toLowerCase() },
        {
          headers: {
            Authorization: `Token ${key}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        removeSpinner(spinner); // Remove spinner
        console.log(res.data);
        if (res.data.status === "redirect_for_payment") {
          localStorage.setItem("jobId", JSON.stringify(res.data.job));
          window.open(`${res.data.redirect_url}`, "", "width=600,height=700");
        } else if (res.data.msg === "Job was reposted successfully") {
          setSuccess(true);
          // localStorage.removeItem("repost");
          removeSpinner(spinner); // Remove spinner
        } else {
        }
      })
      .catch((err) => {
        removeSpinner(spinner); // Remove spinner
        console.log(err);
      });
  };

  const submit = (plan) => {
    handleClickEvent({
      action: "subscribe to new plan",
      label: "subscription",
    });
    const date = new Date();
    localStorage.setItem("plan", JSON.stringify(plan.toLowerCase()));
    if (pk) {
      repostJob(plan);
    } else {
      const spinner = displaySpinner(); // Display spinne

      axios
        .post(
          `${api_url}createjob/`,
          { ...createJob, plan: plan.toLowerCase() },

          {
            headers: {
              Authorization: `Token ${key}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          removeSpinner(spinner); // Remove spinner
          console.log(res.data);
          if (res.data.status === "redirect_for_payment") {
            localStorage.setItem("jobId", JSON.stringify(res.data.job));
            window.open(`${res.data.redirect_url}`, "", "width=600,height=700");
          } else if (
            res.data.msg.toLowerCase() === "job created successfully"
          ) {
            setSuccess(true);
          } else {
          }
          // } else if (res.data.error === "Plan type not provided.") {
          //   navigate("/subscription");
          //   setCreateJob(requestBody);
          // } else if (res.data.error === "redirect_for_payment") {
          //   navigate("/subscription");
          // } else {
          //   setSuccess(false);
          // }
        })
        .catch((err) => {
          removeSpinner(spinner); // Remove spinner
          console.log(err);
        });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const params = JSON.parse(localStorage.getItem("params"));

  useEffect(() => {
    const count = setInterval(() => {
      if (count !== "stop") {
        setCount((count) => count + 1);
      }
    }, 1000);

    return () => {
      clearInterval(count);
    };
  }, [count]);

  useEffect(() => {
    const jobId = JSON.parse(localStorage.getItem("jobId"));
    if (jobId) {
      axios
        .get(`${api_url}job/${jobId}/published-status/`, {
          headers: {
            Authorization: `Token ${key}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res.data.status);
          if (res.data.status === "active") {
            // const notify = () => toast.success("Payment successfull");
            if (pk) {
              setOpenRepostModal(true);
            } else {
              setOpenShareModal(true);
            }
          } else if (res.data.status === "failed") {
            // const notify = () => toast.success("Payment successfull");
            toast.error("Sorry! your payment failed!");
            setCount("stop");
            localStorage.removeItem("jobId");
          }
        });
    }
  }, [count]);

  return (
    <article className={`job_searc ${styles.container}`}>
      <Navbar2 />
      <ToastContainer autoClose={100000} />
      <PaymentSuccessModal
        openShareModal={openShareModal}
        setOpenShareModal={setOpenShareModal}
      />
      <RepostSuccessModal
        openRepostModal={openRepostModal}
        setOpenRepostModal={setOpenRepostModal}
      />
      <section className="mt-md-5">
        <div className="container-sm-fluid container-lg px-2">
          <p className={styles.choose_you}>Choose Your Plan </p>
          <div className={styles.desc}>
            {" "}
            Select the plan that suits your needs and get started
          </div>
          <div className="row justify-content-center gap-5">
            {plans.map(({ title, features, type, price }) => {
              return (
                <section
                  style={{ maxWidth: "460px" }}
                  className={`col-12 col-sm-8 col-md-6 ${styles.card}`}
                >
                  <div className={styles.title}>{title}</div>
                  <div className={styles.price}>
                    ${price} <span className={styles.type}>/{type}</span>
                  </div>
                  <div className={styles}>
                    {features.map((feature) => {
                      return (
                        <div className="d-flex mt-3 align-items-center ">
                          <div className="d-flex align-items-center">
                            <Icon />
                          </div>

                          <div className={styles.feature}>{feature}</div>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    onClick={() => submit(title)}
                    style={{ textAlign: "center" }}
                    className={styles.btn}
                  >
                    Choose Your Plan{" "}
                  </div>
                </section>
              );
            })}
          </div>
          <br />
          <br />
          <br />
          <hr />
          <br />
          <br />
        </div>
      </section>
      {/* SUCCESS POP-UP */}
      <div className={`${success ? "show" : ""} account_success d-flex`}>
        <div
          style={{ height: "fit-content" }}
          className="col-10 col-sm-10 col-md-6 col-lg-5 text-center bg-white mx-auto py-5 px-5 position-relative"
        >
          <div
            style={{
              width: "103.33px",
              height: "102.5px",
              border: "0.3rem solid rgba(101, 202, 117, 1)",
              borderRadius: "100%",
            }}
            className="bg-white d-flex align-items-center justify-content-center mx-auto mt-5"
          >
            <FaCheck
              style={{
                width: "41.33px",
                height: "30.75px",
                color: "rgba(101, 202, 117, 1)",
              }}
              className=""
            />{" "}
          </div>
          <div
            style={{
              fontWeight: "700",
              fontFamily: "inter",
              fontSize: "18px",
              lineHeight: "21.78px",
            }}
            className="primary_color mb-1 mt-3"
          >
            Job {!pk ? "Creation" : "Reposted"} Successful
          </div>
          <div
            style={{
              fontWeight: "400",
              fontFamily: "inter",
              fontSize: "16px",
              lineHeight: "28px",
              color: "rgba(134, 146, 166, 1)",
            }}
            className=""
          >
            Your Free Trial has been started.
          </div>

          <div
            onClick={() => {
              localStorage.removeItem("jobId");
              window.location.href = "/jobs";
            }}
            style={{
              fontWeight: "500",
              fontFamily: "inter",
              fontSize: "20px",
              lineHeight: "24.2px",
            }}
            className="primary_bg_color white rounded py-3 mb-5 mt-2 cursor-pointer "
          >
            OK
          </div>
        </div>
      </div>
    </article>
  );
}

const Icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
  >
    <path
      d="M7.5 0C3.36 0 0 3.36 0 7.5C0 11.64 3.36 15 7.5 15C11.64 15 15 11.64 15 7.5C15 3.36 11.64 0 7.5 0ZM7.5 13.5C4.1925 13.5 1.5 10.8075 1.5 7.5C1.5 4.1925 4.1925 1.5 7.5 1.5C10.8075 1.5 13.5 4.1925 13.5 7.5C13.5 10.8075 10.8075 13.5 7.5 13.5ZM11.4731 4.71938C11.1804 4.42456 10.7038 4.42371 10.41 4.7175L6.70574 8.42176C6.31575 8.81175 5.68364 8.81236 5.2929 8.42313L4.58676 7.71971C4.29429 7.42838 3.82116 7.42883 3.52926 7.72074C3.23696 8.01304 3.23696 8.48696 3.52926 8.77926L5.29289 10.5429C5.68342 10.9334 6.31658 10.9334 6.70711 10.5429L11.4712 5.77875C11.7636 5.48643 11.7644 5.01274 11.4731 4.71938Z"
      fill="#2DC937"
    />
  </svg>
);
