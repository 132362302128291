import React, { useState } from "react";
import {
  FaCopy,
  FaFacebook,
  FaGoogle,
  FaLinkedinIn,
  FaShare,
  FaTelegramPlane,
  FaTwitter,
} from "react-icons/fa";
import style from "./index.module.css";
import { useRef } from "react";
import { useEffect } from "react";

export const ShareBox = ({ url, text, title, disabled }) => {
  const [openShareModal, setopenShareModal] = useState(false);

  const twitterURL = `https://twitter.com/intent/tweet?text=${text}&url=${url}&via=yourTwitterHandle`;
  const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
  const linkedinURL = `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=${text}&source=yourWebsite`;
  const mailURL = `mailto:?subject=${title}&body=${text} - ${url}`;
  const telegramURL = `https://t.me/share/url?url=${url}&text=${text}`;

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(url)
      .then(() => alert("Link copied to clipboard!"))
      .catch((error) => console.error("Could not copy text: ", error));
  };

  const ref = useRef(null);
  useEffect(() => {
    const leave = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setopenShareModal(false);
      }
    };
    document.addEventListener("mousedown", leave);

    return () => {
      window.removeEventListener("mousedown", leave);
    };
  }, [ref]);

  // Modified click handler
  const handleClick = () => {
    if (disabled) {
      return; // Do nothing if the 'disabled' prop is true
    }
    setopenShareModal(!openShareModal);
  };

  return (
    <section>
      <div
        onClick={() => handleClick()}
        style={{
          fontFamily: "inter",
          fontSize: "14px",
          fontWeight: "500",
          borderRadius: "3px",
        }}
        className="d-flex justify-content-center primary_bg_color py-2 px-3 mx-sm-2 mt-1 mt-sm-0 label h-100"
      >
        <div className="d-flex align-items-center gap-1">
          <FaShare /> Share
        </div>
      </div>
      <article
        className={`${openShareModal ? style.show : ""} ${
          style.shareBoxContainer
        }`}
      >
        <div ref={ref} className={style.shareBox}>
          <div className={style.title}>Share this job!</div>
          <div className={style.text}>
            If you like this job share it with your friends.
          </div>
          <div className={style.icons}>
            <a href={twitterURL} target="_blank" rel="noopener noreferrer">
              <div
                style={{ background: "rgba(29, 161, 242, 0.1)" }}
                className={style.iconBox}
              >
                <FaTwitter
                  style={{ color: "rgba(29, 161, 242)" }}
                  className={style.icon}
                />
              </div>
              <div className={style.text}>Twitter</div>
            </a>
            <a href={facebookURL} target="_blank" rel="noopener noreferrer">
              <div
                style={{ background: "rgba(29, 161, 242, 0.1)" }}
                className={style.iconBox}
              >
                <FaFacebook
                  style={{ color: "rgba(29, 161, 242)" }}
                  className={style.icon}
                />
              </div>
              <div className={style.text}>Facebook</div>
            </a>
            <a href={linkedinURL} target="_blank" rel="noopener noreferrer">
              <div
                style={{ background: "rgba(29, 161, 242, 0.1)" }}
                className={style.iconBox}
              >
                <FaLinkedinIn
                  style={{ color: "rgba(29, 161, 242)" }}
                  className={style.icon}
                />
              </div>
              <div className={style.text}>LinkedIn</div>
            </a>
            <a href={mailURL}>
              <div
                style={{ background: "rgba(29, 161, 242, 0.1)" }}
                className={style.iconBox}
              >
                <FaGoogle
                  style={{ color: "rgba(29, 161, 242)" }}
                  className={style.icon}
                />
              </div>
              <div className={style.text}>Email</div>
            </a>
            <a href={telegramURL} target="_blank" rel="noopener noreferrer">
              <div
                style={{ background: "rgba(29, 161, 242, 0.1)" }}
                className={style.iconBox}
              >
                <FaTelegramPlane
                  style={{ color: "rgba(29, 161, 242)" }}
                  className={style.icon}
                />
              </div>
              <div className={style.text}>Telegram</div>
            </a>
          </div>
          <div className={style.copyBox}>
            <div className="">{url}</div>
            <FaCopy className={style.copy} onClick={handleCopyLink} />
          </div>
        </div>
      </article>
    </section>
  );
};
