import React, { useState } from "react";
import { FaInfoCircle, FaTimes } from "react-icons/fa";
import style from "./index.module.scss";
import { useRef } from "react";
import { useEffect } from "react";
import uid from "./../../utils/uid";
import { useGlobalContext } from "../../context";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLayoutEffect } from "react";
import axios from "axios";
import { api_url } from "../../libs/api";

const PaymentSuccess = ({ openShareModal, setOpenShareModal }) => {
  const { recruiter } = useGlobalContext();
  const id = uid();
  const jobId = JSON.parse(localStorage.getItem("jobId"));
  const plan = JSON.parse(localStorage.getItem("plan"));
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams("");
  // const [openShareModal, setOpenShareModal] = useState(false);

  const data = [
    {
      title: "Subscription Plan",
      value: plan?.toUpperCase(),
    },
    { title: "Payment Method", value: "PayPal Plc." },
  ];
  const ref = useRef(null);

  useEffect(() => {
    const comfirmPayment = () => {
      if (searchParams?.get("paymentId")) {
        axios
          .post(`${api_url}confirm-payment/`, {
            paymentId: searchParams?.get("paymentId"),
            PayerID: searchParams?.get("PayerID"),
            jobId: jobId,
            plan_type: `${plan}`,
          })
          .then(() => {
            console.log("");
          });
        setTimeout(() => {
          window.open("", "_self").close();
          window.close();
          console.log("");
        }, 5000);
      }
    };
    comfirmPayment();
  }, [ref]);

  useLayoutEffect(() => {
    if (searchParams?.get("payment")?.includes("success")) {
      setOpenShareModal(true);
    }
    console.log(searchParams?.get("payment"));
  }, []);

  return (
    <section>
      <article
        className={`${openShareModal ? style.show : ""} ${
          style.shareBoxContainer
        }`}
      >
        <div
          ref={ref}
          className={`${openShareModal ? style.show : ""} ${style.shareBoxImg}`}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              zIndex: "1",
              cursor: "default",
            }}
            className="position-relative"
          >
            <div ref={ref} className={style.shareBox}>
              <div className={style.tick}>
                <img src="/static/media/tick-circle.svg" alt="" />
              </div>
              <div className={style.title}>Payment Success!</div>
              <div className={style.text}>
                Your payment has been successfully done.
              </div>
              <hr />
              <div className={style.text}>Total Payment</div>
              <div className={style.price}>
                {plan?.toLowerCase() === "individual" ? "$29" : "$89"}.00
              </div>
              <br />
              <br />
              <section className={style.cards}>
                {data.map(({ title, value }) => {
                  return (
                    <main className={style.card}>
                      <div className={style.name}>{title}</div>
                      <div
                        style={{ textAlign: "start" }}
                        className={style.text}
                      >
                        {value}
                      </div>
                    </main>
                  );
                })}
              </section>
              <br />
              <br />
              {window?.location.pathname != "/plan" && (
                <div
                  onClick={() => {
                    setOpenShareModal(false);
                    navigate("/jobs");
                  }}
                  className={style.btn}
                >
                  okay
                </div>
              )}
              <br />
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};

export const PaymentSuccessModal = ({ openShareModal, setOpenShareModal }) => {
  const { recruiter } = useGlobalContext();
  const id = uid();
  const jobId = JSON.parse(localStorage.getItem("jobId"));
  const plan = JSON.parse(localStorage.getItem("plan"));
  const navigate = useNavigate();

  const data = [
    // { title: "Reference ID", value: id },
    {
      title: "Subscription Plan",
      value: plan?.toUpperCase(),
    },
    { title: "Payment Method", value: "PayPal Plc." },
    // { title: "Sender Name", value: recruiter.name },
  ];
  const ref = useRef(null);

  // useEffect(() => {
  // const jobId = JSON.parse(localStorage.getItem("jobId"));

  const leave = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      if (jobId) {
        setOpenShareModal(false);
        localStorage.removeItem("jobId");
        // window.location.href = "/jobs";
      }
    }
  };
  //   document.addEventListener("mousedown", leave);
  // }, [ref]);

  return (
    <section onClick={leave}>
      <article
        className={`${openShareModal ? style.show : ""} ${
          style.shareBoxContainer
        }`}
      >
        <div
          ref={ref}
          className={`${!openShareModal ? style.show : ""} ${
            style.shareBoxImg
          }`}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              zIndex: "1",
              cursor: "default",
            }}
            className="position-relative"
          >
            <div ref={ref} className={style.shareBox}>
              <div className={style.tick}>
                <img src="/static/media/tick-circle.svg" alt="" />
              </div>
              <div className={style.title}>Payment Success!</div>
              <div className={style.text}>
                Your payment has been successfully done.
              </div>
              <hr />
              <div className={style.text}>Total Payment</div>
              <div className={style.price}>
                {plan?.toLowerCase() === "individual" ? "$29" : "$89"}.00
              </div>
              <br />
              <br />
              <section className={style.cards}>
                {data.map(({ title, value }) => {
                  return (
                    <main className={style.card}>
                      <div className={style.name}>{title}</div>
                      <div
                        style={{ textAlign: "start" }}
                        className={style.text}
                      >
                        {value}
                      </div>
                    </main>
                  );
                })}
              </section>
              <br />
              <br />
              <div
                onClick={() => {
                  setOpenShareModal(false);
                  localStorage.removeItem("jobId");

                  window.location.href = "/jobs";
                }}
                className={style.btn}
              >
                okay
              </div>
              <br />
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};

export const RepostSuccessModal = ({ openRepostModal, setOpenRepostModal }) => {
  const { recruiter } = useGlobalContext();
  const id = uid();
  const jobId = JSON.parse(localStorage.getItem("jobId"));
  const plan = JSON.parse(localStorage.getItem("plan"));
  const navigate = useNavigate();

  const ref = useRef(null);

  // useEffect(() => {
  // const jobId = JSON.parse(localStorage.getItem("jobId"));

  const leave = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      if (jobId) {
        setOpenRepostModal(false);
        window.location.href = "/jobs";
        localStorage.removeItem("jobId");
        localStorage.removeItem("repost");
      }
    }
  };
  //   document.addEventListener("mousedown", leave);
  // }, [ref]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     window.open("", "_self").close();
  //     window.close();
  //   }, 5000);
  // }, []);

  return (
    <section onClick={leave}>
      <article
        className={`${openRepostModal ? style.show : ""} ${
          style.shareBoxContainer
        }`}
      >
        <div
          ref={ref}
          className={`${openRepostModal ? style.show : ""} ${
            style.shareBoxImg
          }`}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              zIndex: "1",
              cursor: "default",
            }}
            className="position-relative"
          >
            <div ref={ref} className={style.shareBox}>
              <div className={style.tick}>
                <img src="/static/media/tick-circle.svg" alt="" />
              </div>
              <div className={style.title}>Payment Success!</div>

              <div className={style.text}>
                Your job has been successfully reposted
              </div>
              <br />
              <div
                onClick={() => {
                  setOpenRepostModal(false);
                  window.location.href = "/jobs";
                  localStorage.removeItem("jobId");
                  localStorage.removeItem("repost");
                }}
                style={{
                  width: "fit-content",
                  fontSize: "18px",
                  cursor: "pointer",
                }}
                className={"bg-success mx-auto text-white px-5 py-2"}
              >
                CLOSE
              </div>
              <br />
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};

export default PaymentSuccess;
