import React from "react";
// import Select from "react-select";
import {
  inputs,
  jobDesc_placeholder,
  qualify_placeholder,
  req_placeholder,
} from "../../libs/mockdata";
import { Dropdown3 } from "../../components/Dropdown3";
import Select from "../../components/select";

export default function Inputs({ error, form, setForm }) {
  return (
    <>
      {inputs.map(({ name, title, placeholder, value, subtext, data }, idx) => {
        if (idx < 2) {
          return (
            <div key={idx} className="col-12 col-sm-6 my-2">
              <div
                style={{
                  fontFamily: "inter",
                  fontSize: "15px",
                  fontWeight: "600",
                }}
                className="black mb-1 text-capitalize px-2"
              >
                {title}*
              </div>
              <input
                type="text"
                required
                value={form[name]}
                onChange={(e) => setForm({ ...form, [name]: e.target.value })}
                placeholder={placeholder}
                style={{
                  fontFamily: "inter",
                  fontSize: "1rem",
                  fontWeight: "400",
                  color: "#6B778C",
                  background: "white",
                  border: `2px solid ${
                    error && !form[name] ? "red" : "#dfe1e6"
                  }`,
                  borderRadius: "4px",
                }}
                className="py-2 px-2 w-100 text-start text-capitalize  login_input"
              />
              {error && !form[name] && (
                <small className="text-danger">Please enter {title}</small>
              )}
            </div>
          );
        }
        if (idx > 1 && idx < 9) {
          return (
            <div key={idx} className="col-12 col-sm-6 my-2">
              <div
                style={{
                  fontFamily: "inter",
                  fontSize: "15px",
                  fontWeight: "600",
                }}
                className="black mb-1 text-capitalize px-2"
              >
                {title}*
              </div>
              <Select
                list={data}
                error={error}
                name={name}
                form={form}
                setForm={setForm}
              />

              {error && !form[name] && (
                <small className="text-danger">Please enter {title}</small>
              )}
            </div>
          );
        }
        if (idx == 9) {
          return (
            <div key={idx} className="col-12 col-sm-6 my-2">
              <div
                style={{
                  fontFamily: "inter",
                  fontSize: "15px",
                  fontWeight: "600",
                }}
                className="black mb-1 text-capitalize px-2"
              >
                {title}*
              </div>
              <Dropdown3
                defaultValue={form[name]}
                state={form["state"]}
                error={error}
                form={form}
                setForm={setForm}
                city={form[name]}
                border={"2px solid #dfe1e6"}
              />
              {error && !form[name] && (
                <small className="text-danger">Please enter {form[name]}</small>
              )}
            </div>
          );
        }
      })}
    </>
  );
}
