import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaEdit, FaUserTie } from "react-icons/fa";
import { IoIosCreate } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../context";
import { displaySpinner } from "../recruiter/CreateAccount";
import { removeSpinner } from "../recruiter/CreateAccount";
import { Navbar2 } from "../../navigation/Navbar";
import "./index.css";
import { PageDescription } from "../../reusableComponents/pageDescription";
import RepostModal from "../../reusableComponents/repostModal/Index";
import PaymentSuccess from "../../reusableComponents/paymentSuccess/paymentSuccess";
import { ToastContainer, toast } from "react-toastify";
import { user_key } from "../../libs/mockdata";
import { api_url, api_host } from "../../libs/api";
import { handleClickEvent } from "../../libs/utils";

export default function Index() {
  const { myJobs, setMyJobs, ediT, recruiter, setCreateJob } =
    useGlobalContext();
  const [repost, setRepost] = useState(false);
  const [repostId, setRepostId] = useState("");
  const [repostItem, setRepostItem] = useState({});
  const [jobStatus, setjobStatus] = useState({
    active: "",
    inactive: "",
    applicants: "",
  });
  const [loading, setLoading] = useState(false);
  const [questionaire, setQuestionaire] = useState({
    state: false,
    questions: [],
    score: "",
  });

  const navigate = useNavigate();

  const repostJob = (pk) => {
    setLoading(true);
    const elements = displaySpinner(); // Display spinner
    handleClickEvent({
      action: "repost job",
      label: "repost",
    });
    axios
      .post(
        `${api_url}repost/${pk}/`,
        {},
        {
          headers: {
            Authorization: `Token ${user_key}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        removeSpinner(elements); // Remove spinner
        setLoading(false);
        console.log(res.data);
        if (res.data.error === "Plan type not provided.") {
          navigate("/subscription");
          localStorage.setItem("repost", JSON.stringify(pk));
          setCreateJob({});
        } else {
          setRepost(true);
        }
      })
      .catch((err) => {
        removeSpinner(elements); // Remove spinner
        setLoading(false);
        console.log(err);
      });
  };

  const job = JSON.parse(localStorage.getItem("myJobs"));
  const key = JSON.parse(localStorage.getItem("key"));
  const id = JSON.parse(localStorage.getItem("id"));
  const date = new Date();
  const selectedQuestion = myJobs?.find((item) => item.id == repostId);

  const editJobOnClick = (pk) => {
    handleClickEvent({
      action: "Edit job",
      label: "Edit",
    });
    const elements = displaySpinner(); // Display spinner
    console.log(questionaire.questions);
    const results = selectedQuestion.questions.filter(
      ({ id }) => !questionaire.questions.some((item) => item.id === id)
    );
    axios
      .put(
        `${api_url}job/${pk}/`,
        {
          questions_to_delete: results.map(({ id }) => id),
          id: repostItem.id,
          type: repostItem.type,
          salestype: repostItem.salestype,
          company: repostItem.company,
          industry: repostItem.industry,
          role: repostItem.role,
          title: repostItem.title,
          salary: repostItem.salary,
          description: repostItem.description,
          experience: repostItem.experience,
          country: repostItem.country,
          state: repostItem.state,
          city: repostItem.city,
          requirements: repostItem.requirements,
          qualifications: repostItem.qualifications,
          email: repostItem.email,
          creation_date: repostItem.creation_date,
          recruiter: id,
          start_date: repostItem.start_date,
          end_date: repostItem.end_date,
          questionnaire_required:
            questionaire.questions.length > 0 ? true : false,
          questions: questionaire.questions,
          passing_score: questionaire.score,
        },
        {
          headers: {
            Authorization: `Token ${key}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        removeSpinner(elements); // Remove spinner
        repostJob(repostId);
        console.log(res.data);
      })
      .catch((err) => {
        removeSpinner(elements); // Remove spinner
        console.log(err);
      });
  };

  useEffect(() => {
    axios
      .get(`${api_url}jobs/`, {
        headers: { Authorization: `Token ${key}` },
      })
      .then((res) => {
        setMyJobs(res.data.reverse());
        console.log(res.data.reverse());
        localStorage.setItem("myJobs", res.data.length);
      });

    axios
      .get(`${api_url}recruiter/${id}/statistics/`, {
        headers: { Authorization: `Token ${key}` },
      })
      .then((res) => {
        setjobStatus({
          active: res.data.active_jobs,
          inactive: res.data.inactive_jobs,
          applicants: res.data.applications_received,
        });
        console.log(res.data);
      });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setRepost(false);
      if (repost) {
        window.location.reload();
      }
    }, 3000);
  }, [repost]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const props = { questionaire, setQuestionaire };
  return (
    <div style={{ zIndex: "0" }} className="job_searc">
      <PageDescription
        title={"My jobs"}
        content={
          "Choose within a variety of sales positions throughout many different industries."
        }
      />
      <Navbar2 />

      <div
        style={{ minHeight: "100vh" }}
        className={` container-fluid bg-white py-5`}
      >
        <div
          className="d-none d-md-flex flex-column
           primary_bg_color"
          style={{
            position: "fixed",
            zIndex: "3",
            left: "0",
            top: "61.99px",
            height: "100vh",
            width: "17%",
          }}
        >
          <div
            style={{
              fontWeight: "700",
              fontSize: "2rem",
              margin: "2rem",
              fontFamily: "clash display",
              textShadow:
                "-1px -1px 1px rgba(255, 255, 255, 0.2),  1px 1px 1px rgba(0, 0, 0, 0.6)",
            }}
            className="white text-center "
          >
            JOB INFO
          </div>

          <div
            style={{
              fontWeight: "400",
              fontSize: "1rem",
              fontFamily: "poppins",
            }}
            className="white d-flex align-items-center justify-content-between px-3 gap-3 mt-4 text capitalize"
          >
            Active jobs
            <div styles={{ background: "green" }} className="talkbubble">
              {jobStatus.active}
            </div>
          </div>
          <div
            style={{
              fontWeight: "400",
              fontSize: "1rem",
              fontFamily: "poppins",
            }}
            className="white d-flex align-items-center justify-content-between px-3 gap-3 my-4 text capitalize"
          >
            Inactive jobs
            <div styles={{ background: "green" }} className="talkbubble2">
              {jobStatus.inactive}
            </div>
          </div>
          <div
            style={{
              fontWeight: "400",
              fontSize: "1rem",
              fontFamily: "poppins",
            }}
            className="white d-flex align-items-center justify-content-between px-3 gap-3 text capitalize"
          >
            Applications
            <div styles={{ background: "green" }} className="talkbubble3">
              {jobStatus.applicants}
            </div>
          </div>
        </div>
        <div className="job-margin">
          <div className="row h-100 justify-content-lg-end shift">
            <div className="col-lg-12">
              <div className="d-flex align-items-center justify-content-between mb-4 col-12 col-md-11 mx-auto">
                <div
                  style={{
                    fontFamily: "inter",
                    fontSize: "30px",
                    fontWeight: "700",
                  }}
                  className="black d-flex align-items-center gap-2"
                >
                  My Jobs
                  <div
                    style={{
                      color: "#f19306",
                      width: "fit-content",
                      height: "fit-content",
                    }}
                    className="smallBadge orange"
                  >
                    {myJobs.length}
                  </div>
                </div>
                <Link
                  style={{
                    fontFamily: "inter",
                    fontSize: "14px",
                    fontWeight: "500",
                    borderRadius: "6px",
                  }}
                  to={myJobs.length > 0 ? "/create-job" : "/"}
                  className=" cursor-pointer primary_bg_color py-2 px-2 label link"
                >
                  + Add another job
                </Link>
              </div>
              {job === 0 && (
                <div className="col-12">
                  <div className="gif mx-auto"></div>
                  <div
                    style={{
                      fontSize: "22px",
                      fontWeight: "600",
                      fontFamily: "poppins",
                    }}
                    className="text-center"
                  >
                    You Have not posted any job yet!
                  </div>
                  <div className=" d-flex mt-3">
                    <Link
                      to="/"
                      style={{ width: "fit-content" }}
                      className=" cursor-pointer mx-auto primary_bg_color rounded py-2 d-flex gap-2 px-3 label link"
                    >
                      <IoIosCreate style={{ fontSize: "1.5rem" }} />
                      <div
                        style={{
                          fontFamily: "inter",
                          fontSize: "17px",
                          fontWeight: "500",
                          borderRadius: "6px",
                        }}
                        className=""
                      >
                        Post job
                      </div>
                    </Link>
                  </div>
                </div>
              )}
              {myJobs.map((fields, index) => {
                const { id, questions } = fields;
                const pk = id;
                if (fields.status != "inactive") {
                  return (
                    <div
                      style={{
                        boxShadow: "5px 5px 7px #d5d5d5, -5px -5px 7px #ffffff",
                      }}
                      className=" col-12 col-md-11 mx-auto"
                      key={index}
                    >
                      <div className="jobs_card align-items-start align-items-md-center justify-content-between py-4 px-3 my-3">
                        <div className="d-flex align-items-center ">
                          <img
                            src={`${api_host}/${fields.image}`}
                            alt="job image"
                            style={{
                              background: "rgba(0, 0, 0, .1)",
                              marginRight: "5px",
                            }}
                            className="rounded"
                            width="94.72px"
                            height="97.67px"
                          />
                          <div className="mx-2">
                            <div
                              style={{
                                fontFamily: "poppins",
                                fontSize:
                                  "calc(24*(clamp(1000px,100vw,1366px)/1366))",
                                fontWeight: "500",
                                lineHeight:
                                  "calc(30*(clamp(1000px,100vw,1366px)/1366))",
                                color: "#000",
                              }}
                              className="text-capitalize"
                            >
                              {fields.title}
                            </div>
                            <div
                              style={{
                                fontFamily: "inter",
                                fontSize:
                                  "calc(20.8*(clamp(1000px,100vw,1366px)/1366))",
                                fontWeight: "lighter",
                                color: "#A4AFC0",
                              }}
                              className=""
                            >
                              {fields.industry}
                            </div>
                            <div
                              style={{
                                fontFamily: "poppins",
                                fontSize: "small",
                                fontWeight: "400",
                                color: "#f19306",
                                background: "rgba(6, 166, 0, 0.0949)",
                                width: "fit-content",
                              }}
                              className=" px-2 mt-3 py-1 smallBadge rounded text-center"
                            >
                              {fields.timeleft}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-md-center ">
                          <Link
                            onClick={() => ediT(pk)}
                            to="/edit-jobs"
                            style={{ textDecoration: "none" }}
                          >
                            <div
                              style={{
                                background: "rgba(241, 147, 6, 1)",
                                borderRadius: "3px",
                              }}
                              className="d-flex align-items-center white mx-2 py-2 px-2 opacity"
                            >
                              <FaEdit />
                              <div className="mx-1 d-none d-md-block">Edit</div>
                            </div>
                          </Link>
                          <div
                            style={{
                              background: "rgba(241, 147, 6, 1)",
                              borderRadius: "3px",
                            }}
                            className={`align-items-center white px-2 py-1 py-md-2 bg-success text-capitalize opacity cursor-pointer`}
                          >
                            active
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
              {myJobs.map((fields, index) => {
                const { id, questions } = fields;
                const pk = id;
                if (fields.status === "inactive") {
                  return (
                    <div className=" col-12 col-md-11 mx-auto" key={index}>
                      <div
                        style={{
                          boxShadow:
                            "5px 5px 7px #d5d5d5, -5px -5px 7px #ffffff",
                        }}
                        className="jobs_card justify-content-between py-4 px-3 my-3"
                      >
                        {repostId === id ? (
                          <RepostModal
                            repostId={repostId === id ? repostId : ""}
                            setRepostId={setRepostId}
                            postJob={() => repostJob(pk)}
                            {...props}
                            editJobOnClick={editJobOnClick}
                          />
                        ) : (
                          ""
                        )}

                        <div className="d-flex align-items-center ">
                          <img
                            src={`${api_host}/${fields.image}`}
                            alt="job image"
                            style={{
                              background: "rgba(0, 0, 0, .1)",
                              marginRight: "5px",
                            }}
                            className="rounded"
                            width="94.72px"
                            height="97.67px"
                          />
                          <div className="mx-2">
                            <div
                              style={{
                                fontFamily: "poppins",
                                fontSize:
                                  "calc(24*(clamp(1000px,100vw,1366px)/1366))",
                                fontWeight: "500",
                                color: "#000",
                              }}
                              className=""
                            >
                              {fields.title}
                            </div>
                            <div
                              style={{
                                fontFamily: "inter",
                                fontSize:
                                  "calc(20.8*(clamp(1000px,100vw,1366px)/1366))",
                                fontWeight: "400",
                                color: "#A4AFC0",
                              }}
                              className=""
                            >
                              {fields.industry}
                            </div>
                            <div
                              style={{
                                fontFamily: "poppins",
                                fontSize: "small",
                                fontWeight: "400",
                                color: "#f19306",
                                background: "rgba(6, 166, 0, 0.0949)",
                                width: "fit-content",
                              }}
                              className="mt-3 px-2 py-1 smallBadge rounded text-center"
                            >
                              {fields.timeleft}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center ">
                          <Link
                            onClick={() => ediT(pk)}
                            to="/edit-jobs"
                            style={{ textDecoration: "none" }}
                          >
                            <div
                              style={{
                                background: "rgba(241, 147, 6, 1)",
                                borderRadius: "3px",
                              }}
                              className="d-flex align-items-center white mx-2 py-2 px-2 opacity"
                            >
                              <FaEdit />
                              <div className="mx-1 d-none d-md-block">Edit</div>
                            </div>
                          </Link>
                          <div
                            onClick={() => {
                              setRepostId(pk);
                              setQuestionaire({
                                state: fields.questionnaire_required,
                                questions: fields.questions,
                                score: fields.passing_score,
                              });
                              setRepostItem(fields);
                            }}
                            style={{
                              background: "rgba(241, 147, 6, 1)",
                              borderRadius: "3px",
                            }}
                            className={`${
                              fields.status === "inactive"
                                ? "bg-danger"
                                : "d-none"
                            } align-items-center white px-2 py-1 py-md-2 text-capitalize opacity cursor-pointer`}
                          >
                            repost
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
      {/*REPOST POPUP*/}
      <div className={`${repost ? "show" : ""} delete_success d-flex`}>
        <div
          style={{ height: "fit-content" }}
          className={`${repost ? "show" : ""} delete_content d-flex`}
        >
          Job reposted successfully!
        </div>
      </div>
    </div>
  );
}
