export const terms = [
  {
    title: `INTRODUCTION`,
    text: [
      {
        text: `The purpose of the website is to provide a platform for employers, recruiters, and candidates to
connect and facilitate the job search process in the field of sales. By using the website, users
agree to comply with the terms and conditions set forth in these Terms of Use, as well as any
applicable laws and regulations. The website reserves the right to modify these terms and
conditions at any time, and users are responsible for reviewing the Terms of Use periodically to
ensure compliance with any updates or changes.
`,
        subtext: [],
      },
    ],
  },
  {
    title: `USER REGISTRATION AND ACCOUNT`,
    text: [
      {
        text: `To register for an account on the website, employers, recruiters, and candidates must provide
certain personal information, including name, email address, and relevant job history or
qualifications. Employers and recruiters may be required to provide additional information to
verify their business or organization, such as a business license or tax ID number.`,
        subtext: [
          `Guidelines for creating a username and password
`,
        ],
      },
      {
        text: `Users are responsible for creating a username and password that are unique and secure.
Usernames and passwords must not violate any applicable laws or regulations, and should not
contain any offensive or inappropriate content. Users are also responsible for keeping their login
information secure and confidential, and should not share their username or password with
anyone else. The website reserves the right to disable or delete user accounts at any time if there
is evidence of a breach of security or unauthorized use.
`,
        subtext: [
          `Information about the responsibilities of users to keep their login information secure:
`,
        ],
      },
      {
        text: `Users are solely responsible for maintaining the confidentiality of their login information and for
any activity that occurs on their account. Users should take reasonable steps to protect their
account, including choosing a secure password, using two-factor authentication if available, and
logging out of their account when they are finished using the website. The website is not
responsible for any damages or losses that may result from a breach of security or unauthorized
access to a user's account.`,
        subtext: [],
      },
    ],
  },
  {
    title: `POSTING JOBS AND APPLYING FOR JOBS`,
    text: [
      {
        text: `Employers and recruiters can post job openings on the website by creating a job listing that
includes a job description, required qualifications, and application instructions. The website may
charge a fee for each job posting, or offer a subscription service for multiple postings. The
website reserves the right to review and approve all job postings before they are published on the
website, and may reject any postings that violate the website's policies or applicable laws and
regulations.`,
        subtext: [],
      },
      {
        text: `Candidates can apply for jobs on the website by submitting a resume or CV through the website's
online application system. Recruiters will receive the resume or CV directly to their email
address, and may contact candidates directly for further screening or interviews. The website
does not guarantee that candidates will be contacted by recruiters or that they will be offered a
job as a result of using the website's services.`,
        subtext: [],
      },
    ],
  },
  {
    title: `PAYMENT AND REFUNDS`,
    text: [
      {
        text: `Employers and recruiters may be required to pay a fee to post jobs or subscribe to the website's
services. Fees may be paid on a one-time basis for each job posting, or on a recurring basis for a
subscription service. The website may accept payment through various payment methods, such
as credit card or PayPal`,
        subtext: [],
      },
      {
        text: `If a user does not get results from their job posting, they may be eligible for a refund, subject to
the website's refund policy. The refund policy may vary depending on the specific service or
subscription purchased, and may be subject to certain limitations or conditions. The website
reserves the right to modify the refund policy at any time.`,
        subtext: [],
      },
      {
        text: `It's important to note that any fees charged by the website for job postings or subscriptions
should be clearly disclosed to users before they agree to use the website's services. Additionally,
any refund policy should be fair and reasonable, and should not be designed to unfairly benefit
the website at the expense of users.`,
        subtext: [],
      },
    ],
  },
  {
    title: `USER CONTENT AND CONDUCT`,
    text: [
      {
        text: `Users of the website are responsible for any content they post on the site, including job postings,
resumes, and other user-generated content. Users should ensure that any content they post is
accurate, truthful, and does not violate any applicable laws or regulations. The website reserves
the right to review and remove any content that violates its policies or that it deems inappropriate
or offensive.
`,
        subtext: [],
      },
      {
        text: `Users must also comply with the following guidelines when using the website:`,
        subtext: [
          `Do not post content that is discriminatory, defamatory, or harassing to other users`,
          `Do not engage in spamming or other activities that may disrupt the website's services or
operations.`,
          `Do not post content that contains viruses, malware, or other harmful code`,
        ],
      },
      {
        text: `The website reserves the right to suspend or terminate user accounts that violate these guidelines
or that engage in other inappropriate or harmful activities.`,
        subtext: [],
      },
    ],
  },
  {
    title: `INTELLECTUAL PROPERTY`,
    text: [
      {
        text: `All intellectual property on the website, including logos, trademarks, and copyrighted content, is
owned by the website or its respective owners. Users may not use or reproduce any intellectual
property on the website without the express written permission of the website or its owners.`,
        subtext: [],
      },
      {
        text: `Users of the website retain ownership of any user-generated content they post on the site, such as
job postings or resumes. However, by posting content on the website, users grant the website a
non-exclusive, royalty-free license to use, modify, and distribute that content for the purposes of
operating the website and providing its services to users. Users also warrant that they have the
right to post any content they submit to the website, and that such content does not infringe on
the rights of any third parties.`,
        subtext: [],
      },
      {
        text: `The website will respond to notices of alleged copyright infringement in accordance with
applicable laws and regulations, and may remove any infringing content at its discretion`,
        subtext: [],
      },
    ],
  },
  {
    title: `TERMINATION`,
    text: [
      {
        text: `The website may terminate user accounts at any time, with or without cause, if users violate the
Terms of Use or engage in other inappropriate or harmful activities. Examples of such activities
may include, but are not limited to:`,
        subtext: [
          `Posting content that violates the website's policies or that is illegal or harmful to others`,
          `Engaging in fraud or misrepresentation in connection with the website's services`,
          `Failing to pay fees or charges associated with the website's services.`,
          `Providing false or misleading information during the registration process.`,
          `Breaching the website's security measures or attempting to access or use the site in an unauthorized manner`,
        ],
      },
      {
        text: `The website may also terminate user accounts in response to requests from law enforcement or
other government agencies, or to comply with applicable laws or regulations`,
        subtext: [],
      },
    ],
  },
  {
    title: `DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY`,
    text: [
      {
        text: `THE WEBSITE PROVIDES ITS SERVICES ON AN "AS IS" AND "AS AVAILABLE"
BASIS, WITHOUT ANY WARRANTIES OR GUARANTEES OF ANY KIND, EXPRESS OR
IMPLIED. THE WEBSITE DOES NOT WARRANT THAT ITS SERVICES WILL BE
UNINTERRUPTED, ERROR-FREE, OR MEET THE NEEDS OR EXPECTATIONS OF ANY
USER. THE WEBSITE IS NOT RESPONSIBLE FOR ANY DAMAGES OR LOSSES THAT
MAY RESULT FROM THE USE OF ITS SERVICES, INCLUDING BUT NOT LIMITED TO:`,
        subtext: [
          `LOST PROFITS OR REVENUE`,
          `DATA LOSS OR CORRUPTION`,
          `BUSINESS INTERRUPTIONS`,
          `PERSONAL INJURY OR PROPERTY DAMAGE`,
          `ANY OTHER DAMAGES OR LOSSES, WHETHER DIRECT, INDIRECT, OR INCIDENTAL`,
        ],
      },
      {
        text: `USERS OF THE WEBSITE AGREE TO USE THE WEBSITE'S SERVICES AT THEIR OWN
RISK, AND AGREE TO INDEMNIFY AND HOLD HARMLESS THE WEBSITE AND ITS
AFFILIATES, OFFICERS, AGENTS, AND EMPLOYEES FROM ANY CLAIMS,
DAMAGES, OR LOSSES ARISING FROM THEIR USE OF THE WEBSITE'S SERVICES.`,
        subtext: [],
      },
    ],
  },
  {
    title: ` GOVERNING LAW AND DISPUTE RESOLUTION`,
    text: [
      {
        text: `These Terms of Use are governed by and construed in accordance with the laws of the
jurisdiction where the website is based. Any disputes or claims arising out of or relating to these
Terms of Use or the website's services shall be resolved through arbitration in accordance with
the rules of the applicable arbitration association. Any arbitration shall take place in the
jurisdiction where the website is based, and the decision of the arbitrator shall be final and
binding on all parties`,
        subtext: [],
      },
    ],
  },
  {
    title: `USER RESPONSIBILITIES`,
    text: [
      {
        text: `Users of the website are responsible for providing accurate and truthful information when
registering for an account or using the website's services. Users must not engage in any illegal
activities while using the website, including but not limited to:`,
        subtext: [
          `Harassment or discrimination of other users.`,
          `Posting content that infringes on the intellectual property rights of others.`,
          `Attempting to access or use the website's services in an unauthorized manner`,
          `Engaging in any other activities that violate applicable laws or regulations.`,
        ],
      },
      {
        text: `Users of the website must also respect the intellectual property rights of others, and must not
reproduce, distribute, or use any content on the website without the express permission of the
content owner.`,
        subtext: [],
      },
    ],
  },
  {
    title: `INDEMNIFICATION`,
    text: [
      {
        text: `Users of the website agree to indemnify and hold harmless the website and its affiliates, officers,
agents, and employees from any claims, damages, or losses arising from their use of the
website's services or any violation of these Terms of Use. This includes, but is not limited to,
claims arising from:`,
        subtext: [
          `Any content posted by the user on the website that infringes on the rights of others or
violates applicable laws or regulations.`,
          `Any breach of security or unauthorized use of the user's account.`,
          `Any violation of these Terms of Use or the website's policies.`,
        ],
      },
      {
        text: `Users of the website agree to cooperate with the website in the defense of any claims, and to
provide any necessary information or assistance to the website in connection with such claims.
The website reserves the right to assume the exclusive defense and control of any matter subject
to indemnification by the user, and the user agrees to not settle any such matter without the
express written consent of the website.`,
        subtext: [],
      },
    ],
  },
  {
    title: `MODIFICATIONS TO THE SITE AND SERVICES
`,
    text: [
      {
        text: `The website reserves the right to modify or discontinue any aspect of its services at any time,
with or without notice to users. This includes, but is not limited to:`,
        subtext: [
          `Changes to the website's functionality or user interface.`,
          `Changes to the fees or charges associated with the website's services.`,
          `Modifications to the website's policies or Terms of Use.`,
        ],
      },
      {
        text: `The website is not liable to users or any third parties for any damages or losses that may result
from such modifications or discontinuations.`,
        subtext: [],
      },
    ],
  },
  {
    title: `DISCLAIMER OF ENDORSEMENT`,
    text: [
      {
        text: `The website does not endorse any employer, recruiter, or candidate, and is not responsible for
any actions or conduct of users of the website. Users should exercise caution when interacting with others on the website, and should conduct their own research and due diligence before
entering into any agreements or transactions with other users.`,
        subtext: [],
      },
      {
        text: `The website makes no representations or warranties regarding the accuracy, completeness, or
reliability of any user-generated content on the website, including job postings or resumes. Users
of the website agree to use the website's services at their own risk, and acknowledge that the
website is not responsible for any damages or losses that may result from their use of the
website's services.`,
        subtext: [],
      },
    ],
  },
  {
    title: `CONFIDENTIALITY`,
    text: [
      {
        text: `Users of the website may have access to confidential information of other users, such as personal
contact information or sensitive job-related details. Users agree to maintain the confidentiality of
such information and to not disclose or share it with any third parties without the express written
consent of the affected user.
`,
        subtext: [],
      },
    ],
  },
  {
    title: `FORCE MAJEURE`,
    text: [
      {
        text: `The website is not liable for any failure or delay in performing its obligations under these Terms
of Use if such failure or delay is caused by circumstances beyond its reasonable control, such as
acts of God, war, terrorism, natural disasters, or other events outside of its control.
`,
        subtext: [],
      },
    ],
  },
  {
    title: `WAIVER`,
    text: [
      {
        text: `The website's failure to enforce any provision of these Terms of Use shall not be deemed a
waiver of such provision or of the right to enforce such provision at a later time.
`,
        subtext: [],
      },
    ],
  },
  {
    title: `ENTIRE AGREEMENT`,
    text: [
      {
        text: `These Terms of Use constitute the entire agreement between the website and the user regarding
the use of the website's services, and supersede any prior or contemporaneous agreements or
understandings, whether written or oral`,
        subtext: [],
      },
    ],
  },
  {
    title: `SEVERABILITY`,
    text: [
      {
        text: `If any provision of these Terms of Use is found to be invalid or unenforceable, such provision
shall be deemed to be severed from these Terms of Use, and the remaining provisions shall
remain in full force and effect`,
        subtext: [],
      },
    ],
  },
  {
    title: `ASSIGNMENT`,
    text: [
      {
        text: `The website may assign these Terms of Use to any third party without the consent of the user.
Users may not assign these Terms of Use or any rights or obligations under these Terms of Use
without the express written consent of the website.
`,
        subtext: [],
      },
    ],
  },
  {
    title: `CONTACT US`,
    text: [
      {
        text: `If you have any questions, concerns, or feedback regarding these Terms of Use or the website's
services, you may contact us `,
        subtext: [],
      },
      {
        text: `We will make our best efforts to respond to your inquiry in a timely manner.`,
        subtext: [],
      },
    ],
  },
];

export const policies = [
  {
    title: `I. INTRODUCTION`,
    text: [
      {
        text: "At intoosales, we are committed to protecting the privacy of our users. This Privacy Policy describes how we collect, use, and protect the personal information we collect from users of our job board website.",
        subtext: [],
      },
      { text: "A. Purpose of the policy", subtext: [] },
      {
        text: "The purpose of this Privacy Policy is to provide clear and transparent information to our users about how their personal information is collected, used, and protected. We want to help our users understand their privacy rights and provide them with the tools they need to manage theirinformation",
        subtext: [],
      },
      {
        text: "B. Information collected and how it will be used",
        subtext: [],
      },
      {
        text: "We collect personal information from users who register on our website or submit job applications. This information includes, but is not limited to, names, email addresses, phone numbers, resumes, and job postings. We use this information to match job seekers with potential employers/recruiters and to facilitate communication between the parties. We may also use the information for marketing purposes, such as sending emails about new job postings or website updates.",
        subtext: [],
      },
      {
        text: "We may also collect non-personal information, such as website usage statistics, to improve our website and provide a  better user experience. We may use cookies or other tracking technologies to collect this information.",
        subtext: [],
      },
      { text: "C. Who the policy applies to", subtext: [] },
      {
        text: "This Privacy Policy applies to all users of our job board website, including but not limited to employers, recruiters, job seekers, and visitors to the site. By accessing or using our website, you agree to the terms of this policy.",
        subtext: [],
      },
      {
        text: "Our website may contain links to third-party websites or services that are not owned or controlled by us. We are not responsible for the privacy practices of these third-party websites or services, and we encourage users to read the privacy policies of these websites before providing any personal information.",
        subtext: [],
      },
      {
        text: "We reserve the right to modify this Privacy Policy at any time, and we will notify users of any changes by posting an updated version of the policy on our website",
        subtext: [],
      },
    ],
  },
  {
    title: "II. INFORMATION COLLECTION",
    text: [
      { text: "A. Information collected from users", subtext: [] },
      {
        text: "We collect personal information from users who register on our website or submit job applications. The information we collect may include:",
        subtext: [
          "Names",
          "Email addresses",
          "Phone numbers",
          "Resumes/CVs",
          "Job preferences",
          "Other information relevant to job applications or job postings",
        ],
      },
      {
        text: "We may also collect non-personal information, such as website usage statistics and IP addresses, to improve our website and provide a better user experience",
        subtext: [],
      },
      { text: "B. How the information will be collected", subtext: [] },
      {
        text: "We collect information from users in various ways, including",
        subtext: [
          "Registration: When users register on our website, we ask them to provide their name, email address, and other relevant information.",
          "Job applications: When users apply for jobs through our website, we ask them to submit their resumes/CVs and other relevant information.",
          "Job postings: When employers/recruiters post jobs on our website, we collect information about the job posting, including the job title, job description, and job requirements",
        ],
      },
      {
        text: "We may also use cookies or other tracking technologies to collect information about users' browsing behavior on our website",
        subtext: [],
      },
      {
        text: "C. Purposes for which the information will be used",
        subtext: [],
      },
      {
        text: "We collect personal information from users for the following purposes:",
        subtext: [
          "To match job seekers with potential employers/recruiters and facilitate communication between the partie",
          "To notify job seekers of new job postings or other relevant information",
          "To send marketing emails about our website or related services",
          "To improve our website and provide a better user experience",
          "To comply with legal obligations",
        ],
      },
      {
        text: "We may also use personal information to create anonymous data sets for statistical analysis and research purposes. We will never sell or rent personal information to third parties for their marketing purposes without obtaining explicit consent from the user.",
        subtext: [],
      },
    ],
  },
  {
    title: "III. INFORMATION USE",
    text: [
      { text: "A. How the collected information will be used", subtext: [] },
      {
        text: "We use the personal information we collect from users for the following purposes:",
        subtext: [
          "To match job seekers with potential employers/recruiters and facilitate communication between the parties",
          ,
          "To notify job seekers of new job postings or other relevant information",
          "To send marketing emails about our website or related services",
          "To improve our website and provide a better user experience",
          "To comply with legal obligation",
        ],
      },
      {
        text: "We may also use personal information to create anonymous data sets for statistical analysis and research purposes.",
        subtext: [],
      },
      {
        text: "B. Conditions under which the information will be disclosed to third parties",
        subtext: [],
      },
      {
        text: "We will not disclose personal information to third parties without the user's consent, except under the following circumstances:",
        subtext: [
          "To our service providers, who are bound by confidentiality agreements and are only authorized to use the information as necessary to provide their services to us",
          "To comply with legal obligations, such as a court order or subpoena",
          "To protect the rights, property, or safety of our website, our users, or others",
          "In connection with a merger, acquisition, or sale of assets",
          "To comply with legal obligations, such as a court order or subpoena",
          "To protect the rights, property, or safety of our website, our users, or others",
          " In connection with a merger, acquisition, or sale of assets",
        ],
      },
      {
        text: "We may also share anonymous or aggregated data sets with third parties for statistical analysis or research purposes.",
        subtext: [],
      },
      {
        text: "C. How users can control the use and sharing of their information",
        subtext: [],
      },
      {
        text: "Users have the right to control how their personal information is used and shared. Here are some ways users can exercise this control:",
        subtext: [
          "Users can edit or delete their personal information by logging into their account and accessing the relevant settings",
          "Users can unsubscribe from marketing emails by following the instructions included in each email",
          "Users can request that we delete their personal information by contacting us at [insert contact information]",
          "Users can opt out of certain types of data collection by adjusting their browser settings orusing ad-blocking software",
        ],
      },
      {
        text: "However, please note that certain types of personal information may be required to use certain features of our website, and deleting or withholding this information may affect the user's ability to use our website.",
        subtext: [],
      },
    ],
  },
  {
    title: "IV. DATA RETENTION",
    text: [
      { text: "A. How long user data will be retained", subtext: [] },
      {
        text: "We will retain user data for as long as necessary to fulfill the purposes for which it was collected, unless a longer retention period is required or permitted by law. The specific retention periods may vary depending on the type of data and the context in which it was collected. For example:",
        subtext: [
          "User account information will be retained as long as the account is active and for a reasonable period of time thereafter",
          "Job postings will be retained for a reasonable period of time after the position has been filled or the posting has expired",
          "Resumes/CVs will be retained for a reasonable period of time after the job application has been processed",
        ],
      },
      { text: "B. Procedures for deleting user data", subtext: [] },
      {
        text: "Users can request that their personal information be deleted by contacting us at [insert contact information]. We will promptly delete or anonymize the personal information in accordance with applicable law.",
        subtext: [],
      },
      { text: "C. Exceptions to data deletion", subtext: [] },
      {
        text: "We may be required to retain certain types of personal information for legal or regulatory purposes, such as tax or accounting obligations. We may also be required to retain certain types of personal information to enforce our agreements or to protect our legal rights.",
        subtext: [],
      },
    ],
  },
  {
    title: "V. SECURITY MEASURES",
    text: [
      {
        text: "A. Security measures in place to protect user data",
        subtext: [],
      },
      {
        text: "We take reasonable measures to protect the personal information we collect from unauthorized access, disclosure, alteration, or destruction. These measures include:",
        subtext: [
          "Using SSL encryption to transmit sensitive data",
          "Restricting access to personal information on a need-to-know basis",
          "Regularly monitoring our systems for security vulnerabilitie",
          "Storing personal information on secure servers that are protected by firewalls and other industry-standard security measures",
        ],
      },
      { text: "B. Procedures for reporting data breaches", subtext: [] },
      {
        text: "In the event of a data breach that affects personal information, we will notify affected users as soon as possible, in accordance with applicable law. We will also notify relevant authorities, such as law enforcement or data protection authorities, as required by law.",
        subtext: [],
      },
      {
        text: "C. How users will be notified in case of a data breach",
        subtext: [],
      },
      {
        text: "If we determine that a data breach has occurred that may affect personal information, we will notify affected users by email or other appropriate means. The notification will include information about the nature of the breach, the types of personal information that may have been affected, and steps that users can take to protect themselves. We will also provide information about our response to the breach and any actions we are taking to prevent future breaches.",
        subtext: [],
      },
    ],
  },
  {
    title: "VI. USER RIGHTS",
    text: [
      { text: "A. User rights regarding their personal data", subtext: [] },
      {
        text: "Users have certain rights with respect to their personal information, including:",
        subtext: [
          "The right to access their personal information",
          "The right to correct or update their personal information",
          "The right to delete their personal information",
          "The right to object to the processing of their personal information",
          "The right to restrict the processing of their personal information",
          "The right to data portability",
          "The right to withdraw consen",
        ],
      },
      { text: "B. How users can exercise their rights", subtext: [] },
      {
        text: "Users can exercise their rights by contacting us at [insert contact information]. We will respond to the request in a timely manner and in accordance with applicable law.",
        subtext: [],
      },
      { text: "C. Exceptions to user rights", subtext: [] },
      {
        text: "We may be unable to comply with certain user requests in certain circumstances, such as when the request would:",
        subtext: [
          "Result in a breach of a legal or regulatory obligation",
          "Interfere with the rights of other users or third parties",
          "Result in a security risk to our systems or data",
        ],
      },
    ],
  },
  {
    title: "VII. UPDATES TO THE POLICY",
    text: [
      { text: "A. How the policy may be updated", subtext: [] },
      {
        text: "We reserve the right to update this Privacy Policy from time to time to reflect changes in our data practices or legal requirements. We will post an updated version of the policy on our website, and we encourage users to review the policy periodically.",
        subtext: [],
      },
      {
        text: "B. How users will be notified of policy updates",
        subtext: [],
      },
      {
        text: "We will notify users of any material changes to the Privacy Policy by posting a prominent notice on our website or by sending an email to registered users. Your continued use of our website after the posting of any changes to this policy will constitute your acceptance of those changes.",
        subtext: [],
      },
    ],
  },
  {
    title: "VIII. CONTACT INFORMATION",
    text: [
      {
        text: "If you have any questions or concerns about this Privacy Policy, or if you wish to exercise your rights with respect to your personal information, please contact us.",
        subtext: [],
      },
    ],
  },
];

export const icons = [
  { icon: "/static/media/Advertising.png", text: "Pharmaceutical" },
  { icon: "/static/media/consulting.png", text: "Pharmaceutical" },
  { icon: "/static/media/Fitness.png", text: "Pharmaceutical" },
  { icon: "/static/media/Media.png", text: "Pharmaceutical" },
  { icon: "/static/media/telecommunications.png", text: "Pharmaceutical" },
  { icon: "/static/media/Pharmaceutical.png", text: "Pharmaceutical" },
  { icon: "/static/media/Insurance.png", text: "Insurance" },
  { icon: "/static/media/education.png", text: "Education" },
  { icon: "/static/media/Cosmetics.png", text: "High Tech" },
  { icon: "/static/media/Capital markets.png", text: "Health" },
  { icon: "/static/media/Fashion.png", text: "Health" },
  { icon: "/static/media/Dealership.png", text: "Health" },
  { icon: "/static/media/Real estate.png", text: "Health" },
  { icon: "/static/media/Finance.png", text: "Health" },
  { icon: "/static/media/Technology.png", text: "Health" },
];
