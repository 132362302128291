import React, { useRef, useState } from "react";
import { useGlobalContext } from "../../context";
import { Link, Navigate, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./Index.css";
import axios from "axios";
import Select from "react-select";
import { locations } from "../jobs/data";

import { FaCheck } from "react-icons/fa";
import Terms from "../../reusableComponents/privacyAndTerms/Terms";
import Policies from "../../reusableComponents/privacyAndTerms/Policies";
import { displaySpinner } from "../recruiter/CreateAccount";
import { removeSpinner } from "../recruiter/CreateAccount";
import ReCAPTCHA from "react-google-recaptcha";
import { useEffect } from "react";
import { PageDescription } from "../../reusableComponents/pageDescription";
import { api_url } from "../../libs/api";
import { handleClickEvent } from "../../libs/utils";

export default function Signup() {
  const user = useGlobalContext();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [resError, setResError] = useState([]);

  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("+1");
  const [password, setPassword] = useState("");
  const [passWord, setPassWord] = useState("");
  const [address, setAddress] = useState("");
  // eslint-disable-next-line
  const [type, setType] = useState("sales");
  const [success, setSuccess] = useState(false);
  const [tearmsModal, setTearmsModal] = useState(false);
  const [policyModal, setPolicyModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [robot, setRobot] = useState(false);
  const [robotCount, setRobotCount] = useState(0);

  const props = { tearmsModal, setTearmsModal, policyModal, setPolicyModal };

  const onChange = (e) => {
    const re = /^(?=.*[+])[- +()0-9 ]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setPhone(e.target.value);
    }
  };
  const captchaRef = useRef(null);
  const token = captchaRef?.current?.getValue();
  const tokenLength = token?.split("").length;
  console.log(tokenLength);
  useEffect(() => {
    const count = setInterval(() => {
      setRobotCount((counting) => (counting += 1));
    }, 1000);

    return () => {
      clearInterval(count);
    };
  }, [password]);

  handleClickEvent({
    action: "Create account",
    label: "signup",
  });
  const login = async (e) => {
    console.log(token);
    e.preventDefault();
    console.log("kkk");
    if (token === null || token === "undefined" || tokenLength === 0) {
      setRobot(true);
    } else if (password.length < 8) {
      setError(true);
    } else {
      setError(false);
      setLoading(true);
      const elements = displaySpinner(); // Display spinner
      axios
        .post(
          `${api_url}registration/applicant/`,
          {
            name: name,
            phone: phone,
            email: email,
            address: address,
            password1: password,
            password2: passWord,
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          localStorage.setItem("key", JSON.stringify(res.data.key));
          localStorage.setItem("id", JSON.stringify(res.data.user.id));
          removeSpinner(elements); // Remove spinner
          setLoading(false);
          setSuccess(true);
          console.log(res);
        })
        .catch((err) => {
          setResError(err.response.data.password1);
        });
    }
  };

  useEffect(() => {
    setPassWord(password);
  }, [password]);
  useEffect(() => {
    console.log(token);
    console.log("token");
    // setPassWord(password);
  }, [token]);

  if (user.user.applicant) {
    return <Navigate to="/" replace="true" />;
  }

  if (user.recruiter.recruiter) {
    return <Navigate to="/" replace="true" />;
  }

  return (
    <div
      className="position-fixed "
      style={{
        zIndex: "5",
        top: "0",
        right: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
        backgroundColor: "#fff",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <PageDescription title={" Create your account"} content={""} />
      <div className="container-fluid">
        <div
          style={{
            position: "fixed",
            zIndex: "2",
            top: "0",
            left: "0",
            height: "100vh",
          }}
          className="d-none d-md-block col-lg-6 col-md-4"
        >
          <img
            src="/static/media/figma 1.png"
            alt=""
            className="w-100"
            style={{ height: "100vh", objectFit: "cover" }}
          />
        </div>
        <div className="container-fluid h-100">
          <div
            style={{ minHeight: "100vh" }}
            className="align-items- pb-5 auth-margin position-relative row"
          >
            <div className=" mx-auto col-md-9 mb-5">
              <Link
                to="/login"
                style={{
                  fontWeight: "500",
                  fontFamily: "inter",
                  fontSize: "18px",
                  top: "0",
                  right: "0",
                  color: "#8692A6",
                  textDecoration: "none",
                }}
                className="d-flex justify-content-end my-4"
              >
                Already have an account? <span className="px-1"></span>
                <span className="primary_color">Sign in</span>
              </Link>
              <form
                onSubmit={login}
                style={{ marginTop: "20%" }}
                className="mx-auto mb-4 mt-4 pt-md-4 mx-xl-4 px-xl-"
              >
                <div
                  className="black"
                  style={{
                    fontFamily: "inter",
                    fontSize: "1.8rem",
                    fontWeight: "700",
                  }}
                >
                  Quick Registration
                </div>
                <div
                  className="black"
                  style={{
                    fontFamily: "inter",
                    fontSize: "1rem",
                    fontWeight: "400",
                  }}
                >
                  Sign Up Today to Gain Access to a Wide Range of Sales
                  Opportunities!
                </div>
                <div
                  style={{
                    fontFamily: "inter",
                    fontSize: "1rem",
                    fontWeight: "500",
                  }}
                  className="mt-4"
                >
                  Full name*
                </div>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  placeholder="Your full name"
                  style={{
                    color: "rgba(124, 146, 166, 1)",
                    fontFamily: "inter",
                    fontSize: "0.87rem",
                    fontWeight: "500",
                  }}
                  required
                  autoFocus
                  className=" py-2 px-3 login_input rounded"
                />
                <div
                  style={{
                    fontFamily: "inter",
                    fontSize: "1rem",
                    fontWeight: "500",
                  }}
                  className="mt-4"
                >
                  Email*
                </div>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="Enter your email"
                  style={{
                    color: "rgba(124, 146, 166, 1)",
                    fontFamily: "inter",
                    fontSize: "0.87rem",
                    fontWeight: "500",
                  }}
                  required
                  className=" py-2 px-3 login_input rounded"
                />
                <div
                  style={{
                    fontFamily: "inter",
                    fontSize: "1rem",
                    fontWeight: "500",
                  }}
                  className="mt-4"
                >
                  Phone number*
                </div>
                <input
                  id="phone"
                  required
                  maxLength={"11"}
                  onChange={onChange}
                  value={phone}
                  style={{
                    fontFamily: "inter",
                    fontSize: "0.87rem",
                    fontWeight: "400",
                    color: "#6B778C",
                    background: "white",
                    border: `2px solid  #DFE1E6`,
                  }}
                  className="py-2 px-3 w-100 text-start rounded login_input rounded"
                />
                <div
                  style={{
                    fontFamily: "inter",
                    fontSize: "1rem",
                    fontWeight: "500",
                  }}
                  className="mt-4"
                >
                  State*
                </div>
                <Select
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) => setAddress(e.value)}
                  placeholder="Select"
                  options={locations}
                  styles={{
                    input: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: "4px 0",
                      fontFamily: "inter",
                      fontSize: "0.3rrem !important",
                      fontWeight: "400",
                      color: "#6B778C",
                    }),
                    control: (base, state) => ({
                      ...base,
                      boxShadow: "none",
                      border: "2px solid #dfe1e6",
                      borderRadius: "6px",
                      fontSize: "0.87rem",
                      height: "49.8px",

                      "&:hover": {
                        // boxShadow: "0 0 0 2px black !important",
                        borderColor: "#4897d8 !important",
                      },
                      "&:focus": {},
                      boxShadow: state.isFocused
                        ? "2px 2px 3px rgb(213, 213, 213, 0.4), -2px -2px 3px rgb(213, 213, 213, 0.4) !important"
                        : "2px 2px 3px rgb(213, 213, 213, 0.4), -2px -2px 3px rgb(213, 213, 213, 0.4) !important",
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: "black",
                      fontFamily: "inter",
                      fontSize: "0.87rem !important",
                    }),
                  }}
                />
                <div
                  style={{
                    fontFamily: "inter",
                    fontSize: "1rem",
                    fontWeight: "500",
                  }}
                  className="mt-4"
                >
                  Password*
                </div>
                <div className="position-relative">
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type={show ? "text" : "password"}
                    placeholder="8 characters or more"
                    style={{
                      color: "rgba(124, 146, 166, 1)",
                      fontFamily: "inter",
                      fontSize: "0.87rem",
                      fontWeight: "500",
                    }}
                    required
                    className={
                      error || passwordError
                        ? "error py-2 px-3 login_input rounded"
                        : " py-2 px-3 login_input rounded"
                    }
                  />
                  <div
                    onClick={() => setShow(!show)}
                    className="show_password px-1"
                  >
                    {show ? "Hide" : "Show"}
                  </div>
                </div>
                {error && (
                  <small className="text-danger">
                    Password must be longer than 8 characters
                  </small>
                )}

                {error && (
                  <small className="text-danger">
                    Password must be longer than 8 characters
                  </small>
                )}
                {passwordError && (
                  <small className="text-danger">{passwordError}</small>
                )}
                {resError && (
                  <small className="text-danger">
                    {resError.map((err) => {
                      return <small className="">{err}</small>;
                    })}
                  </small>
                )}
                <div className="row mt-3">
                  <ReCAPTCHA
                    sitekey={"6LfVtiYnAAAAAIZXwkTGWmE9Hkf_RiY2Gj-jUbxx"}
                    ref={captchaRef}
                  />

                  {robot && !token && (
                    <small className="text-danger">
                      You must verify that you're not a bot!
                    </small>
                  )}
                  <div
                    style={{
                      fontFamily: "inter",
                      fontSize: "1rem",
                      fontWeight: "500",
                      color: "background: rgba(44, 82, 130, 1)",
                      paddingRight: "2vw",
                    }}
                    className="my-3 col-12 col-lg-10"
                  >
                    By continuing, you agree to name <br />{" "}
                    <span
                      className="primary_color cursor"
                      onClick={() => setTearmsModal(true)}
                    >
                      Terms of Use
                    </span>
                    . Read our{" "}
                    <span
                      className="primary_color cursor"
                      onClick={() => setPolicyModal(true)}
                    >
                      Privacy Policy
                    </span>
                    .
                  </div>
                </div>
                <button
                  type="submit"
                  className="rounded primary_bg_color text-center py-3 text-white cursor-pointer w-100 border-0"
                >
                  Continue
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className={`${success ? "show" : ""} account_success d-flex`}>
        <div
          style={{ height: "fit-content" }}
          className="col-10 col-sm-10 col-md-6 col-lg-5 text-center bg-white mx-auto py-5 px-5 position-relative"
        >
          <div
            style={{
              width: "103.33px",
              height: "102.5px",
              border: "0.3rem solid rgba(101, 202, 117, 1)",
              borderRadius: "100%",
            }}
            className="bg-white d-flex align-items-center justify-content-center mx-auto mt-5"
          >
            <FaCheck
              style={{
                width: "41.33px",
                height: "30.75px",
                color: "rgba(101, 202, 117, 1)",
              }}
              className=""
            />{" "}
          </div>
          <div
            style={{
              fontWeight: "700",
              fontFamily: "inter",
              fontSize: "18px",
              lineHeight: "21.78px",
            }}
            className="primary_color mb-1 mt-3"
          >
            Account Creation Successful
          </div>
          <div
            style={{
              fontWeight: "400",
              fontFamily: "inter",
              fontSize: "16px",
              lineHeight: "28px",
              color: "rgba(134, 146, 166, 1)",
            }}
            className=""
          >
            Thanks for registering with Intoosales. Your account has been
            created!
          </div>
          <div
            onClick={() => window.location.replace("/")}
            style={{
              fontWeight: "500",
              fontFamily: "inter",
              fontSize: "20px",
              lineHeight: "24.2px",
            }}
            className="primary_bg_color white rounded py-3 mb-5 mt-2 cursor "
          >
            Continue
          </div>
        </div>
      </div>

      <Terms {...props} />
      <Policies {...props} />
    </div>
  );
}
