import React, { useState } from "react";
import { useGlobalContext } from "../../context";
import { Navigate, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Popup from "../popup/Popup";
import { useEffect } from "react";
import { HiArrowLeft } from "react-icons/hi";
import { api_host } from "../../libs/api";

export default function ResetPassword() {
  const user = useGlobalContext();
  const [success, setSuccess] = useState(false);

  const [email, setEmail] = useState("");
  const [text, setText] = useState("");

  const login = (e) => {
    e.preventDefault();
    const key = JSON.parse(localStorage.getItem("key"));

    axios
      .post(`${api_host}/rest-auth/password/reset/`, {
        email: email,
      })
      .then((res) => {
        setEmail("");
        setText(res.data.detail);
        setSuccess(true);
      })
      .catch((err) => {});
  };

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      if (success) {
        setSuccess(false);
      }
    }, 4000);
  }, [success]);

  return (
    <div
      className="position-fixed"
      style={{
        zIndex: "772",
        top: "0",
        right: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
        backgroundColor: "#f1fcff",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <div className=" bg-white container-fluid h-100">
        <div className="container">
          <div
            style={{ width: "fit-content" }}
            onClick={() => navigate(-1)}
            className="d-flex w-fit cursor-pointer gap-2 pt-3 align-items-center "
          >
            <HiArrowLeft style={{ fontSize: "2rem" }} />
            <div
              className=""
              style={{
                fontSize: "1.5rem",
                fontWeight: "600",
                fontFamily: "poppins",
              }}
            >
              Back
            </div>
          </div>
          <div className=" pb-2 row ">
            <form onSubmit={login} className="mt-5 mx-auto pt-md-4 col-md-6">
              <div
                className="black"
                style={{
                  fontFamily: "roboto",
                  fontSize: "1.7rem",
                  fontWeight: "600",
                }}
              >
                Forget your password?
              </div>{" "}
              <div
                className="text-muted"
                style={{
                  fontFamily: "inter",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
              >
                Enter your email and we'll send you a link to reset it!
              </div>
              <div
                style={{
                  fontFamily: "roboto",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
                className="mt-3 mt-md-4"
              >
                Email*
              </div>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Enter your email"
                style={{
                  color: "rgba(124, 146, 166, 1)",
                  fontFamily: "roboto",
                  fontSize: "0.87rem",
                  fontWeight: "500",
                }}
                required
                className={` py-2 px-3 login_input rounded`}
              />
              <button
                style={{
                  fontFamily: "roboto",
                  fontWeight: "600",
                  letterSpacing: "0.2vw",
                }}
                type="submit"
                className="rounded primary_bg_color text-center py-3 text-white cursor-pointer w-100 border-0 mt-3"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>

      {success ? <Popup text={text} /> : ""}
    </div>
  );
}
