import React, { useEffect, useState } from "react";
import { FaUpload } from "react-icons/fa";
import {
  jobData,
  salaryData,
  expData,
  roleData,
  industryData,
  locations,
  salesData,
} from "../jobs/data";
import Select from "react-select";
import axios from "axios";
import { Dropdown3 } from "../../components/Dropdown";
import { useGlobalContext } from "../../context";
import { Navbar2 } from "../../navigation/Navbar";
import { displaySpinner } from "../recruiter/CreateAccount";
import { removeSpinner } from "../recruiter/CreateAccount";
import Questionaire from "../../reusableComponents/questionaire/Questionaire";
import { toast } from "react-toastify";
import { PageDescription } from "../../reusableComponents/pageDescription";
import UploadImg from "./uploadImg";
import Inputs from "../createteJob/inputs";
import Textareas from "../createteJob/textarea";
import { user_id, user_key } from "../../libs/mockdata";
import { removeObjectKeys } from "../../libs/utils";
import { api_url } from "../../libs/api";

export default function EditJob() {
  const data = useGlobalContext();
  const edit = data.edit.edit;
  const pk = data.edit.id;
  const [form, setForm] = useState(edit);
  const [img, setImg] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mb, setMb] = useState({ state: false, size: 0 });
  const [error, setError] = useState(false);
  const [questionaire, setQuestionaire] = useState({
    state: edit.questionnaire_required,
    questions: edit.questions,
    score: edit.passing_score,
  });

  const submit = (e) => {
    e.preventDefault();
    if (
      Object.values(removeObjectKeys(form)).filter((item) => item != "")
        .length < 14
    ) {
      setError(true);
      window.scrollTo(0, 0);
    } else {
      delete form["image"];
      setLoading(true);
      const elements = displaySpinner(); // Display spinner
      const date = new Date();
      const props = { ...form, image: form.image == "string" || form.image };
      axios
        .put(
          `${api_url}job/${pk}/`,
          {
            ...form,
            image: img && img,
            id: pk,
            country: "USA",
            creation_date: `${date.getFullYear()}-${
              date.getUTCMonth() + 1
            }-${date.getDate()}`,
            recruiter: user_id,
            start_date: edit.start_date,
            end_date: edit.end_date,
            questionnaire_required:
              questionaire.questions.length > 0 ? true : false,
            questions: questionaire.questions,
            passing_score: questionaire.score,
          },
          {
            headers: {
              Authorization: `Token ${user_key}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          removeSpinner(elements); // Remove spinner
          setLoading(false);
          setSuccess(true);
          const notify = () =>
            toast.success("You have successfully edited your job post!");
          if (elements) {
            notify();
          }
        })
        .catch((err) => {
          removeSpinner(elements); // Remove spinner
          setLoading(false);
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (img) {
      setForm({ ...form, image: img });
    }
  }, [img]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Edit your job";
  }, []);

  const props = {
    questionaire,
    setQuestionaire,
    editJob: true,
    status: edit.status,
    form,
    setForm,
    error,
    mb,
    setMb,
    img,
    setImg,
  };

  return (
    <>
      <Navbar2 />
      <PageDescription title={" Edit Jobs"} content={""} />

      <article className={`job_searc bg-white`}>
        <div className="container-fluid px-md-5">
          <div className="">
            <div
              className="d-none d-md-block fixed h-screen left-0"
              style={{
                zIndex: "3",
                top: "61.99px",
                width: "17%",
              }}
            >
              <img
                src="./static/media/figma 4.png"
                alt=""
                className="w-100 h-100"
                style={{ objectFit: "cover" }}
              />
            </div>
            <div className="job-margin mb-5 mt-4">
              <div className="container">
                <div className="d-flex justify-content-between align-items-stretch">
                  <UploadImg {...props} />
                  <div className="my-3 my-md-0 d-none d-lg-block col-12 col-sm-7 ">
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        zIndex: "1",
                        cursor: "default",
                      }}
                      className="position-relative"
                    >
                      <div className="fine-border-1"></div>
                      <div className="fine-border-2"></div>
                      <div
                        style={{
                          background: "#f2f9ff",
                          paddingRight: "8px",
                          paddingLeft: "24px",
                        }}
                        className="rounded pb-4 pt-2 h-100 "
                      >
                        <div
                          style={{
                            fontFamily: "inter",
                            fontSize: "1.5rem",
                            fontWeight: "700",
                          }}
                          className="mb-3"
                        >
                          Tips
                        </div>
                        <ul>
                          <li
                            style={{
                              fontSize:
                                "calc(19.3*(clamp(800px,100vw,1366px)/1366))",
                              fontWeight: "400",
                            }}
                            className="mt-4"
                          >
                            <span className="text-capitalize">u</span>se a
                            catchy job title to attract potential candidates.
                          </li>
                          <li
                            style={{
                              fontSize:
                                "calc(19.3*(clamp(800px,100vw,1366px)/1366))",
                              fontWeight: "400",
                            }}
                            className="mt-4"
                          >
                            <span className="text-capitalize">H</span>ighlight
                            the benefits of the job and any growth
                            opportunities.
                          </li>
                          <li
                            style={{
                              fontSize:
                                "calc(19.3*(clamp(800px,100vw,1366px)/1366))",
                              fontWeight: "400",
                            }}
                            className="mt-4"
                          >
                            <span className="text-capitalize">B</span>e specific
                            about the required sales experience and skills.
                          </li>
                          <li
                            style={{
                              fontSize:
                                "calc(19.3*(clamp(800px,100vw,1366px)/1366))",
                              fontWeight: "400",
                            }}
                            className="mt-4"
                          >
                            <span className="text-capitalize">I</span>nclude
                            information on compensation and benefits.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center my-4">
                  <div className="black font-bold font-inter text-lg w-96">
                    General information
                  </div>
                </div>
                <section>
                  <div className="row">
                    <Inputs {...props} />
                  </div>
                </section>
                <Textareas {...props} />

                <div className="col-md-7 my-3">
                  <div className="d-flex flex-column flex-sm-row align-items-center mt-3 gap-3">
                    <div
                      onClick={submit}
                      style={{
                        cursor: success ? "not-allowed" : "",
                        pointerEvents: success ? "none" : "",
                      }}
                      className="rounded cursor-pointer primary_bg_color link"
                    >
                      <div
                        className="d-flex align-items-stretch text-white px-4"
                        style={{ paddingTop: "9.5px", paddingBottom: "9.5px" }}
                      >
                        <div
                          className="white "
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: "600",
                            letterSpacing: "0.1vw",
                          }}
                        >
                          {success ? "Submitted" : "Submit"}
                        </div>
                      </div>
                    </div>
                    {/* {console.log()}*/}
                    <Questionaire {...props} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
