import React, { useState } from "react";
import { useGlobalContext } from "../../context";
import "./Index.css";
import { Navigate, Link } from "react-router-dom";
import axios from "axios";
import { FaTimes } from "react-icons/fa";
import { displaySpinner } from "../recruiter/CreateAccount";
import { removeSpinner } from "../recruiter/CreateAccount";
import { PageDescription } from "../../reusableComponents/pageDescription";
import { api_url } from "../../libs/api";
import { handleClickEvent } from "../../libs/utils";

export default function Login() {
  const user = useGlobalContext();
  const [error, setError] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [success, setSuccess] = useState(false);

  const login = (e) => {
    e.preventDefault();
    handleClickEvent({
      action: "Login",
      label: "Login",
    });
    if (password.length < 8) {
      setError(true);
    } else {
      setError(false);
      const elements = displaySpinner(); // Display spinner
      axios
        .post(
          `${api_url}custom/login/`,
          {
            email: email,
            password: password,
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          removeSpinner(elements); // Remove spinner
          if (res.data.user.is_applicant) {
            setEmail("");
            setPassword("");
            localStorage.setItem("id", JSON.stringify(res.data.user.id));
            localStorage.setItem("key", JSON.stringify(res.data.key));
            window.location.href = "/";
          } else {
            setSuccess(true);
          }
        })
        .catch((err) => {
          removeSpinner(elements); // Remove spinner
          setPasswordError(
            "Unable to login with the provided credentials, please try again"
          );
        });
    }
  };

  if (user.user.applicant) {
    return <Navigate to="/" replace="true" />;
  }

  if (user.recruiter.recruiter) {
    return <Navigate to="/" replace="true" />;
  }

  return (
    <div
      className="position-fixed"
      style={{
        zIndex: "2",
        top: "0",
        right: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
        backgroundColor: "#fff",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <PageDescription title={" Log in to your account"} content={""} />
      {user.email}
      <div className="container-fluid h-100">
        <div
          style={{
            position: "fixed",
            zIndex: "2",
            top: "0",
            left: "0",
            height: "100vh",
          }}
          className="d-none d-md-block col-lg-6 col-md-4"
        >
          <img
            src="/static/media/figma 1.png"
            alt=""
            className="w-100"
            style={{ height: "100%", objectFit: "cover" }}
          />
        </div>
        <div className="container-fluid">
          <div className="position-relative pb-2 auth-margin row align-items-center">
            <div className="mx-auto col-md-9">
              <Link
                to="/signup"
                style={{
                  fontWeight: "500",
                  fontFamily: "inter",
                  fontSize: "18px",
                  top: "0",
                  right: "0",
                  color: "#8692A6",
                  textDecoration: "none",
                }}
                className="d-flex justify-content-end my-4"
              >
                Don’t have an account? <span className="px-1"></span>
                <span className="primary_color">Sign up</span>
              </Link>
              <form
                onSubmit={login}
                style={{ marginTop: "20%" }}
                className="mx-xl-5 mt-5 px-xl-3 pt-md-4"
              >
                <div
                  className="black"
                  style={{
                    fontFamily: "inter",
                    fontSize: "1.8rem",
                    fontWeight: "700",
                  }}
                >
                  Sign in
                </div>
                <div
                  className="black"
                  style={{
                    fontFamily: "inter",
                    fontSize: "1rem",
                    fontWeight: "400",
                  }}
                >
                  Welcome Back, Sales Rockstar! Sign In to Ignite Your Career
                  Journey!{" "}
                </div>
                <div
                  style={{
                    fontFamily: "inter",
                    fontSize: "1rem",
                    fontWeight: "500",
                  }}
                  className="mt-3 mt-lg-4"
                >
                  Email*
                </div>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="Enter your email"
                  style={{
                    color: "rgba(124, 146, 166, 1)",
                    fontFamily: "inter",
                    fontSize: "0.87rem",
                    fontWeight: "500",
                  }}
                  required
                  autoFocus
                  className={` py-2 px-3 login_input rounded ${
                    passwordError || success ? "error" : ""
                  }`}
                />
                <div
                  style={{
                    fontFamily: "inter",
                    fontSize: "1rem",
                    fontWeight: "500",
                  }}
                  className="mt-4"
                >
                  Password*
                </div>
                <div className="position-relative">
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type={show ? "text" : "password"}
                    placeholder="Enter your password"
                    style={{
                      color: "rgba(124, 146, 166, 1)",
                      fontFamily: "inter",
                      fontSize: "0.87rem",
                      fontWeight: "500",
                    }}
                    required
                    className={` py-2 px-3 login_input rounded ${
                      error || success ? "error" : ""
                    } ${passwordError ? "error" : ""} `}
                  />
                  <div
                    onClick={() => setShow(!show)}
                    className="show_password px-1"
                  >
                    {show ? "Hide" : "Show"}
                  </div>
                </div>
                <div
                  style={{
                    fontFamily: "inter",
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "background: rgba(44, 82, 130, 1)",
                  }}
                  className="mt-2"
                >
                  {passwordError && (
                    <small className="text-danger">{passwordError}</small>
                  )}
                  {success && (
                    <small className="text-danger">
                      Your are a recruiter, please use the recruiter login page.
                    </small>
                  )}
                  {error && (
                    <small className="text-danger">
                      Password must be longer than 8 character
                    </small>
                  )}
                </div>
                <div className="d-flex justify-content-end mb-4">
                  <Link
                    to={"/reset-password"}
                    onClick={() => localStorage.setItem("login", 2)}
                  >
                    <small className="">Reset Password</small>
                  </Link>
                </div>
                <button
                  type="submit"
                  className="rounded primary_bg_color text-center py-3 text-white cursor-pointer w-100 border-0"
                >
                  Continue
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
