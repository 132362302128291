import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import { FaShare, FaFileUpload, FaSistrix, FaLinkedinIn } from "react-icons/fa";
import {
  salesData,
  jobData,
  roleData,
  industryData,
  locations,
} from "../jobs/data";
import Multi, {
  Dropdown,
  Dropdown1,
  Dropdown2,
} from "../../components/Dropdown";
import axios from "axios";
import { useGlobalContext } from "../../context";
import Terms from "../../reusableComponents/privacyAndTerms/Terms";
import Policies from "../../reusableComponents/privacyAndTerms/Policies";
import { FacebookShareCount, LineShareButton } from "react-share";
import { displaySpinner } from "../recruiter/CreateAccount";
import { removeSpinner } from "../recruiter/CreateAccount";
import { ShareBox } from "../../reusableComponents/shareBox/ShareBox";
import "./index.scss";
import Navbar from "../../navigation/Navbar";
import Questions from "../../reusableComponents/questions/Questionaire";
import { ToastContainer, toast } from "react-toastify";
import { PageDescription } from "../../reusableComponents/pageDescription";
import { api_host, api_url } from "../../libs/api";

export default function Jobs() {
  const user = useGlobalContext();
  const [jobs, setJobs] = useState([]);
  const [jobLoaded, setJobLoaded] = useState(false);
  const [state, setState] = useState("State");
  const [city, setCity] = useState("");
  const [searchArray, setSearchArray] = useState({ City: [] });
  const [resume, setResume] = useState(true);
  const [resumeModal, setResumeModal] = useState(false);
  const [searchValue, setSearchValue] = useState([]);
  const filteredItems = Object.values(searchArray).join(",").split(",");
  const g = filteredItems.filter((item) => item != "");
  const [searchText, setSearchText] = useState([]);
  const [currentPage, setCurrentPage] = useState(10);
  const [search, setSearch] = useState([]);
  const [tearmsModal, setTearmsModal] = useState(false);
  const [policyModal, setPolicyModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [appliedSuccess, setAppliedSuccess] = useState(false);

  const props = { tearmsModal, setTearmsModal, policyModal, setPolicyModal };

  const searchFilter = () => {
    axios
      .post(`${api_url}search/`, {
        State: searchArray["State"],
        City: searchArray["City"],
        "Choose Industry": searchArray["Choose Industry"],
        "Sales Role": searchArray["Sales Role"],
        "Sales Type": searchArray["Sales Type"],
        "Job Type": searchArray["Job Type"],
      })
      .then((res) => {
        setSearch(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    searchFilter();
    return () => {
      setSearch([]);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const elements = displaySpinner(); // Display spinner

      const key = JSON.parse(localStorage.getItem("key"));
      try {
        const res = await axios.get(`${api_url}listjobs/`, {
          headers: { Authorization: `Token ${key}` },
        });
        setJobs(res.data);
      } catch (err) {
        console.log(err);
      } finally {
        removeSpinner(elements); // Remove spinner
        setLoading(false);
        const timer = setTimeout(() => {
          setJobLoaded(true);
        }, 1000);

        return () => {
          setJobs([]);
          clearTimeout(timer);
        };
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setCurrentPage(10);
  }, [searchValue]);

  useEffect(() => {
    document.title = "Find jobs";
    // document.body.style.background =
  }, []);

  return (
    <div className="">
      <PageDescription title={"Job Feed"} content={""} />

      <Navbar />
      <Terms {...props} />
      <Policies {...props} />

      <ToastContainer />
      <article
        style={{ scrollBehavior: "smooth" }}
        className="container-fluid container-lg"
      >
        <div className="px-lg-5 mx-xl-4">
          <div className="container-fluid container-sm">
            <div
              style={{
                fontFamily: "inter",
                fontSize: "27px",
                fontWeight: "700",
              }}
              className="primary_color job-header"
            >
              {/* Find the job you want */}
            </div>
            <div
              style={{
                background: "rgba(185, 223, 254, 0.5)",
                border: "0.1px solid rgba(221, 221, 221, 1)",
              }}
              className="row bg-white rounded py-3"
            >
              <div className="col-12 col-md-4 col-lg-3 mb-2 mb-sm-0">
                <Multi
                  title={"Sales Role"}
                  setSearchText={setSearchText}
                  data={roleData}
                  setSearchArray={setSearchArray}
                  searchArray={searchArray}
                />
              </div>
              <div className="col-12 col-md-4 col-lg-3">
                <Multi
                  title={"Choose Industry"}
                  setSearchText={setSearchText}
                  data={industryData}
                  setSearchArray={setSearchArray}
                  searchArray={searchArray}
                />
              </div>
              <div className="col-12 col-md-4 col-lg-3 mt-2 mt-md-0">
                <Multi
                  title={"Sales Type"}
                  data={salesData}
                  setSearchText={setSearchText}
                  setSearchArray={setSearchArray}
                  searchArray={searchArray}
                />
              </div>
              <div className="col-12 col-md-6 col-lg-3 mt-2 mt-lg-0">
                <Multi
                  title={"Job Type"}
                  setSearchText={setSearchText}
                  data={jobData}
                  setSearchArray={setSearchArray}
                  searchArray={searchArray}
                />
              </div>
              <div className="col-12 col-md-6 col-Lg-5 mt-2">
                <Dropdown1
                  cities={locations}
                  setCity={setState}
                  border={"1px solid #8692A6"}
                  city={state}
                  searchArray={searchArray}
                  setSearchArray={setSearchArray}
                />
              </div>
              <div className="col-12 col-md-6 col-Lg-5 mt-2">
                <Dropdown2
                  searchArray={searchArray}
                  setSearchArray={setSearchArray}
                  state={state}
                  setCity={setCity}
                  city={city}
                />
              </div>
              <div className="col-12 col-md-6 col-lg-3 mt-2">
                <div
                  onClick={() => searchFilter()}
                  value={""}
                  style={{
                    fontFamily: "inter",
                    fontSize: "1.1rem",
                    border: "1px solid orange",
                    padding: " 5px",
                    boxShadow:
                      " 2px 2px 3px rgb(213, 213, 213, 0.4),  -2px -2px 3px rgb(213, 213, 213, 0.4)",
                  }}
                  className="orange text-center
 w-100 rounded text-white shadow d-flex align-items-center cursor
  justify-content-center"
                >
                  <FaSistrix className="mx-1" />
                  SEARCH
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div
              className="black mt-4 mb-3 mx-1"
              style={{
                fontFamily: "inter",
                fontSize: "19px",
                fontWeight: "600",
              }}
            >
              Go get that sales job you deserve!
            </div>
            <div className="row">
              {search.length === 0 && jobLoaded ? (
                <div
                  style={{
                    height: "30vh",
                    background: "rgba(0,0,0, .05)",
                    fontFamily: "inter",
                    fontSize: "1.1rem",
                  }}
                  className="empty-search text-capitalize col-12 mb-3 rounded d-flex justify-content-center align-items-center"
                >
                  no result found
                </div>
              ) : (
                search?.map((fields, index) => {
                  if (index < currentPage) {
                    const { id } = fields;
                    return (
                      <Job
                        resume={resume}
                        answers={answers}
                        setAnswers={setAnswers}
                        key={id}
                        resumeModal={resumeModal}
                        setResumeModal={setResumeModal}
                        user={user} // apply={apply}
                        index={index}
                        fields={fields}
                        id={id}
                      />
                    );
                  }
                })
              )}
              {search.length < currentPage ? (
                ""
              ) : (
                <div
                  style={{ width: "fit-content" }}
                  onClick={() => setCurrentPage(currentPage + 10)}
                  className={` px-3
                     py-2 cursor rounded primary_bg_color text-white mx-auto label`}
                >
                  LOAD MORE
                </div>
              )}
            </div>
            <div className="pt-3">
              <hr />
              <div className="d-md-flex d-block container justify-content-between align-items-center my-4">
                <div
                  style={{ fontFamily: "inter", fontSize: "14px" }}
                  className="black my-3"
                >
                  &copy;2022, All right reserved.
                </div>
                <div className="d-md-flex d-block align-items-center">
                  <div className="">
                    <a
                      href="#Privacy Policy"
                      onClick={() => setPolicyModal(true)}
                      style={{ fontFamily: "inter", fontSize: "14px" }}
                      className="black "
                    >
                      Privacy Policy
                    </a>
                  </div>
                  <div className="my-1">
                    <a
                      onClick={() => setTearmsModal(true)}
                      href="#
                Terms of Service"
                      style={{ fontFamily: "inter", fontSize: "14px" }}
                      className="black mx-md-4"
                    >
                      Terms of Service
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      {/* RESUME POP-UP */}

      <div
        style={{ zIndex: "889" }}
        className={`${resumeModal ? "show" : ""} account_success d-flex`}
      >
        <div
          style={{ height: "fit-content" }}
          className="col-10 col-sm-10 col-md-6 col-lg-5 text-center bg-white mx-auto py-5 px-5 position-relative"
        >
          <div
            style={{
              width: "103.33px",
              height: "102.5px",
              border: "0.3rem solid orange",
              borderRadius: "100%",
            }}
            className="bg-white d-flex align-items-center justify-content-center mx-auto mt-5"
          >
            <FaFileUpload
              style={{
                width: "41.33px",
                height: "30.75px",
                color: "orange",
              }}
              className=""
            />{" "}
          </div>
          <div
            style={{
              fontWeight: "700",
              fontFamily: "inter",
              fontSize: "18px",
              lineHeight: "21.78px",
            }}
            className="text-warning mb-1 mt-3  text-capitalize"
          >
            Upload your resume!
          </div>
          <div
            style={{
              fontWeight: "400",
              fontFamily: "inter",
              fontSize: "16px",
              lineHeight: "28px",
              color: "rgba(134, 146, 166, 1)",
            }}
            className=""
          >
            Please upload your resume and continue applying
          </div>
          <div
            onClick={() => setResumeModal(false)}
            style={{
              fontWeight: "500",
              fontFamily: "inter",
              fontSize: "20px",
              lineHeight: "24.2px",
            }}
            className="primary_bg_color white rounded py-3 mb-5 mt-2 cursor "
          >
            OK
          </div>
        </div>
      </div>
    </div>
  );
}

const Job = ({
  id,
  fields,
  resume,
  resumeModal,
  setResumeModal,
  user,
  // apply,
  index,
  setAnswers,
  answers,
  setResume,
}) => {
  const desc = fields.description;
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [applied, setApplied] = useState(false);
  const [show, setShow] = useState(false);
  const [showo, setShowo] = useState(desc);
  const [showoo, setShowoo] = useState("");
  const [answerQuiz, setAnswerQuiz] = useState(false);

  const apply = (id, answer) => {
    const key = JSON.parse(localStorage.getItem("key"));
    const elements = displaySpinner(); // Display spinner
    const jobid = id;
    console.log(answer);
    console.log(answers);
    const resume = user.user.resume;
    if (resume) {
      axios
        .post(
          `${api_url}apply/${jobid}/`,
          { questionnaire_answers: answers },
          { headers: { Authorization: `Token ${key}` } }
        )
        .then((res) => {
          console.log(answers);
          console.log(res);
          const notify = () => toast(res.data.msg);
          notify();
          removeSpinner(elements);
          if (res.data.msg === "job applied successfully") {
            setApplied(true);
            // setAnswers([]);
          }
          setAnswerQuiz(false);
        })
        .catch((err) => console.log(err));
    } else {
      setResumeModal(true);
      setResume(false);
    }
  };

  const submit = (id) => {
    apply(id);
    setShow(false);
    console.log(id);
  };

  // useEffect(() => {
  //   if (answers.length > 0) {
  //     submit(id);
  //   }
  //   console.log(answers);
  // }, [answers]);

  useEffect(() => {
    const key = JSON.parse(localStorage.getItem("key"));
    axios
      .get(`${api_url}appliedjobs/`, {
        headers: { Authorization: `Token ${key}` },
      })
      .then((res) => setAppliedJobs(res.data.data))
      .catch((err) => console.log(err));
    const txt = showo.replaceAll(".", ".\n");
    setShowoo(txt);
  }, []);
  useEffect(() => {
    const app = appliedJobs.find((job) => job.applied_jobs === id);
    if (app) {
      setApplied(true);
    }
  }, [appliedJobs]);

  return (
    <div
      style={{
        borderTop: "0.1px solid rgba(221, 221, 221, 1)",
        borderBottom: "0.1px solid rgba(221, 221, 221, 1)",
        borderRight: "7px solid #4897D8",
        borderLeft: "7px solid #4897D8",
        borderRadius: "8px",
        transition: "all 0.2s ease-in-out",
        boxShadow: ` 6px 6px 8px rgba(0, 0,0,.1),
             -6px -6px 8px rgba(0, 0,0,.1)`,
      }}
      className="col-12 job-ost my-3 cursor pt-3 bg-white position-relative"
      key={index}
    >
      <img
        src={`${api_host}/${fields.image}`}
        className="d-sm-none d-block w-100"
        height={"150px"}
        alt=""
        onClick={() => setShow(!show)}
      />
      <img
        src={`${api_host}/${fields.image}`}
        className="d-none d-sm-block img-right"
        alt=""
        onClick={() => setShow(!show)}
      />
      <div className="row " onClick={() => setShow(!show)}>
        <div className="col-10">
          <span
            style={{
              fontFamily: "inter",
              fontSize: "28px",
              fontWeight: "700",
              lineHeight: "33.89px",
            }}
            className="black text-capitalize"
          >
            {fields.title}
          </span>{" "}
          <div className=" mt-2 d-flex gap-2 flex-wrap">
            <span
              className=" mt-3 text-capitalize"
              style={{
                fontFamily: "inter",
                fontSize: "24px",
                fontWeight: "700",
              }}
            >
              {fields.company}
            </span>
            <small
              className=" mt-3 smallBadge text-capitalize"
              style={{
                color: "#f19306",
                fontFamily: "inter",
                fontWeight: "400",
              }}
            >
              {fields.industry}
            </small>
            <small
              className=" mt-3 smallBadge"
              style={{
                color: "#f19306",
                fontFamily: "inter",
                fontWeight: "400",
              }}
            >
              {fields.type}
            </small>
            <small
              className="mt-3 smallBadge"
              style={{
                color: "#f19306",
                fontFamily: "inter",
                fontWeight: "400",
              }}
            >
              {fields.salary}
            </small>
            <small
              className="mt-3 smallBadge"
              style={{
                color: "#f19306",
                fontFamily: "inter",
                fontWeight: "400",
              }}
            >
              {fields.experience}
            </small>
          </div>
        </div>
      </div>
      <div
        className="d-sm-flex align-items-center mt-2 mb-md-2"
        onClick={() => setShow(!show)}
      >
        <div
          style={{ fontFamily: "inter", fontSize: "24px", fontWeight: "700" }}
          className=""
        >
          {"Job Description"}
        </div>
        <div className="mx-sm-2 text-muted ">
          <small>{fields.period}</small>
        </div>
      </div>
      <div
        onClick={() => setShow(!show)}
        style={{
          fontFamily: "inter",
          fontSize: "1.125rem",
          lineHeight: "1.7rem",
          whiteSpace: "pre-wrap",
        }}
        className=""
      >
        {show ? (
          desc
        ) : (
          <div className="">
            <div className="d-xxl-none">
              <span className="readMore">
                {desc.substring(0, 280)}
                <span className="mx-0  px-0 text-muted">...</span>
              </span>
            </div>

            <div className="d-none d-xxl-block">
              <span className="readMore">
                {desc.substring(0, 330)}
                <span className="mx-0 px-0 text-muted">...</span>
              </span>
            </div>
          </div>
        )}
      </div>
      <div className={show ? "d-block" : "d-none"}>
        <div
          style={{ fontFamily: "inter", fontSize: "24px", fontWeight: "700" }}
          className="mt-4 mb-2"
        >
          {"Requirements"}
        </div>
        <div
          style={{
            fontFamily: "inter",
            fontSize: "1.125rem",
            lineHeight: "1.7rem",
            whiteSpace: "pre-wrap",
          }}
          className=""
        >
          {fields.requirements}
        </div>
        <div
          style={{ fontFamily: "inter", fontSize: "24px", fontWeight: "700" }}
          className="mt-4 mb-2"
        >
          {"Qualifications"}
        </div>
        <div
          style={{
            fontFamily: "inter",
            fontSize: "1.125rem",
            lineHeight: "1.7rem",
            whiteSpace: "pre-wrap",
          }}
          className=""
        >
          {fields.qualifications}
        </div>
      </div>
      <div className="d-flex flex-wrap gap-3 my-3">
        <small
          className="smallBadge"
          style={{
            color: "#f19306",
            fontFamily: "inter",
            fontWeight: "400",
          }}
        >
          {fields.state}
        </small>
        <small
          className="smallBadge"
          style={{
            color: "#f19306",
            fontFamily: "inter",
            fontWeight: "400",
          }}
        >
          {fields.city}
        </small>
        <small
          className="smallBadge"
          style={{
            color: "#f19306",
            fontFamily: "inter",
            fontWeight: "400",
          }}
        >
          {fields.role}
        </small>
        <small
          className="smallBadge"
          style={{
            color: "#f19306",
            fontFamily: "inter",
            fontWeight: "400",
          }}
        >
          {fields.salestype}
        </small>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div className="d-sm-flex d-block">
          <Questions
            resume={resume}
            applied={applied}
            quiz={fields.questions}
            answers={answers}
            setAnswers={setAnswers}
            answerQuiz={answerQuiz}
            setAnswerQuiz={setAnswerQuiz}
            resumeModal={resumeModal}
            setResumeModal={setResumeModal}
            id={id}
            onClick={submit}
          />{" "}
          <ShareBox url={`${api_host}/job-detail` + id} title={fields.title} />
        </div>

        <div
          onClick={() => setShow(!show)}
          className="primary_color cursor-pointer link  mx-md-4"
        >
          {show ? <div>Show less</div> : <div>Show more</div>}
        </div>
      </div>
    </div>
  );
};
