import React, { useEffect, useRef, useState } from "react";
import { FaChevronDown } from "react-icons/fa";

export default function Select({ list, name, form, setForm, error }) {
  const [show, setShow] = useState(false);
  const [inputVal, setInputVal] = useState("");

  const ref = useRef(null);
  useEffect(() => {
    const closeDropdown = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", closeDropdown);
  }, [ref]);

  return (
    <main ref={ref} className="relative w-full">
      <div style={{ cursor: "pointer" }} className="position-relative">
        <input
          type="text"
          value={inputVal}
          onClick={() => setShow(!show)}
          onChange={(e) => setInputVal(e.target.value)}
          placeholder={form[name] || "Select"}
          className={"input_2 px-3"}
          style={{
            borderColor: error && !form[name] ? "red" : "",
            // border: border ? border : "",
            height: "45.59px",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        />
        <div
          className="arrow_container items-center flex"
          style={{ borderColor: "#f19306" }}
        >
          <FaChevronDown style={{ color: "#f19306" }} className="arrow2 " />
        </div>
      </div>
      <section
        className={`${
          show ? "z-10 mt-2 visible" : "-z-10 mt-4 invisible"
        } absolute duration-500 top-full h-fit rounded left-0 w-full shadow-lg p-2 bg-white`}
      >
        <div
          className={` max-h-[300px] h-auto custom-scrollbar overflow-y-auto pr-2 mx-auto flex flex-col gap-0.5 bg-white`}
        >
          {list
            .filter((item) => item?.value.includes(inputVal))
            .map(({ value, selected, id }, index) => {
              return (
                <div key={index} className="">
                  <div
                    className={`d-flex rounded cursor-default justify-content-between duration-500 py-2 px-3 text-capitalize ${
                      form[name] == value
                        ? "text-white primary_bg_color"
                        : "hover:bg-opacity-10 hover:bg-primary"
                    }`}
                    onClick={() => {
                      setForm({ ...form, [name]: value });
                      setShow(false);
                      console.log(value);
                    }}
                  >
                    <div className="">{value}</div>
                  </div>
                </div>
              );
            })}

          {list.filter((item) => item?.value.includes(inputVal))?.length ===
            0 && <div className="py-2 text-center">No Matches</div>}
        </div>
      </section>
    </main>
  );
}
