import React, { useState } from "react";
import PaymentSuccess from "../reusableComponents/paymentSuccess/paymentSuccess";

export default function PaymentModal() {
  const [openShareModal, setOpenShareModal] = useState(false);

  return (
    <div>
      <PaymentSuccess
        openShareModal={openShareModal}
        setOpenShareModal={setOpenShareModal}
      />
    </div>
  );
}
